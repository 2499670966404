import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { QuestionnaireHeader } from "../components/QuestionnaireHeader";
import { useExternalQuestionnaireBreadcrumb } from "../hooks/useExternalQuestionnairesBreadcrumb";
import { QuestionSection } from "./components/QuestionSection";
import { Sidebar } from "./components/Sidebar";
import { useQuestionBreadcrumb } from "./hooks/useQuestionBreadcrumb";
import { keys, RESOURCE_NAME } from "./i18n";

export const Question: FC = () => {
    const {
        external_questionnaire_id: questionnaireId,
        external_questionnaire_question_id: id,
    } = useParams();
    const { t } = useTranslation(RESOURCE_NAME);
    const navigate = useNavigate();
    useQuestionBreadcrumb(id, questionnaireId);
    useExternalQuestionnaireBreadcrumb(questionnaireId);

    const handleBack = () => {
        navigate("../");
    };

    return (
        <PageContainer p="4" gap="4">
            <QuestionnaireHeader />
            <FlexRow>
                <Button variant="tonal" onClick={handleBack}>
                    <Button.Icon name="angleLeft" />
                    {t(keys.back_button_label)}
                </Button>
            </FlexRow>
            <FlexRow gap="4" h="full" w="full">
                <Sidebar />
                <QuestionSection key={id} />
            </FlexRow>
        </PageContainer>
    );
};
