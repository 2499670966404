import ImageUpload from "@app/shared/components/Upload/ImageUpload";

import {
    NewS3File,
    newS3FileToCreateFileInput,
} from "@app/usecases/FileUseCases";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { File } from "../../../../generated/client/graphql";
import ButtonLarge from "../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../components/Button/ButtonShared";
import Container from "../../../components/Container";
import InputSimple from "../../../components/InputSimple";
import BodyHeader from "../../../shared/components/BodyHeader";
import { useOrgCustomization, useUpdateOrgCustomization } from "./data";
import "./i18n";

export default function General() {
    const { t } = useTranslation("General");
    const { data, refetch, isRefetching } = useOrgCustomization();

    const { mutate: updateOrg, isPending: isMutating } =
        useUpdateOrgCustomization();

    const [name, setName] = useState<string | undefined>(
        data?.org?.tenant?.name || undefined,
    );
    const [color, setColor] = useState<string | undefined>(
        data?.org?.customization?.hexColor || undefined,
    );

    const [newS3File, setNewS3File] = useState<NewS3File>();

    const canSave =
        color !== data?.org?.customization?.hexColor ||
        name !== data?.org?.tenant?.name ||
        newS3File;

    useEffect(() => {
        setName(data?.org?.tenant?.name || undefined);
        setColor(data?.org?.customization?.hexColor || undefined);
    }, [data]);

    return (
        <Container>
            <BodyHeader title={t("title")} subTitle={t("subtitle")} />
            <div className="p-4">
                <div className="max-w-[540px] space-y-3">
                    <div className="flex flex-col space-y-4 justify-center items-center w-full px-6 py-5 rounded border border-secondary bg-secondary">
                        <ImageUpload
                            file={data?.org?.customization?.logo as File}
                            newS3File={newS3File}
                            setNewS3File={setNewS3File}
                        ></ImageUpload>
                    </div>
                    <InputSimple
                        title={t("organization_name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <InputSimple
                        title={t("color")}
                        value={color}
                        iconLeft={
                            <div
                                className="w-4 h-4 rounded border border-secondary"
                                style={{ backgroundColor: color || "#" }}
                            />
                        }
                        onChange={(e) => {
                            setColor(e.target.value);
                        }}
                    />
                    <ButtonLarge
                        onClick={() => {
                            updateOrg(
                                {
                                    name: name || "",
                                    hexColor: color || "",
                                    tenantId: data?.org?.tenantId || "",
                                    logoFile: newS3File
                                        ? newS3FileToCreateFileInput(newS3File)
                                        : undefined,
                                },
                                {
                                    onSuccess: () => {
                                        setNewS3File(undefined);
                                        refetch();
                                    },
                                },
                            );
                        }}
                        loading={isRefetching || isMutating}
                        variant={ButtonType.FLAT_PRIMARY}
                        disabled={!canSave}
                    >
                        {t("save")}
                    </ButtonLarge>
                </div>
            </div>
        </Container>
    );
}
