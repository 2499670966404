import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import Floatbar from "@app/components/Floatbar";
import { useSetToast } from "@app/components/Toast";
import { useCreateObjectives } from "@app/screens/Objectives/data";
import { TagsMultiSelect } from "@app/shared/components/TagsMultiSelect";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import Select from "@design-system/Inputs/Select";
import { Text } from "@design-system/Typography/Text";
import {
    Indicator,
    TagType,
    UpdateIndicatorInput,
    User,
} from "@generated/client/graphql";
import { useAtom } from "jotai";
import { Bell, ChevronDown, EyeOff, UserPlus } from "lucide-react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { openReminderModalAtom } from "../../context";
import {
    useUpdateIndicators,
    useUpdateManyIndicatorEntityConfig,
} from "../../data";
import "../../i18n";

enum Actions {
    SEND_REMINDER = "SEND_REMINDER",
    ASSIGN_OWNER = "ASSIGN_OWNER",
    MARK_NOT_RELEVANT = "MARK_NOT_RELEVANT",
    ASSIGN_TAGS = "ASSIGN_TAGS",
    CREATE_OBJECTIVES = "CREATE_OBJECTIVES",
}

const ReportingTableActionBar = ({
    indicators,
    unset,
}: {
    indicators: Indicator[];
    unset: () => void;
}) => {
    const { t } = useTranslation("ReportingPage");

    const [activeAction, setActiveAction] = useState<Actions | undefined>(
        undefined,
    );
    const [_, setOpenReminderModal] = useAtom(openReminderModalAtom);
    const [ownerId, setOwnerId] = useState<string | null | undefined>(null);
    const [tagsIds, setTagsIds] = useState<(string | null)[]>();
    const [searchOwnerFilter, setSearchOwnerFilter] = useState<string>();
    const { mutate, isPending } = useUpdateManyIndicatorEntityConfig();
    const { mutate: createObjectives } = useCreateObjectives();
    const { mutate: updateIndicators } = useUpdateIndicators();
    const { setToastSuccess, setToastError } = useSetToast();
    const selectedUsers = indicators
        .map((elt) => elt?.entityConfig?.assignedTo)
        .filter((elt) => elt?.id) as User[];
    const uniqueSelectedUsersCount = [
        ...new Map(selectedUsers.map((item) => [item.id, item])).values(),
    ].length;

    const onRelevanceApply = () => {
        const ids = indicators
            .map((elt) => elt?.entityConfig?.id)
            .filter((elt) => elt !== undefined) as string[];
        if (!ids) {
            return;
        }
        mutate(
            {
                indicatorEntityConfigIds: ids,
                set: {
                    active: false,
                    userId: undefined,
                },
            },
            {
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                    unset();
                },
            },
        );
    };
    const onCreateObjectivesApply = () => {
        const filteredIndicators = indicators.filter(
            (indicator) =>
                !!indicator.entityConfig?.entityId &&
                !!indicator.subthemev2?.theme?.id,
        );
        if (!filteredIndicators.length) return;
        createObjectives(
            filteredIndicators.map((indicator) => ({
                indicatorId: indicator.id,
                entityId: indicator.entityConfig?.entityId || "",
                themeId: indicator.subthemev2?.theme?.id || "",
            })),
            {
                onSuccess: () => {
                    setToastSuccess(t("create_objectives_success"));
                    unset();
                },
                onError: () => {
                    setToastError(t("create_objectives_error"));
                },
            },
        );
    };

    const onOwnerApply = () => {
        const ids = indicators
            .map((elt) => elt?.entityConfig?.id)
            .filter((elt) => elt !== undefined) as string[];
        if (!ids) {
            return;
        }
        mutate(
            {
                indicatorEntityConfigIds: ids,
                set: {
                    active: undefined,
                    userId: ownerId || null,
                },
            },
            {
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                    unset();
                },
            },
        );
    };
    const onTagsApply = () => {
        const ids = indicators.map((elt) => elt.id);
        if (!ids) {
            return;
        }
        updateIndicators(
            {
                input: ids.map(
                    (id) =>
                        ({
                            id,
                            set: {
                                tagIds: tagsIds?.filter(Boolean) as string[],
                            },
                        }) as UpdateIndicatorInput,
                ),
            },
            {
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                    unset();
                },
                onError: () => {
                    setToastSuccess(t("update_error"));
                },
            },
        );
    };
    const onSendReminderApply = () => {
        setOpenReminderModal(true);
    };

    useEffect(() => {
        setActiveAction(undefined);
    }, []);

    const onValueChange = (actionId: string) => {
        setActiveAction(actionId as Actions);
    };
    const showCreateObjectives =
        useFeatureFlagEnabled("legacyObjectives") === false;

    return (
        <Floatbar
            unset={unset}
            text={t("indicator_table_action_selection", {
                count: indicators.length,
            })}
            open={indicators.length > 0}
            primary={<></>}
            secondary={
                <Select value={activeAction} onValueChange={onValueChange}>
                    <Select.Trigger asChild>
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            IconRight={ChevronDown}
                            className=" justify-between"
                        >
                            <Text variant="body-sm-bold" pl="2" pr="3" py="2">
                                {t("indicator_table_action_select_action")}
                            </Text>
                        </ButtonSmall>
                    </Select.Trigger>
                    <Select.Content>
                        <Select.Item value={Actions.SEND_REMINDER}>
                            <Bell className="w-4 text-beavrGreen" />
                            <Text variant="body-sm">
                                {t(
                                    "indicator_table_action_send_reminder_action",
                                )}
                            </Text>
                        </Select.Item>
                        <Select.Item value={Actions.ASSIGN_OWNER}>
                            <UserPlus className="w-4 text-beavrGreen" />
                            <Text variant="body-sm">
                                {t(
                                    "indicator_table_action_assign_owner_action",
                                )}
                            </Text>
                        </Select.Item>
                        <Select.Item value={Actions.MARK_NOT_RELEVANT}>
                            <EyeOff className="w-4 text-beavrGreen" />
                            <Text variant="body-sm">
                                {t(
                                    "indicator_table_action_mark_not_relevant_action",
                                )}
                            </Text>
                        </Select.Item>
                        <Select.Item value={Actions.ASSIGN_TAGS}>
                            <Icon
                                name="tag"
                                size="sm"
                                className="text-beavrGreen"
                            />
                            <Text variant="body-sm">
                                {t("indicator_table_action_assign_tags_action")}
                            </Text>
                        </Select.Item>
                        {showCreateObjectives && (
                            <Select.Item value={Actions.CREATE_OBJECTIVES}>
                                <Icon
                                    name="target"
                                    size="sm"
                                    className="text-beavrGreen"
                                />
                                <Text variant="body-sm">
                                    {t(
                                        "indicator_table_action_create_objectives",
                                    )}
                                </Text>
                            </Select.Item>
                        )}
                    </Select.Content>
                </Select>
            }
            actions={[
                {
                    id: Actions.ASSIGN_OWNER,
                    text: t("indicator_table_action_assign_owner", {
                        count: indicators.length,
                    }),
                    selector: (
                        <UserChipSelect
                            userId={ownerId}
                            setUserId={setOwnerId}
                            searchFilter={searchOwnerFilter}
                            onSearchFilterChange={setSearchOwnerFilter}
                            showUnsetByDefault={true}
                        ></UserChipSelect>
                    ),
                    apply: (
                        <ButtonSmall
                            onClick={onOwnerApply}
                            disabled={ownerId === null}
                            loading={isPending}
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.MARK_NOT_RELEVANT,
                    text: t("indicator_table_action_mark_not_relevant", {
                        count: indicators.length,
                    }),
                    apply: (
                        <ButtonSmall
                            onClick={onRelevanceApply}
                            loading={isPending}
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.SEND_REMINDER,
                    text: t("indicator_table_action_send_reminder", {
                        count: uniqueSelectedUsersCount,
                    }),
                    apply: (
                        <ButtonSmall onClick={onSendReminderApply}>
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
                {
                    id: Actions.ASSIGN_TAGS,
                    text: t("indicator_table_action_assign_tags_action"),
                    selector: (
                        <TagsMultiSelect
                            tagsIds={tagsIds}
                            setTagsIds={setTagsIds}
                            type={TagType.Indicator}
                            side="top"
                        />
                    ),
                    apply: (
                        <Button
                            onClick={onTagsApply}
                            disabled={!tagsIds?.length}
                        >
                            {t("apply")}
                        </Button>
                    ),
                },
                {
                    id: Actions.CREATE_OBJECTIVES,
                    text: t("indicator_table_action_create_objectives_action", {
                        count: indicators.length,
                    }),
                    apply: (
                        <Button onClick={onCreateObjectivesApply}>
                            {t("apply")}
                        </Button>
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        ></Floatbar>
    );
};

export default ReportingTableActionBar;
