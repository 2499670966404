import i18next from "@app/i18n";

const enResources = {
    edit_question: "Edit question",
    delete_question: "Delete question",

    answer_placeholder: "Answer",
    comment_placeholder: "Comment",

    answer_saved: "Answer saved",
    comment_saved: "Comment saved",
    answer_saved_error: "Error while saving answer",
    comment_saved_error: "Error while saving comment",

    add_docs: "Add documents for references",

    back_button_label: "Back to questionnaire",

    validate_answer: "Validate answer",
    owner: "Owner",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    edit_question: "Modifier la question",
    delete_question: "Supprimer la question",

    answer_placeholder: "Réponse",
    comment_placeholder: "Commentaire",

    answer_saved: "Réponse sauvegardée",
    comment_saved: "Commentaire sauvegardé",
    answer_saved_error: "Erreur lors de la sauvegarde de la réponse",
    comment_saved_error: "Erreur lors de la sauvegarde du commentaire",

    add_docs: "Ajouter des documents pour les références",

    back_button_label: "Retour à au questionnaire",

    validate_answer: "Valider la réponse",
    owner: "Propriétaire",
};

export const RESOURCE_NAME = "ExternalQuestionnaireQuestion";
export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
