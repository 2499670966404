import CheckboxCell from "@app/components/Table/CheckboxCell";
import Header from "@app/components/Table/Header";
import CheckboxHeader from "@app/components/Table/Header/Checkbox";
import { FileIcon } from "@app/shared/components/FileIcon";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FileWithLinkedItems } from "../../../../screens/Frameworks/framework/Csrd/pillar/types";
import { RESOURCE_NAME, keys } from "./i18n";

const selectCol: ColumnDef<FileWithLinkedItems> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} />,
    meta: {
        colWidth: "50px",
    },
};

const nameCol: (isSuggested: boolean) => ColumnDef<FileWithLinkedItems> = (
    isSuggested: boolean,
) => ({
    id: "name",
    accessorKey: "name",
    header: ({ column }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        return (
            <Header column={column} disableSort>
                <FlexRow gap="2" alignItems="center">
                    <Text
                        variant="body-sm-bold"
                        color={isSuggested ? "brand" : "primary"}
                    >
                        {t(isSuggested ? keys.suggested_files : keys.all_files)}
                    </Text>
                    {isSuggested && (
                        <Icon
                            name="stars"
                            className="text-brand"
                            size="sm"
                        ></Icon>
                    )}
                </FlexRow>
            </Header>
        );
    },
    cell: ({ row }) => {
        const displayName =
            (!!row.original?.documentVersions &&
                row.original?.documentVersions[0]?.document?.name) ||
            row.original?.filename;
        return (
            <a
                href={row.original?.signedUrl || undefined}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:no-underline text-inherit"
            >
                <FlexRow h="6" gap="2" alignItems="center">
                    <FileIcon mimeType={row.original?.mimeType || ""} />
                    <Text
                        variant="body-sm-bold"
                        align="start"
                        className="no-underline"
                    >
                        {displayName}
                    </Text>
                </FlexRow>
            </a>
        );
    },
});

const suggestedFilesColumns: ColumnDef<FileWithLinkedItems>[] = [
    selectCol,
    nameCol(true),
];
export const evidenceFilesColumns: ColumnDef<FileWithLinkedItems>[] = [
    selectCol,
    nameCol(false),
];
export default suggestedFilesColumns;
