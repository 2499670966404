import { useTranslation } from "react-i18next";
import { type FC } from "react";
import { Text } from "@design-system/Typography/Text";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";

import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import { ReactComponent as Logo } from "../../../../../assets/top-logo.svg";

export const AskAiPlaceholder: FC<{ isFirstThread: boolean }> = ({
    isFirstThread,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <FlexRow w="full" alignItems="center" justifyContent="center">
            <Logo className={"text-green"} />
            <FlexCol ml="1">
                <Text variant="header3" color="primary">
                    {t(keys.title)}
                </Text>
                <Text variant="body" color="secondary" mt="0.5">
                    {isFirstThread ? t(keys.firstThreadText) : t(keys.text)}
                </Text>
            </FlexCol>
        </FlexRow>
    );
};
