import { type ColumnDef } from "@design-system/Table";
import { ExternalQuestionnairesListItem } from "./types";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";
import { Link } from "react-router-dom";
import { Text } from "@design-system/Typography/Text";
import { ProgressBar } from "@design-system/DataDisplay/ProgressBar/ProgressBar";
import { getQuestionnaireProgress } from "./services";
import { FlexRow } from "@design-system/Layout/Flex";

export const columns: ColumnDef<ExternalQuestionnairesListItem>[] = [
    {
        id: "name",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_name);
        },
        cell: ({ row }) => {
            return (
                <Link className="[all:unset]" to={row.original.id}>
                    <Text className="cursor-pointer">{row.original.name}</Text>
                </Link>
            );
        },
    },
    {
        id: "requester",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_requester);
        },
        cell: ({ row }) => {
            return row.original.requesterEmail ?? "-";
        },
    },
    {
        id: "company",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_company);
        },
        cell: ({ row }) => {
            return row.original.companyName ?? "-";
        },
    },
    {
        id: "progress",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_progress);
        },
        cell: ({ row }) => {
            const { completedQuestions, totalQuestions } =
                getQuestionnaireProgress(row.original);

            if (totalQuestions === 0) {
                return "-";
            }
            return (
                <FlexRow gap="5">
                    <ProgressBar
                        completed={completedQuestions}
                        total={totalQuestions}
                        noDataPlaceholder="N/A"
                    />
                    <Text>{`${completedQuestions} / ${totalQuestions}`}</Text>
                </FlexRow>
            );
        },
    },
    {
        id: "deadline",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_deadline);
        },
        cell: ({ row }) => {
            const deadline = row.original.deadline
                ? new Date(row.original.deadline)
                : null;
            return deadline ? format(deadline, "dd/MM/yyyy") : "-";
        },
    },
];
