import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { currentAiThreadIdAtom } from "../ctx";
import { useCreateThread, useThread, useThreads } from "../data";
import { RESOURCE_NAME, keys } from "../i18n";

const ThreadsHistoryItem: FC<{ id: string; name: string }> = ({ id, name }) => {
    const [currentAiThreadId, setCurrentAiThreadId] = useAtom(
        currentAiThreadIdAtom,
    );

    const isSelected = id === currentAiThreadId;

    const handlePointerEnter = usePrefetchThread(id);
    const handleClick = () => setCurrentAiThreadId(id);

    return (
        <li>
            <a
                className="[all:unset]"
                onClick={handleClick}
                onPointerEnter={handlePointerEnter}
                style={{ textDecoration: "none" }}
            >
                <Box
                    className={cn(
                        isSelected && "bg-tertiary text-brand",
                        "hover:text-brand_hover",
                        "cursor-pointer",
                    )}
                    br="lg"
                    p="2"
                    w="full"
                >
                    <Text color="currentColor" truncate>
                        {name}
                    </Text>
                </Box>
            </a>
        </li>
    );
};

export const ThreadsHistory: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { threads } = useThreads();
    const { mutate } = useCreateThread();
    const setCurrentAiThreadId = useSetAtom(currentAiThreadIdAtom);

    const createThread = async () => {
        mutate(undefined, {
            onSettled: (data) => {
                setCurrentAiThreadId(data?.createAiThread.id);
            },
        });
    };

    useEffect(() => {
        if (!threads.length) return;
        setCurrentAiThreadId(threads[0].id);
    }, [threads]);

    return (
        <FlexCol className="w-[224px] max-w-[224px] min-w-[224px] border-tertiary border-r overflow-scroll scrollbar-hide gap-1">
            <FlexCol className="overflow-hidden" h="full">
                <Text as="h3" p="4" pb="3" variant="header3">
                    {t(keys.hystory)}
                </Text>
                <Box className="overflow-y-auto scrollbar-hide" h="full" px="2">
                    <ul>
                        {threads.map((thread, idx) => (
                            <ThreadsHistoryItem
                                id={thread.id}
                                key={thread.id}
                                name={t(keys.conversation, { index: idx + 1 })}
                            />
                        ))}
                    </ul>
                </Box>
            </FlexCol>
            <Box p="4" pt="1">
                <Button className="w-full" onClick={createThread}>
                    {t(keys.new_thread_btn)}
                    <Button.Icon name="plus" />
                </Button>
            </Box>
        </FlexCol>
    );
};

function usePrefetchThread(id: string) {
    const [prefetch, setPrefetch] = useState(false);
    const handlePointerEnter = () => setPrefetch(true);
    useThread(prefetch ? id : undefined);

    return handlePointerEnter;
}
