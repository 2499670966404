import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";
import {
    type CsrdDatapointPatch,
    type CsrdDatapointsFilter,
    type CsrdDisclosureRequirementPatch,
    type CsrdEsrsPatch,
    type GetCsrdDatapointQueryVariables,
    type GetCsrdDatapointsQueryVariables,
    type GetCsrdStatsQuery,
    type UpdateCsrdDatapointMutation,
} from "@generated/client/graphql";

const GetCsrdStatsQuery = graphql(`
    query GetCsrdStats {
        csrdStats {
            total
            assigned
            validated
            inProgress
            notStarted
            notMaterial
        }
    }
`);

const GetCsrdSectionsAndStatsQuery = graphql(`
    query GetCsrdSectionsAndStats {
        pillars {
            cmsId
            name
            description
            color
            order
            stats {
                total
                validated
                notMaterial
            }
            esrss {
                cmsId
                name
                description
                order
                stats {
                    total
                    assigned
                    validated
                    inProgress
                    notStarted
                    notMaterial
                }
                disclosureRequirements {
                    cmsId
                }
            }
        }
    }
`);

const GetCsrdDatapointQuery = graphql(`
    query GetCsrdDatapoint($id: ID!) {
        csrdDatapoint(id: $id) {
            associatedReportingDatapoints {
                id
                value
                comment
                datapointGroup {
                    id
                    entity {
                        id
                        name
                        isDefault
                    }
                    period
                }
            }
        }
    }
`);

const GetCsrdDatapointsQuery = graphql(`
    query GetCsrdDatapoints(
        $filter: CsrdDatapointsFilter
        $take: Int
        $skip: Int
    ) {
        csrdDatapoints(filter: $filter, take: $take, skip: $skip) {
            count
            data {
                id
                cmsId
                esrsId
                drId
                status
                materiality {
                    status
                    comment
                }
                name
                mandatory
                phaseInLess750
                phaseInAll
                value
                ownerId
                unit {
                    id
                    name
                    shortName
                    type
                }
                type
                comment
                owner {
                    id
                    firstName
                    lastName
                }
                validationStatus
            }
        }
    }
`);

const GetCsrdPillarDataQuery = graphql(`
    query GetCsrdPillarData(
        $pillarCmsId: String!
        $filter: CsrdDatapointsFilter
    ) {
        pillar(cmsId: $pillarCmsId) {
            cmsId
            name
            description
            color
            esrsIds
            esrss {
                cmsId
                name
                order
                ownerIds
                drIds
                disclosureRequirements {
                    id
                    name
                    cmsId
                    materiality {
                        comment
                        status
                    }
                    order
                    ownerIds
                    datapoints(filter: $filter) {
                        id
                        cmsId
                        esrsId
                        drId
                        indicatorCmsId
                        status
                        aiSuggestion
                        materiality {
                            status
                            comment
                        }
                        mandatory
                        phaseInLess750
                        phaseInAll
                        name
                        value
                        ownerId
                        unit {
                            id
                            name
                            shortName
                            type
                        }
                        type
                        comment
                        owner {
                            id
                            firstName
                            lastName
                        }
                        validationStatus
                        evidenceFiles {
                            id
                            name
                            signedUrl
                            mimeType
                            documentVersions {
                                id
                                document {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`);

const GetCsrdPillarsQuery = graphql(`
    query GetCsrdPillars {
        pillars {
            cmsId
            name
            esrss {
                cmsId
                name
                order
                drIds
                disclosureRequirements {
                    cmsId
                    name
                    order
                }
            }
        }
    }
`);

const UpdateCsrdDatapointMutation = graphql(`
    mutation UpdateCsrdDatapoint($id: ID!, $patch: CsrdDatapointPatch!) {
        updateCsrdDatapoint(id: $id, patch: $patch) {
            id
        }
    }
`);

const UpdateCsrdDisclosureRequirementMutation = graphql(`
    mutation UpdateCsrdDisclosureRequirement(
        $id: ID!
        $patch: CsrdDisclosureRequirementPatch!
    ) {
        updateCsrdDisclosureRequirement(id: $id, patch: $patch) {
            id
        }
    }
`);

const UpdateCsrdEsrsMutation = graphql(`
    mutation UpdateCsrdEsrs($id: ID!, $patch: CsrdEsrsPatch!) {
        updateCsrdEsrs(id: $id, patch: $patch)
    }
`);

const GenerateAiSuggestionMutation = graphql(`
    mutation GenerateAiSuggestion($id: ID!) {
        generateAiSuggestion(id: $id)
    }
`);

export class GraphQLCsrdRepository {
    getGlobalStats = async () => {
        return graphqlClientKoyeb.request(
            GetCsrdStatsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getSectionsAndStats = async () => {
        return graphqlClientKoyeb.request(
            GetCsrdSectionsAndStatsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getPillar = async (id: string, filter?: CsrdDatapointsFilter) => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarDataQuery,
            { pillarCmsId: id, filter: filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getPillars = async () => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoint = async (input: GetCsrdDatapointQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointQuery,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoints = async (input: GetCsrdDatapointsQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointsQuery,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateDatapoint = async (input: {
        id: string;
        patch: CsrdDatapointPatch;
    }) => {
        return graphqlClientKoyeb.request(
            UpdateCsrdDatapointMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateDisclosureRequirement = async (input: {
        id: string;
        patch: CsrdDisclosureRequirementPatch;
    }) => {
        return graphqlClientKoyeb.request(
            UpdateCsrdDisclosureRequirementMutation,
            input,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateEsrs = async (id: string, patch: CsrdEsrsPatch) =>
        graphqlClientKoyeb.request(
            UpdateCsrdEsrsMutation,
            { id, patch },
            await AccessHeadersHolder.getHeaders(),
        );

    generateAiSuggestion = async (id: string) => {
        return graphqlClientKoyeb.request(
            GenerateAiSuggestionMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
