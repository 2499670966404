import {
    Header,
    HeaderFullSkeleton,
} from "@app/shared/components/BackgroundedHeader";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useExternalQuestionnaire } from "../data";
import { keys, RESOURCE_NAME } from "../i18n";
import { type FC } from "react";

export const QuestionnaireHeader: FC = () => {
    const { external_questionnaire_id: id } = useParams();
    const { t } = useTranslation(RESOURCE_NAME);
    const { externalQuestionnaire } = useExternalQuestionnaire(id);

    if (!externalQuestionnaire) return <HeaderFullSkeleton />;

    return (
        <Header bgColor="#1B85B8">
            <Header.LeftColumn>
                <Header.Title>{t(keys.page_title)}</Header.Title>
                <Header.Description>
                    {externalQuestionnaire.name}
                </Header.Description>
            </Header.LeftColumn>
            <Header.RightColumn />
        </Header>
    );
};
