import BodyHeader from "@app/shared/components/BodyHeader";
import { useThemes } from "@app/store/themeStore";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { CreateObjectiveModal } from "./components/CreateObjectiveModal";
import { selectedThemeIdAtom } from "./components/CreateObjectiveModal/ctx";
import { ObjectivesPageTopbar } from "./components/ObjectivesPageTopbar";
import { ObjectivesTable } from "./components/ObjectiveTable/ObjectivesTable";
import { activeEntityIdAtom, isCreateObjectiveModalOpenAtom } from "./ctx";
import { useCreateObjective, useObjectives } from "./data";
import { RESOURCE_NAME, keys } from "./i18n";

export default function Objectives() {
    const activeEntityId = useAtomValue(activeEntityIdAtom);
    const { isPending: isCreateObjectivePending } = useCreateObjective();
    const { objectives, isPending: isObjectivesPending } = useObjectives({
        entityId: activeEntityId,
    });
    const { themes } = useThemes();
    const environmentId = themes?.find(
        (theme) => theme.cmsThemeId === "environment",
    )?.id;
    const setIsCreateObjectiveModalOpen = useSetAtom(
        isCreateObjectiveModalOpenAtom,
    );
    const setCreateObjectiveSelectedThemeId = useSetAtom(selectedThemeIdAtom);

    const handleCreateObjectiveClick = () => {
        setIsCreateObjectiveModalOpen(true);
        if (environmentId) {
            setCreateObjectiveSelectedThemeId(environmentId);
        }
    };

    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <PageContainer>
            <BodyHeader
                title={t(keys.page_title)}
                subTitle={t(keys.page_subtitle)}
                action={
                    <Button
                        size="md"
                        onClick={handleCreateObjectiveClick}
                        loading={isCreateObjectivePending}
                    >
                        <Text variant="body-bold">
                            {t(keys.create_objective)}
                        </Text>
                    </Button>
                }
            />
            <FlexCol gap="4" p="4" alignItems="center" w="full" h="full">
                <ObjectivesPageTopbar />
                {!objectives?.length && !isObjectivesPending ? (
                    <FlexCol
                        gap="4"
                        alignItems="center"
                        justifyContent="center"
                        w="full"
                        h="full"
                        p="11"
                        pt="8"
                    >
                        <Icon
                            name="circleBig"
                            size="lg"
                            className="h-40 w-40"
                        />
                        <FlexCol gap="4" className="max-w-[522px]">
                            <Text variant="body-lg-bold">
                                {t(keys.empty_state_title)}
                            </Text>
                            <Text variant="body-lg">
                                {t(keys.empty_state_subtitle)}
                            </Text>
                            <Button
                                size="md"
                                onClick={handleCreateObjectiveClick}
                                className="w-fit"
                            >
                                <Text variant="body-bold">
                                    {t(keys.empty_state_button)}
                                </Text>
                            </Button>
                        </FlexCol>
                    </FlexCol>
                ) : (
                    <ObjectivesTable entityId={activeEntityId} />
                )}
            </FlexCol>
            <CreateObjectiveModal />
        </PageContainer>
    );
}
