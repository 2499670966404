import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import BodyHeader from "../../../shared/components/BodyHeader";
import UnitsTable from "./components/UnitsTable";
import "./i18n";

export default function Units() {
    const { t } = useTranslation("Units");

    return (
        <>
            <Container>
                <BodyHeader title={t("title")} subTitle={t("subtitle")} />
                <div className="p-4">
                    <UnitsTable />
                </div>
            </Container>
        </>
    );
}
