import { ComponentType, ReactNode, useState } from "react";
import { AlertModal, AlertModalContent } from "../AlertModal";

const ActionWithConfirmation = ({
    title,
    children,
    TriggerButton,
    confirmButton,
}: {
    title: string;
    children: ReactNode;
    TriggerButton: ComponentType<
        Partial<{ onClick: (e: React.MouseEvent<HTMLElement>) => void }>
    >;
    confirmButton: React.ReactNode | ((close: () => void) => ReactNode);
}) => {
    const [open, setOpen] = useState(false);

    const confirmButtonNode =
        typeof confirmButton === "function"
            ? confirmButton(() => setOpen(false))
            : confirmButton;

    return (
        <>
            <TriggerButton
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpen(true);
                }}
            />
            <AlertModal open={open} onOpenChange={setOpen}>
                <AlertModalContent
                    title={title}
                    actions={confirmButtonNode}
                    className="max-w-lg"
                >
                    {children}
                </AlertModalContent>
            </AlertModal>
        </>
    );
};

export default ActionWithConfirmation;
