import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Eye, MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AssignementCard from "../../components/BodyAnalytics/AssignementCard";
import ProgressCard from "../../components/BodyAnalytics/ProgressCard";
import ButtonLarge from "../../components/Button/ButtonLarge";
import { ButtonType } from "../../components/Button/ButtonShared";
import Container from "../../components/Container";
import { DropdownTrigger } from "../../components/Dropdown/components";
import BaseItem from "../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../components/Dropdown/components/dropdowns/layout";
import BodyHeader from "../../shared/components/BodyHeader";
import UnrelevantRequirementsModal from "./components/UnrelevantRequirementsModal";
import RequirementModal from "./components/modal";
import RequirementTable from "./components/table";
import { useRequirementStats } from "./hooks/data";
import {
    useNonRelevantRequirementsTable,
    useRequirementsTable,
} from "./hooks/table";
import "./i18n";
export default function Requirements() {
    const { t } = useTranslation("Requirements");

    const { table: relevantRequirementsTable } = useRequirementsTable();
    const { table: unlevantRequirementsTable, count: nonRelevantCount } =
        useNonRelevantRequirementsTable();
    const { data: statsData } = useRequirementStats();

    const [
        openUnrelevantRequirementsModal,
        setOpenUnrelevantRequirementsModal,
    ] = useState(false);

    return (
        <>
            <Container>
                <BodyHeader
                    dataChameleon={{
                        key: "requirements",
                        type: "tutorial",
                    }}
                    title={t("title")}
                    subTitle={t("subtitle")}
                    action={
                        <div className="flex space-x-2 items-center justify-end">
                            <DropdownMenu modal={false}>
                                <DropdownTrigger asChild>
                                    <ButtonLarge
                                        variant={ButtonType.OUTLINED}
                                        IconLeft={MoreHorizontal}
                                    />
                                </DropdownTrigger>
                                <DropdownContentLayout
                                    align="end"
                                    side="bottom"
                                    sideOffset={0}
                                >
                                    <BaseItem
                                        onClick={() =>
                                            setOpenUnrelevantRequirementsModal(
                                                true,
                                            )
                                        }
                                        IconLeft={() => (
                                            <Eye className="text-green h-4 w-4" />
                                        )}
                                    >
                                        {t("see_not_relevant", {
                                            count: nonRelevantCount,
                                        })}
                                    </BaseItem>
                                </DropdownContentLayout>
                            </DropdownMenu>
                        </div>
                    }
                />
                <div className="p-4 space-y-4">
                    <div className="w-full flex space-x-4">
                        <AssignementCard {...statsData?.requirementStats} />
                        <ProgressCard {...statsData?.requirementStats} />
                    </div>
                    <RequirementTable table={relevantRequirementsTable} />
                </div>
                <UnrelevantRequirementsModal
                    table={unlevantRequirementsTable}
                    open={openUnrelevantRequirementsModal}
                    setOpen={setOpenUnrelevantRequirementsModal}
                />
                <RequirementModal />
            </Container>
        </>
    );
}
