import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { type Indicator } from "./types";

const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "400px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.indicators);
    },
    cell: ({ row }) => {
        return <Text variant="body-sm">{row.original.name}</Text>;
    },
};

const unitCol: ColumnDef<Indicator> = {
    id: "unit",
    meta: {
        colWidth: "120px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.unit);
    },
    cell: ({ row }) => {
        return (
            <Text variant="body-sm">{row.original.unit?.shortName || ""}</Text>
        );
    },
};

const subthemeCol: ColumnDef<Indicator> = {
    id: "subtheme",

    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.subtheme);
    },
    cell: ({ row }) => {
        return (
            <FlexRow
                alignItems="center"
                justifyContent="center"
                className="border border-neutral-200 rounded-xl p-0.5 w-fit"
                style={{
                    color: row.original.subtheme?.theme?.color,
                }}
                elevation="b-100"
            >
                <Icon name="tagEmptyCircle" size="sm" />
                <Text
                    variant="body-sm"
                    color="primary"
                    px="1"
                    className="  text-primary text-sm"
                    style={{ textWrap: "nowrap" }}
                >
                    {row.original.subtheme?.name}
                </Text>
            </FlexRow>
        );
    },
};

const actionCol: (
    updateObjectiveIndicator: (indicator: Indicator) => void,
    isMutationPending: boolean,
    selectedIndicatorId: string | null,
) => ColumnDef<Indicator> = (
    updateObjectiveIndicator: (indicator: Indicator) => void,
    isMutationPending: boolean,
    selectedIndicatorId: string | null,
) => {
    return {
        id: "select",
        header: () => {
            return "";
        },
        cell: ({ row }) => {
            const handleIndicatorSetClick = () => {
                updateObjectiveIndicator(row.original);
            };
            return (
                <Button
                    onClick={handleIndicatorSetClick}
                    size="sm"
                    className="w-7 h-7"
                    loading={
                        isMutationPending &&
                        selectedIndicatorId === row.original.id
                    }
                >
                    <Icon name="arrowRight" size="sm" className="text-white" />
                </Button>
            );
        },
    };
};

export const useColumns = (
    updateObjectiveIndicator: (indicator: Indicator) => void,
    isMutationPending: boolean,
    selectedIndicatorId: string | null,
): ColumnDef<Indicator>[] => {
    const columns = [
        nameCol,
        unitCol,
        subthemeCol,
        actionCol(
            updateObjectiveIndicator,
            isMutationPending,
            selectedIndicatorId,
        ),
    ];
    return columns;
};
