import { Button } from "@design-system/Inputs/Button";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { FlexRow } from "@design-system/Layout/Flex";
import { type Table } from "@tanstack/react-table";
import { forwardRef, type FC, type PropsWithChildren } from "react";

interface ButtonPaginationProps {
    text?: string;
    disabled?: boolean;
    active?: boolean;
    onClick?: (value?: number) => void;
    value?: number;
}

const ButtonPagination = forwardRef<
    HTMLButtonElement,
    PropsWithChildren<ButtonPaginationProps>
>(({ children, onClick, disabled, active, value }, ref) => {
    const handleClick = () => {
        onClick?.(value);
    };

    return (
        <Button
            active={active}
            className="min-w-8 disabled:bg-white disabled:border-none"
            disabled={disabled}
            onClick={handleClick}
            ref={ref}
            size="sm"
            variant={active ? "outlinedDanger" : "text"}
        >
            {children}
        </Button>
    );
});

type PaginationProps = {
    loading?: boolean;
    table: Table<any>;
};

const InnerPaginationMoreThanSeven = ({ loading, table }: PaginationProps) => {
    const pageCount = table.getPageCount();

    const activePage = table.getState().pagination.pageIndex;
    const isInMiddle = activePage > 2 && activePage < pageCount - 3;

    const goToPage = (page?: number) => {
        if (typeof page !== "number") return;
        table.setPageIndex(page);
    };

    return (
        <>
            <ButtonPagination
                active={activePage === 0}
                onClick={goToPage}
                disabled={loading}
                value={0}
            >
                1
            </ButtonPagination>
            <ButtonPagination
                active={activePage === 1}
                onClick={goToPage}
                disabled={loading}
                value={1}
            >
                2
            </ButtonPagination>
            {isInMiddle ? (
                <>
                    <ButtonPagination active={false} disabled={true}>
                        ...
                    </ButtonPagination>
                    <ButtonPagination
                        active={true}
                        disabled={true}
                    >{`${activePage + 1}`}</ButtonPagination>
                    <ButtonPagination active={false} disabled={true}>
                        ...
                    </ButtonPagination>
                </>
            ) : (
                <>
                    <ButtonPagination
                        active={activePage === 2}
                        onClick={goToPage}
                        disabled={loading}
                        value={2}
                    >
                        3
                    </ButtonPagination>
                    <ButtonPagination>...</ButtonPagination>
                    <ButtonPagination
                        active={activePage === pageCount - 3}
                        onClick={goToPage}
                        disabled={loading}
                        value={pageCount - 3}
                    >
                        {pageCount - 2}
                    </ButtonPagination>
                </>
            )}

            <ButtonPagination
                active={activePage === pageCount - 2}
                onClick={goToPage}
                disabled={false}
                value={pageCount - 2}
            >
                {pageCount - 1}
            </ButtonPagination>
            <ButtonPagination
                active={activePage === pageCount - 1}
                onClick={goToPage}
                disabled={!table.getCanNextPage() || loading}
                value={pageCount - 1}
            >
                {pageCount}
            </ButtonPagination>
        </>
    );
};

const InnerPaginationLessThanSeven = ({ loading, table }: PaginationProps) => {
    const pageCount = table.getPageCount();
    const activePage = table.getState().pagination.pageIndex;
    const goToPage = (page?: number) => {
        if (typeof page !== "number") return;
        table.setPageIndex(page);
    };

    return Array.from({ length: pageCount }, (_, i) => i).map((i) => (
        <ButtonPagination
            key={i}
            active={activePage === i}
            onClick={goToPage}
            value={i}
            disabled={loading}
        >
            {i + 1}
        </ButtonPagination>
    ));
};
export const Pagination: FC<PaginationProps> = ({ loading, table }) => {
    if (!table) {
        throw new Error(
            "TablePagination can be used only inside a Table component",
        );
    }

    const pageCount = table.getPageCount();

    const handlePageSizeChange = (pageSize: string) => {
        const numberPageSize = parseInt(pageSize, 10);
        table.setPageSize(numberPageSize);
    };

    const goToPreviousPage = () => {
        table.previousPage();
    };

    const goToNextPage = () => {
        table.nextPage();
    };

    const isTableEmpty = table.getRowCount() === 0;

    if (
        (pageCount < 2 && table.getState().pagination.pageSize === 10) ||
        isTableEmpty
    ) {
        return null;
    }

    return (
        <FlexRow alignItems="center" justifyContent="between">
            <FlexRow gap="1">
                <ButtonPagination
                    onClick={goToPreviousPage}
                    disabled={!table.getCanPreviousPage() || loading}
                >
                    <Button.Icon name="angleLeft" />
                </ButtonPagination>
                {pageCount <= 7 ? (
                    <InnerPaginationLessThanSeven table={table} />
                ) : (
                    <InnerPaginationMoreThanSeven table={table} />
                )}
                <ButtonPagination
                    onClick={goToNextPage}
                    disabled={!table.getCanNextPage() || loading}
                >
                    <Button.Icon name="angleRight" />
                </ButtonPagination>
            </FlexRow>
            <SingleSelect
                onValueChange={handlePageSizeChange}
                value={table.getState().pagination.pageSize.toString()}
            >
                <SingleSelect.Trigger size="sm" />
                <SingleSelect.Content>
                    {[10, 25, 50, 100].map((pageSize) => (
                        <SingleSelect.Option
                            key={pageSize}
                            label={pageSize.toString(10)}
                            value={pageSize.toString()}
                        />
                    ))}
                </SingleSelect.Content>
            </SingleSelect>
        </FlexRow>
    );
};
