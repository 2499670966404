import i18next from "../../../../i18n";

i18next.addResources("en", "ThemeMultiSelect", {
    placeholder: "Theme",
    placeholder_active: "Theme ({{count}})",
    searchbar_placeholder: "search",
});
i18next.addResources("fr", "ThemeMultiSelect", {
    placeholder: "Thème",
    placeholder_active: "Thème ({{count}})",
    searchbar_placeholder: "chercher",
});
