import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import RoleSelect from "@app/shared/components/RoleSelect";
import { useEffect, useState } from "react";
import { User, UserRole } from "../../../../../generated/client/graphql";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import InputSimple from "../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import InputSelectAvatar from "../../../../components/inputs/InputSelect/Avatar";
import { useUpdateUser } from "../../data";
import "./i18n";

export const toUpdateUserIdAtom = atom<string | undefined>(undefined);

export default function UpdateUserModal({ user }: { user: User | undefined }) {
    const { t } = useTranslation("UpdateUserModal");

    const [toUpdateUserId, setToUpdateUserId] = useAtom(toUpdateUserIdAtom);

    const [firstname, setFirstname] = useState<string | undefined>(
        user?.firstName,
    );
    const [lastname, setLastname] = useState<string | undefined>(
        user?.lastName,
    );
    const [department, setDepartment] = useState<string | undefined>(
        user?.department || undefined,
    );
    const [avatarUrl, setAvatarUrl] = useState<string | undefined>(
        user?.avatar?.url,
    );
    const [role, setRole] = useState<UserRole | undefined>(
        user?.permission?.role || undefined,
    );
    const { mutate, isPending } = useUpdateUser(toUpdateUserId);

    const canSave = true;
    const handleSave = () => {
        canSave &&
            mutate(
                {
                    firstName: firstname,
                    lastName: lastname,
                    department,
                    avatarUrl,
                    role,
                },
                {
                    onSuccess: () => {
                        setToUpdateUserId(undefined);
                    },
                },
            );
    };

    useEffect(() => {
        setFirstname(user?.firstName);
        setLastname(user?.lastName);
        setDepartment(user?.department || "");
        setAvatarUrl(user?.avatar?.url);
        setRole(user?.permission?.role || undefined);
    }, [user]);
    return (
        <CenterModal
            open={toUpdateUserId !== undefined}
            onOpenChange={(open) => {
                !open && setToUpdateUserId(undefined);
            }}
            modal={false}
        >
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="w-full space-y-3">
                    <InputSimple
                        title={t("firstname")}
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                    />
                    <InputSimple
                        title={t("lastname")}
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                    />
                    <InputSimple
                        disabled
                        title={t("email")}
                        value={user?.email}
                    />
                    <InputSimple
                        title={t("department")}
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                    />
                    <InputSelectAvatar
                        value={avatarUrl}
                        onValueChange={(url) => setAvatarUrl(url)}
                    />
                    {user?.permission?.role && (
                        <RoleSelect role={role} setRole={setRole} />
                    )}
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
