import { Icon } from "@design-system/Icon";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import * as Select from "@radix-ui/react-select";
import { Fragment, forwardRef } from "react";
import { Button, type ButtonProps } from "../Button";
import { Text } from "@design-system/Typography/Text";

const Trigger = forwardRef<
    HTMLButtonElement,
    ButtonProps & { placeholder?: string }
>(({ children, placeholder, ...props }, ref) => {
    return (
        <Select.Trigger asChild ref={ref}>
            {children ?? (
                <Button variant="outlined" {...props}>
                    <Select.Value placeholder={placeholder ?? "Select"} />
                    <Button.Icon name="angleDown" />
                </Button>
            )}
        </Select.Trigger>
    );
});

const Content = forwardRef<
    HTMLDivElement,
    Select.PopperContentProps & { hasPortal?: boolean }
>(({ children, className, hasPortal, ...props }, ref) => {
    const Wrapper = hasPortal ? Select.Portal : Fragment;
    return (
        <Wrapper>
            <Select.Content
                position="popper"
                sideOffset={4}
                {...props}
                ref={ref}
            >
                <FlexCol
                    br="xl"
                    className={cn("bg-white", className)}
                    elevation="b-300"
                    p="1"
                >
                    {children}
                </FlexCol>
            </Select.Content>
        </Wrapper>
    );
});

const Option = forwardRef<
    HTMLDivElement,
    Select.SelectItemProps & { label: string }
>(({ className, children, ...props }, ref) => {
    return (
        <Select.Item
            {...props}
            className={cn(
                "w-full p-2 rounded-sm",
                "cursor-pointer",
                "hover:bg-secondary",
                "data-[state=checked]:bg-tertiary",
                "flex items-center gap-3",
                className,
            )}
            ref={ref}
        >
            <Box
                className="w-[18px] h-[18px] content-center"
                display="grid"
                p="0.5"
            >
                <Select.ItemIndicator>
                    <Icon className="text-green" name="check" />
                </Select.ItemIndicator>
            </Box>
            <Select.ItemText>{props.label}</Select.ItemText>
        </Select.Item>
    );
});

const Group = forwardRef<
    HTMLDivElement,
    Select.SelectGroupProps & { label: string }
>(({ label, asChild, ...props }, ref) => {
    return (
        <Select.Group {...props} ref={ref} asChild>
            <FlexCol>
                <Box pt="1" px="2">
                    <Select.Label asChild>
                        <Text color="secondary" variant="body-sm-bold">
                            {label}
                        </Text>
                    </Select.Label>
                </Box>
                {props.children}
            </FlexCol>
        </Select.Group>
    );
});

type SingleSelectComponentType = typeof Select.Root & {
    Trigger: typeof Trigger;
    Content: typeof Content;
    Option: typeof Option;
    Group: typeof Group;
};

export const SingleSelect: SingleSelectComponentType =
    Select.Root as unknown as SingleSelectComponentType;

SingleSelect.Trigger = Trigger;
SingleSelect.Content = Content;
SingleSelect.Option = Option;
SingleSelect.Group = Group;
