import { UserAssign } from "@app/shared/components/UserAssign";
import { Button } from "@design-system/Inputs/Button";
import { Textarea } from "@design-system/Inputs/Textarea";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { type ChangeEvent, type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import { isQuestionType } from "../../services";
import { Question } from "../../types";
import { AnswerTypeSelect } from "../AnswerTypeSelect";

type QuestionBlockProps = {
    id: number;
    onChange: (id: number, question: Partial<Question>) => void;
    onDelete: (id: number) => void;
    question: Partial<Question>;
};

export const QuestionBlock: FC<QuestionBlockProps> = ({
    id,
    onChange,
    onDelete,
    question: { name, type, ownerId },
}) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const handleNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(id, { name: e.target.value });
    };

    const handleTypeChange = (value: string[]) => {
        const [questionType] = value;
        if (!isQuestionType(questionType)) {
            return;
        }

        onChange(id, { type: questionType });
    };

    const handleOwnerSelect = (value: string[]) => {
        onChange(id, { ownerId: value[0] });
    };

    const handleDelete = () => {
        onDelete(id);
    };

    const typeValue = type ? [type] : [];
    const ownerValue = ownerId ? [ownerId] : [];

    return (
        <FlexCol gap="4" p="4">
            <Textarea
                onChange={handleNameChange}
                placeholder={t(
                    keys.create_questions_modal_question_name_placeholder,
                )}
                value={name}
            />
            <FlexRow justifyContent="between">
                <FlexRow gap="4">
                    <AnswerTypeSelect
                        defaultValue={typeValue}
                        onChange={handleTypeChange}
                    />
                    <UserAssign
                        selectedUsers={ownerValue}
                        onSelect={handleOwnerSelect}
                        selectMode="single"
                    />
                </FlexRow>
                <Button variant="flatDanger" onClick={handleDelete} size="sm">
                    <Button.Icon name="trash" />
                </Button>
            </FlexRow>
        </FlexCol>
    );
};
