import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { useSetAtom } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { isExampleDrawerOpen } from "../ExampleDrawer/ctx";
import { keys, RESOURCE_NAME } from "./i18n";
import { IndicatorDisplay } from "./IndicatorDisplay";
import { ObjectiveTargets } from "./ObjectiveTargets";

type ObjectiveContentProps = {
    objective?: GetObjectiveQuery["objective"];
};

export const ObjectiveContent: FC<ObjectiveContentProps> = ({ objective }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const setIsExampleDrawerOpen = useSetAtom(isExampleDrawerOpen);
    const handleShowExampleClick = () => {
        setIsExampleDrawerOpen(true);
    };
    return (
        <FlexCol w="full" gap="5">
            <FlexRow justifyContent="between" alignItems="end">
                <FlexRow alignItems="center" className="gap-[16px]">
                    <Text variant="body-bold">{t(keys.content_subtitle)}</Text>
                </FlexRow>
                <Button
                    variant="text"
                    size="sm"
                    onClick={handleShowExampleClick}
                >
                    <Text variant="body-sm">{t(keys.show_example)}</Text>
                    <Icon name="info" size="sm" />
                </Button>
            </FlexRow>
            <hr />
            <FlexRow
                px="3"
                py="2.5"
                gap="3"
                br="lg"
                className="bg-accent-100 border border-accent-450"
            >
                <Icon name="stars" size="sm" className="text-accent-700" />
                <Text variant="body-sm">{t(keys.targets_guidance)}</Text>
            </FlexRow>
            <FlexCol br="lg" className="border border-secondary">
                <IndicatorDisplay objective={objective} />
                {objective?.indicatorId && (
                    <ObjectiveTargets objective={objective} />
                )}
            </FlexCol>
        </FlexCol>
    );
};
