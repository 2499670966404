/* eslint-disable @typescript-eslint/no-floating-promises */
import { atom } from "jotai";

import Container from "../../components/Container";
import BodyHeader from "../../shared/components/BodyHeader";

import { useTranslation } from "react-i18next";
import ThemesList from "./components/ThemesList";
import CreateModal from "./createModal";
import "./i18n";
export const modalIsMountedAtom = atom(false);
export const themeIdAtom = atom("");

export default function Themes() {
    const { t } = useTranslation("Themes");
    return (
        <Container>
            <BodyHeader
                dataChameleon={{
                    key: "material-topics",
                    type: "help",
                }}
                title={t("title")}
                subTitle={t("subtitle")}
            />
            <div className="p-4">
                <ThemesList />
            </div>

            <CreateModal />
        </Container>
    );
}
