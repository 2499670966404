import i18next from "@app/i18n";

const enResources = {
    apply: "Apply",
    default_title_placeholder: "Your objective title",
    no_subtheme_placeholder: "No subtheme",
    no_owner_placeholder: "Unassigned",
    no_target: "No target defined",
    objective_table_action_select_action: "Select an action",
    objective_table_action_assign_owner: "Assign owner ({{count}})",
    header_name: "Objective",
    header_subtheme: "Subtheme",
    header_owner: "Owner",
    header_target: "Target",
    header_date: "Date",
    empty_state_search_title: "No objectives found",
    empty_state_search_subtitle: `Try changing your search parameters`,
    update_success: "Objective updated",
    update_error: "Error updating objective",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    apply: "Enregistrer",
    default_title_placeholder: "Votre objectif",
    no_subtheme_placeholder: "Aucun sous-thème",
    no_owner_placeholder: "Non attribué",
    no_target: "Aucun objectif défini",
    objective_table_action_select_action: "Choisir une action",
    objective_table_action_assign_owner: "Assigner un propriétaire ({{count}})",
    header_name: "Objectif",
    header_subtheme: "Sous-thème",
    header_owner: "Propriétaire",
    header_target: "Chiffrage",
    header_date: "Date",
    empty_state_search_title: "Aucun objectif trouvé",
    empty_state_search_subtitle: `Essayez de modifier vos paramètres de recherche`,
    update_success: "Objectif mis à jour",
    update_error: "Erreur lors de la mise à jour de l'objectif",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "ObjectiveTable";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
