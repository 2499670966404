import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useMemo, useState, type FC } from "react";

import { Spinner } from "@app/components/TipTap/components/ui/Spinner";
import { makeDocumentLink } from "@app/screens/Global/AskAIModal/services";
import { AiSuggestion } from "@app/shared/components/ai/AiSuggestion";
import { FileIcon } from "@app/shared/components/FileIcon";
import { Button } from "@design-system/Inputs/Button";
import { cn } from "@design-system/Utilities";

import { removeAiAnnotation } from "@app/screens/Frameworks/framework/Csrd/pillar/services";
import { useParams } from "react-router-dom";
import {
    useGenerateAiSuggestion,
    useGetDocuments,
    useQuestionAndAnswerUpdate,
} from "../data";
import { ExternalQuestionnaireQuestion } from "../types";

export const AnswerAiSuggestion: FC<{
    question: ExternalQuestionnaireQuestion;
}> = ({ question }) => {
    const [isAiSuggestionOpen, setIsAiSuggestionOpen] =
        useState<boolean>(false);
    const { org_uname } = useParams();
    const { updateAnswer, updateEvidenceFilesAsync } =
        useQuestionAndAnswerUpdate(question.id, question.answer?.id);

    const aiSuggestionContent = useMemo(() => {
        try {
            return question?.answer?.aiSuggestion?.text === undefined
                ? {}
                : JSON.parse(question?.answer?.aiSuggestion?.text);
        } catch (error) {
            return {};
        }
    }, [question?.answer?.aiSuggestion]);

    const { documents: suggestedDocuments } = useGetDocuments(
        question?.answer?.aiSuggestion?.annotationDocumentIds ?? [],
    );

    const { mutate: generateAiSuggestion, isPending: isSuggestPending } =
        useGenerateAiSuggestion();

    const handleSuggestButtonClick = () => {
        generateAiSuggestion(question.id, {
            onSettled: () => {
                setIsAiSuggestionOpen(true);
            },
        });
    };

    const handleToggleOpen = () => {
        setIsAiSuggestionOpen(!isAiSuggestionOpen);
    };

    const handleConfirmClick = () => {
        updateAnswer(removeAiAnnotation(aiSuggestionContent?.text || ""));
        updateEvidenceFilesAsync({
            fileIds: suggestedDocuments
                ?.map(
                    (document) => document?.lastApprovedVersion?.finalFile?.id,
                )
                .filter(Boolean) as string[],
        });
        setIsAiSuggestionOpen(false);
    };

    return (
        <AiSuggestion
            isOpen={isAiSuggestionOpen}
            isLoading={isSuggestPending}
            className="flex flex-col justify-center align-middle"
        >
            <AiSuggestion.Header title="AI suggestion">
                {isSuggestPending ? (
                    <FlexRow
                        justifyContent="center"
                        alignItems="center"
                        w="7"
                        h="7"
                    >
                        <Spinner className="border-t-beavrGreen w-5 h-5" />
                    </FlexRow>
                ) : !question?.answer?.aiSuggestion &&
                  !isAiSuggestionOpen &&
                  !isSuggestPending ? (
                    <Button size="sm" onClick={handleSuggestButtonClick}>
                        Suggest
                    </Button>
                ) : (
                    <Button
                        size="sm"
                        variant="plain"
                        className="w-fit h-fit"
                        onClick={handleToggleOpen}
                    >
                        <Icon
                            name={isAiSuggestionOpen ? "angleUp" : "angleDown"}
                            size="sm"
                            className="text-[#01C33B]"
                        />
                    </Button>
                )}
            </AiSuggestion.Header>

            <AiSuggestion.Body
                className={cn(!question?.answer?.aiSuggestion && "pb-5")}
            >
                <AiSuggestion.Block
                    title="Answer"
                    content={removeAiAnnotation(
                        aiSuggestionContent?.text || "",
                    )}
                    type="text"
                    withCopy
                />

                {!!suggestedDocuments?.length && (
                    <AiSuggestion.ReferenceBlock title="References">
                        <FlexCol>
                            {suggestedDocuments?.map((document) => (
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="between"
                                    gap="3"
                                    alignSelf="stretch"
                                    key={document.id}
                                >
                                    <a
                                        href={
                                            makeDocumentLink(
                                                {
                                                    type: document.type,
                                                    uniqueUrl:
                                                        document.uniqueUrl,
                                                },
                                                org_uname,
                                            ) || undefined
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-inherit cursor-pointer"
                                    >
                                        <FlexRow
                                            alignItems="center"
                                            gap="3"
                                            alignSelf="stretch"
                                        >
                                            <FileIcon
                                                mimeType={
                                                    document
                                                        ?.lastApprovedVersion
                                                        ?.finalFile?.mimeType ||
                                                    "application/pdf"
                                                }
                                            />
                                            <Text
                                                variant="body-bold"
                                                color="brand"
                                            >
                                                {document?.name}
                                            </Text>
                                        </FlexRow>
                                    </a>
                                </FlexRow>
                            ))}
                        </FlexCol>
                    </AiSuggestion.ReferenceBlock>
                )}
            </AiSuggestion.Body>

            <AiSuggestion.Footer>
                <Button
                    variant="plain"
                    size="sm"
                    className="w-fit"
                    onClick={handleSuggestButtonClick}
                    disabled={isSuggestPending}
                >
                    Re-generate
                </Button>
                <AiSuggestion.Confirm onClick={handleConfirmClick}>
                    Confirm
                </AiSuggestion.Confirm>
            </AiSuggestion.Footer>
        </AiSuggestion>
    );
};
