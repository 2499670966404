import { useSetToast } from "@app/components/Toast";
import { Icon } from "@design-system/Icon";
import { Button, ButtonProps } from "@design-system/Inputs/Button";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Box } from "@design-system/Layout/Box";
import {
    FlexCol,
    FlexRow,
    type OrientedFlexProps,
} from "@design-system/Layout/Flex";
import { Text, type TextProps } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import {
    createContext,
    type FC,
    type PropsWithChildren,
    useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "./i18n";

const Header: FC<TextProps & { title?: string; className?: string }> = ({
    title,
    className,
    ...props
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const context = useContext(AiSuggestionContext);
    return (
        <FlexRow
            alignItems="center"
            justifyContent="between"
            gap="1"
            as="header"
            className={cn("p-4", className)}
        >
            <FlexRow>
                <Box
                    className="flex"
                    alignItems="center"
                    justifyContent="center"
                    w="5"
                    h="5"
                >
                    <Icon
                        name="stars"
                        size="sm"
                        className={cn(
                            "text-[#01C33B]",
                            context?.isLoading && "animate-pulse",
                        )}
                    />
                </Box>

                <Text
                    {...props}
                    variant="body-sm-bold"
                    className={cn(
                        "bg-gradient-to-r from-[#01C33B] to-[#03A365] bg-clip-text text-transparent",
                        context?.isLoading && "animate-pulse",
                    )}
                >
                    {title ?? t(keys.defaultTitle)}
                </Text>
            </FlexRow>

            {props.children}
        </FlexRow>
    );
};

const Body: FC<OrientedFlexProps> = ({ className, ...props }) => {
    const context = useContext(AiSuggestionContext);
    return (
        context?.isOpen && (
            <FlexCol className={cn("px-4", className)} gap="5" {...props} />
        )
    );
};

const Block: FC<{
    title: string;
    content: string;
    type?: "value" | "text";
    withCopy?: boolean;
}> = ({ title, content, withCopy, type }) => {
    const { setToastSuccess } = useSetToast();
    const { t } = useTranslation(RESOURCE_NAME);
    const handleCopyLinkCLick = () => {
        navigator.clipboard.writeText(content);
        setToastSuccess(t(keys.copy_to_clipboard));
    };

    return (
        <FlexCol gap="2">
            <FlexRow alignItems="center" justifyContent="between">
                <Text
                    variant="body-sm-bold"
                    color="secondary"
                    className="uppercase"
                >
                    {title}
                </Text>
                {withCopy && (
                    <Box
                        onClick={handleCopyLinkCLick}
                        className="cursor-pointer p-1 text-brand"
                    >
                        <Icon name="duplicate" size="sm" />
                    </Box>
                )}
            </FlexRow>
            {type === "text" ? (
                <Textarea disabled className="h-[150px]" value={content} />
            ) : (
                <Text>{content}</Text>
            )}
        </FlexCol>
    );
};

const ReferenceBlock: FC<
    PropsWithChildren<OrientedFlexProps & { title: string }>
> = ({ children, title, ...props }) => {
    return (
        <FlexCol gap="3" {...props}>
            <Text
                variant="body-sm-bold"
                color="secondary"
                className="uppercase"
            >
                {title}
            </Text>
            {children}
        </FlexCol>
    );
};

const Footer: FC<OrientedFlexProps> = ({ className, ...props }) => {
    const context = useContext(AiSuggestionContext);
    return (
        context?.isOpen && (
            <FlexRow
                {...props}
                as="footer"
                className={className}
                gap="4"
                p="4"
                justifyContent="end"
                w="full"
            />
        )
    );
};

const Confirm: FC<ButtonProps> = ({ ...props }) => {
    const context = useContext(AiSuggestionContext);
    return (
        <Button
            {...props}
            variant="flatPrimary"
            size="sm"
            disabled={context?.isLoading}
        />
    );
};

type AiSuggestionType = FC<
    OrientedFlexProps & {
        isOpen: boolean;
        isLoading?: boolean;
    }
> & {
    Header: typeof Header;
    Body: typeof Body;
    Block: typeof Block;
    ReferenceBlock: typeof ReferenceBlock;
    Footer: typeof Footer;
    Confirm: typeof Confirm;
};

const AiSuggestionContext = createContext<
    | {
          isOpen: boolean;
          isLoading?: boolean;
      }
    | undefined
>(undefined);

export const AiSuggestion: AiSuggestionType = ({
    className,
    isOpen,
    isLoading,
    ...props
}) => {
    return (
        <AiSuggestionContext.Provider value={{ isOpen, isLoading }}>
            <FlexCol className="bg-gradient-to-r from-[#01C33B] to-[#03A365] rounded-xl p-[1px] w-full">
                <FlexCol
                    {...props}
                    className={cn(
                        "bg-[#EEFFF2] rounded-xl max-h-[5000px] transition-max-height duration-500 ease-in-out overflow-hidden",
                        className,
                    )}
                    gap="1"
                />
            </FlexCol>
        </AiSuggestionContext.Provider>
    );
};

AiSuggestion.Header = Header;
AiSuggestion.Body = Body;
AiSuggestion.Block = Block;
AiSuggestion.ReferenceBlock = ReferenceBlock;
AiSuggestion.Footer = Footer;
AiSuggestion.Confirm = Confirm;
