import { Icon } from "@design-system/Icon";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";
import { ObjectiveContent } from "./ObjectiveContent";
import { ObjectiveDescription } from "./ObjectiveDescription";
import { ObjectiveActionPlan } from "./ObjectiveActionPlan";

type ObjectiveBodyProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveBody: FC<ObjectiveBodyProps> = ({ objective }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol gap="8">
            <ObjectiveContent objective={objective} />

            <FlexCol gap="5">
                <FlexRow alignItems="center" justifyContent="between">
                    <Text variant="body-bold">
                        {t(keys.description_subtitle)}
                    </Text>
                </FlexRow>
                <hr />
                <FlexRow
                    px="3"
                    py="2.5"
                    gap="3"
                    br="lg"
                    className="bg-accent-100 border border-accent-450"
                >
                    <Icon name="stars" size="sm" className="text-accent-700" />
                    <Text variant="body-sm">
                        {t(keys.description_guidance)}
                    </Text>
                </FlexRow>
                <ObjectiveDescription
                    objective={objective}
                    key={objective.id}
                />
            </FlexCol>
            <FlexCol gap="5">
                <FlexRow alignItems="center" justifyContent="between">
                    <Text variant="body-bold">{t(keys.action_plan_title)}</Text>
                </FlexRow>
                <hr />
                <FlexRow
                    px="3"
                    py="2.5"
                    gap="3"
                    br="lg"
                    className="bg-accent-100 border border-accent-450"
                >
                    <Icon name="stars" size="sm" className="text-accent-700" />
                    <Text variant="body-sm">
                        {t(keys.action_plan_guidance)}
                    </Text>
                </FlexRow>
                <ObjectiveActionPlan objective={objective} key={objective.id} />
            </FlexCol>
        </FlexCol>
    );
};
