import i18next from "@app/i18n";

const enResources = {
    ask_ai: "Ask Cleo",
    ask_me_anything: "Ask me anything",
    conversation: "Conversation {{ index }}",
    error_creating_thread: "Error creating thread. Please try again later",
    hystory: "History",
    loader: "Thinking",
    searching_files: "Searching through your files",
    searching_reporting: "Reading your reporting data",
    new_thread_btn: "New chat",
    error_title: "What's going on with Cleo ?",
    error_description:
        "Please reload the page or try again later. If the issue persists, create a new chat.",
    error_cta: "Reload page",
    no_threads_msg:
        "Welcome! There are no chats with Cleo yet, start a new chat!",
    passed_100_messages: "You can only retrieve the last 100 messages.",
    link_tooltip: "This link will redirect you to an external website.",
    run_status_cancelling:
        "This chat is temporarily unavailable. Please try again later or create a new chat.",
    run_error:
        "There were some troubles processing your request. Please try again.",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    ask_ai: "Demander à Cleo",
    ask_me_anything: "Posez-moi une question",
    conversation: "Conversation {{ index }}",
    error_creating_thread:
        "Erreur lors de la création du fil. Veuillez réessayer plus tard",
    hystory: "Historique",
    loader: "En réflexion",
    searching_files: "Lecture de vos fichiers",
    searching_reporting: "Recherche dans votre reporting",
    new_thread_btn: "Nouvelle conversation",
    error_title: "Que se passe-t-il avec Cleo ?",
    error_description:
        "Veuillez recharger la page ou réessayez plus tard. Si l'erreur persiste, créez un nouveau chat.",
    error_cta: "Recharger la page",
    no_threads_msg:
        "Bienvenue! Il n'y a pas encore de tchats avec Cleo, commencez un nouveau tchat!",
    passed_100_messages:
        "Vous ne pouvez récupérer que les 100 derniers messages.",
    link_tooltip: "Ce lien vous redirigera vers un site web externe.",
    run_status_cancelling:
        "Cette conversation est temporairement indisponible. Veuillez réessayer plus tard ou créer un nouveau chat.",
    run_error:
        "Il y a eu des difficultés lors du traitement de votre demande. Veuillez réessayer.",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AskAiModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
