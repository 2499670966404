import { Dropdown as UniversalDropdown } from "@design-system/Inputs/UniversalDropdown";
import { useDropdownContentStyles } from "@design-system/Inputs/UniversalDropdown/styles";
import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../../../../generated/client/graphql";
import Avatar from "../../../User/Avatar";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";

const UserDropdown = ({
    selectedUserIds,
    onUserIdChange,
    users,
    preventDefault,
    ...props
}: {
    selectedUserIds?: (string | null)[];
    onUserIdChange: (userId: string | undefined) => void;
    users: User[];
    preventDefault?: boolean;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const [searchFilterString, setSearchFilterString] = useState("");
    const contentClassName = useDropdownContentStyles(props.className);

    const { t } = useTranslation("UserDropdown");
    return (
        <DropdownContentLayout {...props} className={contentClassName}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />
            <DropdownPrimitive.Item
                className="focus:outline-none"
                onClick={(e) => {
                    onUserIdChange(undefined);
                    e.stopPropagation();
                }}
            >
                <UniversalDropdown.Item variant="negative">
                    <UniversalDropdown.ItemIcon name="userDelete" />
                    <UniversalDropdown.ItemText>
                        {t("unset")}
                    </UniversalDropdown.ItemText>
                </UniversalDropdown.Item>
            </DropdownPrimitive.Item>
            {users
                .filter(
                    ({ firstName, lastName, email }) =>
                        !searchFilterString ||
                        [firstName, lastName, email].some(
                            (s) =>
                                s &&
                                s
                                    .toLowerCase()
                                    .includes(searchFilterString.toLowerCase()),
                        ),
                )
                .map((user, idx) => (
                    <DropdownPrimitive.Item
                        key={idx}
                        onClick={(e) => {
                            onUserIdChange(user.id);
                            e.stopPropagation();
                            preventDefault && e.preventDefault();
                        }}
                        className="focus:outline-none"
                    >
                        <UniversalDropdown.Item
                            active={selectedUserIds?.includes(user.id)}
                        >
                            <Avatar
                                avatarUrl={user.avatar?.signedUrl}
                                initials={user.initials}
                                size="small"
                            />
                            <UniversalDropdown.ItemText>
                                {user.firstName} {user.lastName}
                            </UniversalDropdown.ItemText>
                        </UniversalDropdown.Item>
                    </DropdownPrimitive.Item>
                ))}
        </DropdownContentLayout>
    );
};

export default UserDropdown;
