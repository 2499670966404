import { Icon } from "@design-system/Icon";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    BooleanEnum,
    CsrdDatapointPatch,
    MaterialityStatus,
} from "@generated/client/graphql";
import { useSetAtom } from "jotai";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { CsrdDatapoint } from "../../../types";
import { openMaterialityModalAtom } from "../../ctx";
import "./i18n";
import { UseDatapointStateType } from "./useDatapoint";

export const ActionDropdown: FC<{
    datapoint: CsrdDatapoint;
    datapointState: UseDatapointStateType;
    handleChange: (patch: CsrdDatapointPatch) => void;
}> = ({ datapoint, datapointState, handleChange }) => {
    const { t } = useTranslation("FrameworkDatapoint");
    const openMaterialityModal = useSetAtom(openMaterialityModalAtom);

    const handleMaterialityModalSelect = () => {
        openMaterialityModal(
            datapoint?.materiality?.status as MaterialityStatus,
            datapoint?.materiality?.comment || "",
            datapoint?.id,
            undefined,
            datapoint?.name,
        );
    };

    const handleNoDataChange = (_: Event) => {
        datapointState.setValueNoData(!datapointState.valueNoData);
        handleChange({
            value: {
                noData:
                    datapointState.valueNoData === true
                        ? BooleanEnum.False
                        : datapointState.valueNoData === false
                          ? BooleanEnum.True
                          : BooleanEnum.Unknown,
            },
        });
    };

    return (
        <DropdownMenu modal={false}>
            <DropdownMenu.Trigger>
                <Icon name="more" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end">
                {
                    <Tooltip delayDuration={0}>
                        <Tooltip.Trigger>
                            <DropdownMenu.Item
                                onSelect={handleNoDataChange}
                                disabled={
                                    datapoint?.mandatory === BooleanEnum.True
                                }
                            >
                                <DropdownMenu.ItemIcon
                                    name={
                                        datapointState.valueNoData
                                            ? "edit"
                                            : "editCrossed"
                                    }
                                />

                                <FlexRow w="full">
                                    <Text
                                        variant="body"
                                        color={
                                            datapoint?.mandatory ===
                                            BooleanEnum.True
                                                ? "disabled"
                                                : "dark"
                                        }
                                    >
                                        {datapointState.valueNoData
                                            ? t("data_available")
                                            : t("no_data")}
                                    </Text>
                                </FlexRow>
                            </DropdownMenu.Item>
                        </Tooltip.Trigger>
                        {datapoint?.mandatory === BooleanEnum.True && (
                            <Tooltip.Content>
                                {t("data_is_required")}
                            </Tooltip.Content>
                        )}
                    </Tooltip>
                }
                <DropdownMenu.Item onSelect={handleMaterialityModalSelect}>
                    <DropdownMenu.ItemIcon name="eyeOff" />
                    <FlexRow alignItems="center">
                        <Text variant="body" color="dark">
                            {t("manage_materiality")}
                        </Text>
                    </FlexRow>
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu>
    );
};
