import Floatbar from "@app/components/Floatbar";
import { useSetToast } from "@app/components/Toast";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import { Button } from "@design-system/Inputs/Button";
import { UpdateObjectiveInput } from "@generated/client/graphql";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateObjectives } from "../../data";
import "../../i18n";
import { Objective } from "./types";

enum Actions {
    ASSIGN_OWNER = "ASSIGN_OWNER",
}

const ObjectiveTableActionBar = ({
    objectives,
    unset,
}: {
    objectives: Objective[];
    unset: () => void;
}) => {
    const { t } = useTranslation("ObjectiveTable");

    const [ownerId, setOwnerId] = useState<string | null | undefined>(null);

    const [searchOwnerFilter, setSearchOwnerFilter] = useState<string>();
    const { mutate, isPending } = useUpdateObjectives(
        objectives.map((o) => o.id),
    );
    const { setToastSuccess, setToastError } = useSetToast();

    const onOwnerApply = () => {
        const ids = objectives
            .map((elt) => elt?.id)
            .filter((elt) => elt !== undefined) as string[];
        if (!ids) {
            return;
        }
        const inputs: UpdateObjectiveInput[] = objectives.map((o) => ({
            id: o.id,
            ownerId: ownerId || null,
        }));

        mutate(inputs, {
            onSuccess: () => {
                setToastSuccess(t("update_success"));
                unset();
                setOwnerId(null);
            },
            onError: () => {
                setToastError(t("update_error"));
            },
        });
    };

    return (
        <Floatbar
            unset={unset}
            text={t("objective_table_action_assign_owner", {
                count: objectives.length,
            })}
            open={objectives.length > 0}
            primary={
                <UserChipSelect
                    userId={ownerId}
                    setUserId={setOwnerId}
                    searchFilter={searchOwnerFilter}
                    onSearchFilterChange={setSearchOwnerFilter}
                    showUnsetByDefault={true}
                />
            }
            secondary={
                <Button
                    size="sm"
                    onClick={onOwnerApply}
                    disabled={ownerId === null}
                    loading={isPending}
                >
                    {t("apply")}
                </Button>
            }
            activeAction={Actions.ASSIGN_OWNER}
        />
    );
};

export default ObjectiveTableActionBar;
