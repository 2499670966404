import UserSelect from "@app/components/Dropdown/dropdowns/User";
import Loading from "@app/components/Loading";
import { useSetToast } from "@app/components/Toast";
import { DocumentsModal } from "@app/shared/components/documentUpload/DocumentsModal";
import { UploadedDocumentsList } from "@app/shared/components/documentUpload/UploadedDocumentsList";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Switch } from "@design-system/Inputs/Switch";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ValidationStatus } from "@generated/client/graphql";
import { useRef, useState, type ChangeEvent, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { EditOrDeleteDropdown } from "../../components/EditOrDeleteDropdown";
import { useQuestion, useQuestionAndAnswerUpdate } from "../data";
import { keys, RESOURCE_NAME } from "../i18n";
import { AnswerAiSuggestion } from "./AnswerAiSuggestion";

export const QuestionSection: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { external_questionnaire_question_id: id } = useParams();
    const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
    const { question, isPending } = useQuestion(id);
    const {
        toggleValidation,
        updateAnswer,
        updateComment,
        updateOwner,
        updateEvidenceFilesAsync,
        removeEvidenceFiles,
        isPending: isUpdatePending,
    } = useQuestionAndAnswerUpdate(id, question?.answer?.id);
    const answerTxtRef = useRef(question?.answer?.value?.string);
    const commentTxtRef = useRef(question?.answer?.comment);
    const handleAnswerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        answerTxtRef.current = e.target.value;
    };
    const { setToastSuccess, setToastError } = useSetToast();

    const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        commentTxtRef.current = e.target.value;
    };

    const openDocumentsModal = () => {
        setIsDocumentsModalOpen(true);
    };

    const closeDocumentsModal = () => {
        setIsDocumentsModalOpen(false);
    };

    const saveAnswer = () => {
        if (question?.answer?.value?.string === answerTxtRef.current) return;
        updateAnswer(answerTxtRef.current, {
            onSuccess: () => {
                setToastSuccess(t(keys.answer_saved));
            },
            onError: () => {
                setToastError(t(keys.answer_saved_error));
            },
        });
    };

    const saveComment = () => {
        if (question?.answer?.comment === commentTxtRef.current) return;
        updateComment(commentTxtRef.current ?? "", {
            onSuccess: () => {
                setToastSuccess(t(keys.comment_saved));
            },
            onError: () => {
                setToastError(t(keys.comment_saved_error));
            },
        });
    };

    const removeDocument = (fileId: string) => {
        removeEvidenceFiles([fileId]);
    };

    const isValidated = question?.answer?.status === ValidationStatus.Validated;

    return (
        <Box
            className="bg-tertiary border border-tertiary"
            br="lg"
            w="full"
            h="full"
            p="4"
        >
            {isPending ? (
                <Loading size="large" />
            ) : (
                <FlexRow
                    className="bg-primary border border-tertiary"
                    gap="4"
                    p="4"
                    br="lg"
                >
                    <FlexCol justifyContent="start" alignItems="center">
                        <StatusTagCircular
                            className="mt-2"
                            status={isValidated ? "validated" : "todo"}
                        />
                    </FlexCol>
                    <FlexCol w="full" gap="3" pt="0.5">
                        <Text variant="body-lg-bold">{question?.name}</Text>
                        {question?.description && (
                            <Text mb="2">{question.description}</Text>
                        )}
                        <FlexRow className="gap-[6px]" alignItems="center">
                            <Text variant="body-sm" color="secondary">
                                {t(keys.owner)}
                            </Text>
                            <UserSelect
                                selectedUserId={question?.ownerId ?? undefined}
                                onChange={updateOwner}
                            />
                        </FlexRow>
                        <Textarea
                            defaultValue={question?.answer?.value?.string}
                            placeholder={t(keys.answer_placeholder)}
                            onChange={handleAnswerChange}
                            onBlur={saveAnswer}
                        />
                        <Textarea
                            defaultValue={question?.answer?.comment ?? ""}
                            placeholder={t(keys.comment_placeholder)}
                            onChange={handleCommentChange}
                            onBlur={saveComment}
                        />
                        <a
                            className="flex items-center gap-1 text-base font-bold text-brand w-fit text-left cursor-pointer"
                            onClick={openDocumentsModal}
                        >
                            {t(keys.add_docs)}{" "}
                            <Icon name="circlePlus" size="sm" />
                        </a>
                        {!!question?.answer?.evidenceFiles?.length && (
                            <UploadedDocumentsList
                                files={question.answer.evidenceFiles}
                                onRemove={removeDocument}
                            />
                        )}
                        <Box className="flex items-center gap-1">
                            <Switch
                                disabled={isUpdatePending}
                                checked={isValidated}
                                onChange={toggleValidation}
                                label={t(keys.validate_answer)}
                                labelPosition="right"
                            />
                        </Box>

                        {question && <AnswerAiSuggestion question={question} />}
                    </FlexCol>
                    <FlexCol justifyContent="start" alignItems="center">
                        <EditOrDeleteDropdown id={id}>
                            <Button size="sm" variant="text">
                                <Button.Icon name="more" />
                            </Button>
                        </EditOrDeleteDropdown>
                    </FlexCol>
                </FlexRow>
            )}
            <DocumentsModal
                onConfirm={updateEvidenceFilesAsync}
                open={isDocumentsModalOpen}
                onClose={closeDocumentsModal}
                loading={isUpdatePending}
            />
        </Box>
    );
};
