import { type ClassValue as CvaClassValue } from "class-variance-authority/types";
import { clsx } from "clsx";
import { useEffect, useState, type RefObject } from "react";
import { twMerge } from "tailwind-merge";
import { useControllableState as useControllableStateNoJsDoc } from "@radix-ui/react-use-controllable-state";
export type ClassValue = CvaClassValue;

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

/**
 * Check if an element is overflown
 * @param element The element to check
 * @returns True if the element is overflown
 * @example
 * ```tsx
 * const element = document.getElementById('my-element');
 * if (isOverflown(element)) {
 *  console.log('Element is overflown');
 * }
 * ```
 */
export function isOverflown(element?: HTMLElement) {
    if (!element) return false;
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

/**
 * React hook to check if an element is overflown
 * It observes element resize and updates the state
 * if the element is overflown.
 * @param ref The ref to the element to check
 * @param watch A value to watch for changes, since the ref is doesn't trigger a re-render
 * @returns True if the element is overflown
 * @example
 * ```tsx
 * const ref = useRef(null);
 * const isOverflown = useIsOverflown(ref);
 * if (isOverflown) {
 *  console.log('Element is overflown');
 * }
 * ```
 */
export function useIsOverflown(ref: RefObject<HTMLElement>, watch?: unknown) {
    const [isOverflownState, setIsOverflownState] = useState(false);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver(() => {
            setIsOverflownState(isOverflown(element));
        });

        resizeObserver.observe(element);

        // Clean up function
        return () => {
            resizeObserver.unobserve(element);
        };
    }, [ref, isOverflownState, watch]);

    return isOverflownState;
}

export function getRandomBgColorForWhiteText() {
    const colors = [
        "#03A365", // green
        "#1081E9", // azzurro
        "#E36DC2", // pink
        "#EC825A", // orange
        "#F2C94C", // yellow
        "#9254E3", // purple
        "#2CA5BF", // teal
    ];
    return colors[Math.floor(Math.random() * colors.length)];
}

type UseControllableStateParams<T> = {
    prop?: T | undefined;
    defaultProp?: T | undefined;
    onChange?: (state: T) => void;
};
/**
 * Hook
 * This is a wrapper around useControllableStateNoJsDoc that adds JSDoc comments to the function.
 */
/**
 * A custom React hook that manages a stateful value that can either be controlled
 * by a parent component or managed internally within the component.
 * This is a wrapper around useControllableStateNoJsDoc that adds JSDoc comments to the function.
 *
 * @template T - The type of the state value.
 *
 * @param {UseControllableStateParams<T>} params - The parameters for configuring the hook.
 * @param {T | undefined} [params.prop] - The controlled state value passed by the parent component.
 *    If defined, this value will always be used as the current state.
 * @param {T | undefined} [params.defaultProp] - The initial value for the uncontrolled state.
 *    Used only if the state is not controlled (`prop` is undefined).
 * @param {(state: T) => void} [params.onChange] - A callback function triggered when the state changes.
 *    This is called whenever the value of the state is updated, either controlled or uncontrolled.
 *
 * @returns {[T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>]}
 *    A tuple containing:
 *    - `value` (T | undefined): The current state value (either controlled or uncontrolled).
 *    - `setValue` (React.Dispatch<React.SetStateAction<T | undefined>>): A function to update the state.
 *        - If the state is controlled, this function calls the `onChange` handler with the new value.
 *        - If the state is uncontrolled, it updates the internal state value.
 *
 * @description
 * This hook enables components to support both controlled and uncontrolled state patterns:
 * - **Controlled State:** When `prop` is provided, the component relies entirely on the parent
 *   to manage the state. The `setValue` function triggers the `onChange` callback with the new value.
 * - **Uncontrolled State:** When `prop` is undefined, the component manages its own state internally
 *   using `defaultProp` as the initial value. The `onChange` callback is still triggered whenever
 *   the state changes.
 *
 * @example
 * // Controlled usage
 * const [controlledValue, setControlledValue] = React.useState('controlled');
 * const [value, setValue] = useControllableState({
 *   prop: controlledValue,
 *   onChange: (newValue) => setControlledValue(newValue),
 * });
 *
 * @example
 * // Uncontrolled usage
 * const [value, setValue] = useControllableState({ defaultProp: 'default' });
 */
export function useControllableState<T>(props: UseControllableStateParams<T>) {
    return useControllableStateNoJsDoc(props);
}
