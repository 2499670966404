import { allowNestedClickWithCallback } from "@app/shared/utils/allowNestedClickWithCallback";
import { Button } from "@design-system/Inputs/Button";
import { FileStack } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document } from "@generated/client/graphql";
import SearchFilter from "@app/components/Filters/Search";
import LoadingTable from "@app/components/Table/LoadingTable";
import { useFrameworkDocument } from "@app/screens/Frameworks/framework/hooks/framework";
import Table from "./Table";
import { Modal } from "@design-system/Modal";

const DocumentModal = ({ frameworkId }: { frameworkId: string }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation("Framework");
    const [searchString, setSearchString] = useState<string | undefined>();
    const { documents, isLoading, exportDocuments, exportDocumentsLoading } =
        useFrameworkDocument(frameworkId);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(
        [],
    );

    const handleConfirm = () => {
        exportDocuments(selectedDocumentIds);
    };

    const openModal = allowNestedClickWithCallback(() => setOpen(true));

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Trigger>
                <Button onClick={openModal} variant="text">
                    <FileStack className="text-beavrGreen w-4" />
                    {t("export_proofs")}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay scrollable>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{t("export_proofs")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isLoading && <LoadingTable />}
                        {!isLoading && !!documents?.length && (
                            <>
                                <SearchFilter
                                    className=" w-auto mb-5"
                                    searchString={searchString}
                                    setSearchString={setSearchString}
                                />
                                <Table
                                    searchString={searchString}
                                    documents={documents as Document[]}
                                    onSelectedDocumentIdsChange={
                                        setSelectedDocumentIds
                                    }
                                />
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t("cancel")}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            disabled={!selectedDocumentIds.length}
                            loading={exportDocumentsLoading}
                            onClick={handleConfirm}
                        >
                            {t("export_documents", {
                                count: selectedDocumentIds.length,
                            })}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

export default DocumentModal;
