import { Icon } from "@design-system/Icon";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { Slot } from "@radix-ui/react-slot";
import {
    createContext,
    forwardRef,
    useContext,
    type ComponentProps,
    type ComponentPropsWithoutRef,
    type FC,
    type ForwardRefExoticComponent,
    type PropsWithChildren,
    type RefAttributes,
} from "react";

const List = forwardRef<HTMLOListElement, ComponentPropsWithoutRef<"ol">>(
    ({ className, ...props }, ref) => (
        <ol
            ref={ref}
            className={cn(
                "flex flex-wrap items-center gap-1.5 break-words text-sm text-muted-foreground",
                className,
            )}
            {...props}
        />
    ),
);

const Item = forwardRef<HTMLLIElement, ComponentPropsWithoutRef<"li">>(
    ({ className, ...props }, ref) => (
        <li
            ref={ref}
            className={cn(
                "inline-flex items-center gap-1.5 text-primary",
                className,
            )}
            {...props}
        />
    ),
);

type SelectableItemProps = {
    label: string;
    value: string;
};

const SelectableItemCtx = createContext<string | null>(null);

const SelectableItem = forwardRef<
    HTMLButtonElement,
    PropsWithChildren<SelectableItemProps>
>(({ children, label, value }, ref) => {
    return (
        <DropdownPrimitive.Root>
            <DropdownPrimitive.Trigger
                className="group text-primary select-none outline-none"
                ref={ref}
            >
                <Item className="gap-0.5 max-w-[200px]">
                    <Link asChild>
                        <Ellipsis asChild hasTooltip>
                            <Text>{label}</Text>
                        </Ellipsis>
                    </Link>
                    <Icon
                        className={cn(
                            "rounded-full",
                            "group-hover:bg-secondary group-hover:border group-hover:border-tertiary",
                            "group-data-[open=true]:bg-secondary group-data-[open=true]:border group-data-[open=true]:border-tertiary",
                        )}
                        name="angleDown"
                        size="sm"
                    />
                </Item>
            </DropdownPrimitive.Trigger>
            <DropdownPrimitive.Content
                align="start"
                className="min-w-[212px] p-1 rounded-xl bg-white shadow-b-300"
            >
                <SelectableItemCtx.Provider value={value}>
                    {children}
                </SelectableItemCtx.Provider>
            </DropdownPrimitive.Content>
        </DropdownPrimitive.Root>
    );
});

const SelectableItemOption = forwardRef<
    HTMLAnchorElement,
    PropsWithChildren<
        ComponentPropsWithoutRef<"a"> & { asChild?: boolean; value: string }
    >
>(({ children, className, value, ...props }, ref) => {
    const currValue = useContext(SelectableItemCtx);
    if (!currValue) {
        throw new Error(
            "SelectableItemOption must be a child of SelectableItem",
        );
    }
    const isSelected = currValue === value;

    return (
        <DropdownPrimitive.Item
            className={cn(
                "px-2 py-1.5 cursor-pointer select-none outline-none",
                isSelected && "bg-tertiary",
            )}
        >
            <Item className="flex w-full">
                <Link
                    className={cn(
                        "block w-full",
                        isSelected && "!text-beavrGreen",
                        "!hover:no-underline hover:text-beavrGreen",
                    )}
                    ref={ref}
                    {...props}
                >
                    {children}
                </Link>
            </Item>
        </DropdownPrimitive.Item>
    );
});

const Link = forwardRef<
    HTMLAnchorElement,
    ComponentPropsWithoutRef<"a"> & {
        asChild?: boolean;
    }
>(({ asChild, className, ...props }, ref) => {
    const Comp = asChild ? Slot : "a";

    return (
        <Comp
            ref={ref}
            className={cn(
                "transition-colors text-sm font-bold text-primary",
                "hover:no-underline hover:text-beavrGreen",
                className,
            )}
            {...props}
        />
    );
});

const Separator: FC<ComponentProps<"li">> = ({ children, ...props }) => (
    <li role="presentation" aria-hidden="true" {...props}>
        {children ?? (
            <Text variant="body-sm" color="secondary">
                /
            </Text>
        )}
    </li>
);

type BreadcrumbComponentType = ForwardRefExoticComponent<
    ComponentPropsWithoutRef<"nav"> & RefAttributes<HTMLElement>
> & {
    List: typeof List;
    Item: typeof Item;
    Link: typeof Link;
    SelectableItem: typeof SelectableItem;
    SelectableItemOption: typeof SelectableItemOption;
    Separator: typeof Separator;
};

export const Breadcrumb = forwardRef<
    HTMLElement,
    ComponentPropsWithoutRef<"nav">
>(({ ...props }, ref) => (
    <nav ref={ref} aria-label="breadcrumb" {...props} />
)) as BreadcrumbComponentType;

Breadcrumb.List = List;
Breadcrumb.Item = Item;
Breadcrumb.Link = Link;
Breadcrumb.Separator = Separator;
Breadcrumb.SelectableItem = SelectableItem;
Breadcrumb.SelectableItemOption = SelectableItemOption;
