import { tagStatus } from "@design-system/DataDisplay/StatusTagCircular";
import {
    ObjectiveStatus,
    ObjectiveTarget,
    ObjectiveTargetStatus,
} from "@generated/client/graphql";

export const displayStatus = (status: ObjectiveStatus | undefined) => {
    if (status === ObjectiveStatus.Archived) return tagStatus.validated;
    if (status === ObjectiveStatus.Committed) return tagStatus.validated;
    if (status === ObjectiveStatus.Defined) return tagStatus.defined;
    if (status === ObjectiveStatus.Draft) return tagStatus.todo;
    return tagStatus.todo;
};

export const getTargetToDisplay = (targets: ObjectiveTarget[]) => {
    const sortedTargets = targets?.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
    const ongoingTargets = sortedTargets?.filter(
        (target) => target.status === ObjectiveTargetStatus.Ongoing,
    );
    const missedTargets = sortedTargets?.filter(
        (target) => target.status === ObjectiveTargetStatus.Missed,
    );
    const reachedTargets = sortedTargets?.filter(
        (target) => target.status === ObjectiveTargetStatus.Reached,
    );
    const targetToDisplay =
        ongoingTargets?.[0] ||
        reachedTargets?.[reachedTargets.length - 1] ||
        missedTargets?.[missedTargets.length - 1];

    return targetToDisplay;
};
