import { useNavigation } from "@app/lib/navigation";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { ColumnDef } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME } from "./i18n";
import { EnrichedSubtheme } from "./types";

const nameCol: ColumnDef<EnrichedSubtheme> = {
    id: "name",
    accessorKey: "name",
    enableSorting: false,
    meta: {
        cellClassName: "px-4 py-0",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t("subtheme_name");
    },
    cell: ({ row }) => {
        return <Text variant="body-sm">{row.original.subtheme.name}</Text>;
    },
};

const countCol: ColumnDef<EnrichedSubtheme> = {
    id: "objectivesCount",
    meta: {
        cellClassName: "text-right px-4 py-0",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t("objectives_count");
    },
    cell: ({ row }) => {
        return (
            <Text variant="body-sm">
                {row.original.validatedObjectiveCount}
            </Text>
        );
    },
};

const progressionCol: ColumnDef<EnrichedSubtheme> = {
    id: "progression",
    meta: {
        headerClassName: "border-r-0",
        cellClassName: "border-r-0 px-4 py-0",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t("progression");
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const iconName =
            row.original.validatedObjectiveCount > 1
                ? "circleValidated"
                : "circleWarning";
        const iconColor =
            row.original.validatedObjectiveCount > 1
                ? "success"
                : "bg-warning-600";
        const colText = (() => {
            switch (row.original.validatedObjectiveCount) {
                case 0:
                    return t("insufficient_progression");
                case 1:
                    return t("weak_progression");
                case 2:
                    return t("good_progression");
                default:
                    return t("excellent_progression");
            }
        })();

        const tooltipText = (() => {
            switch (row.original.validatedObjectiveCount) {
                case 0:
                    return t("insufficient_tooltip");
                case 1:
                    return t("weak_tooltip");
                case 2:
                    return t("good_tooltip");
                default:
                    return t("excellent_tooltip", {
                        count: row.original.validatedObjectiveCount,
                    });
            }
        })();

        return (
            <FlexRow
                alignItems="center"
                justifyContent="stretch"
                gap="1.5"
                color={iconColor}
            >
                <Icon name={iconName} size="sm" />
                <Text variant="body-sm" color="primary">
                    {colText}
                </Text>
                <Tooltip>
                    <Tooltip.Trigger color="primary">
                        <Icon
                            name="info"
                            size="sm"
                            className="text-secondary"
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content className="max-w-[680px]">
                        {tooltipText}
                    </Tooltip.Content>
                </Tooltip>
            </FlexRow>
        );
    },
};

const navigationCol: ColumnDef<EnrichedSubtheme> = {
    id: "navigation",
    meta: {
        colWidth: "50px",
        headerClassName:
            "min-w-[36px] max-w-[36px] border-l-0 border-r-0 gap-3 px-2",
        cellClassName:
            "min-w-[36px] max-w-[36px] group-hover/tablerow:bg-secondary border-l-0 border-r-0 gap-3 pl-4 pl-0 py-0",
    },
    header: () => {
        return "";
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const { navigateFromHome } = useNavigation();

        const handleCommitmentClick = () => {
            navigateFromHome(
                `objectives?search=${row.original.subtheme.name?.toLowerCase()}`,
            );
        };
        return (
            <Tooltip>
                <Tooltip.Trigger color="primary">
                    <Button
                        onClick={handleCommitmentClick}
                        size="sm"
                        className="hover:shadow-none hover:bg-secondary hover:text-green"
                        variant="text"
                    >
                        <Button.Icon
                            name="arrowTopRight"
                            size="sm"
                            className="text-secondary hover:text-green"
                        />
                    </Button>
                </Tooltip.Trigger>
                <Tooltip.Content className="max-w-[680px]">
                    {t("go_to_objectives")}
                </Tooltip.Content>
            </Tooltip>
        );
    },
};

export const useColumns = (): ColumnDef<EnrichedSubtheme>[] => {
    const columns = [nameCol, countCol, progressionCol, navigationCol];
    return columns;
};
