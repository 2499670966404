import { useEditorContext } from "@app/screens/Document/Edit/hooks/useEditor";
import { useObjectivesWithDetails } from "@app/screens/Objectives/data";
import { useTheme } from "@app/store/themeStore";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Checkbox } from "@design-system/Inputs/Checkbox";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import chroma from "chroma-js";
import { useAtom } from "jotai";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../i18n";
import { isOpenInsertObjectivesModalAtom } from "./ctx";
import { generateObjectiveContent } from "./services";

type InsertObjsModalProps = {
    className?: ClassValue;
};

export const InsertObjectivesModal: FC<InsertObjsModalProps> = ({
    className,
}) => {
    const [open, setOpen] = useAtom(isOpenInsertObjectivesModalAtom);
    const { t } = useTranslation(RESOURCE_NAME);
    const { version, editor } = useEditorContext();
    const currentTheme = useTheme(version?.document?.theme?.id || undefined);
    const { objectives } = useObjectivesWithDetails({
        themeId: version?.document?.theme?.id,
    });

    const filterObjectivesBySubtheme = (subthemeId: string) => {
        return (
            objectives?.filter(
                (objective) => objective.subthemeId === subthemeId,
            ) || []
        );
    };

    const [indexesToInsert, setIndexesToInsert] = useState<boolean[]>(
        currentTheme?.subthemes?.map(() => false) || [],
    );

    useEffect(() => {
        setIndexesToInsert(currentTheme?.subthemes?.map(() => false) || []);
    }, [currentTheme]);

    const updateIndexesToInsert = (idx: number) => {
        setIndexesToInsert(
            indexesToInsert.map((elt, idx2) => (idx === idx2 ? !elt : elt)),
        );
    };

    const handleInsertObjectiveClick = () => {
        return () => {
            if (!editor) return;
            currentTheme?.subthemes?.forEach((subtheme, idx) => {
                if (indexesToInsert[idx]) {
                    const objectivesToInsert = filterObjectivesBySubtheme(
                        subtheme.id,
                    );
                    const objectiveContent = generateObjectiveContent(
                        objectivesToInsert,
                        subtheme,
                        t,
                    );
                    editor.commands.insertContent(objectiveContent || "{}");
                    editor
                        .chain()
                        .insertContentAt(editor.state.doc.content.size, "\n")
                        .focus()
                        .run();
                }
            });
        };
    };

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <Modal.Overlay>
                <Modal.Content className={cn("w-[760px]", className)}>
                    <Modal.Header>
                        <Modal.Title>
                            {t(keys.insert_objectives_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="h-[500px] overflow-scroll">
                        <FlexCol gap="5">
                            {currentTheme?.subthemes?.map((subtheme, idx) => (
                                <FlexCol
                                    alignItems="start"
                                    className="rounded-lg border border-tertiary"
                                >
                                    <FlexRow
                                        alignItems="center"
                                        w="full"
                                        className="border-b border-tertiary bg-secondary"
                                    >
                                        <FlexRow
                                            w="8"
                                            alignItems="start"
                                            alignSelf="stretch"
                                        >
                                            <FlexRow
                                                px="4"
                                                py="1.5"
                                                alignItems="center"
                                                alignSelf="stretch"
                                            >
                                                <Checkbox
                                                    checked={
                                                        indexesToInsert[idx]
                                                    }
                                                    onChange={() =>
                                                        updateIndexesToInsert(
                                                            idx,
                                                        )
                                                    }
                                                    className="cursor-pointer"
                                                ></Checkbox>
                                            </FlexRow>
                                        </FlexRow>
                                        <FlexRow
                                            alignItems="center"
                                            alignSelf="stretch"
                                            h="8"
                                        >
                                            <FlexRow
                                                pl="2"
                                                pr="4"
                                                alignItems="center"
                                                gap="3"
                                            >
                                                <Text variant="body-bold">
                                                    {`${subtheme.name} (${filterObjectivesBySubtheme(subtheme.id)?.length})`}
                                                </Text>
                                            </FlexRow>
                                        </FlexRow>
                                    </FlexRow>
                                    <FlexRow
                                        alignItems="center"
                                        h="12"
                                        p="2"
                                        w="full"
                                        className={cn(
                                            !!filterObjectivesBySubtheme(
                                                subtheme.id,
                                            )?.length &&
                                                "border-b border-tertiary",
                                        )}
                                    >
                                        <SubthemeObjectivesMessage
                                            numObjectives={
                                                objectives?.filter(
                                                    (objective) =>
                                                        objective.subthemeId ===
                                                        subtheme.id,
                                                ).length || 0
                                            }
                                        />
                                    </FlexRow>
                                    <FlexCol alignItems="start" w="full">
                                        {filterObjectivesBySubtheme(
                                            subtheme.id,
                                        )?.map((objective, idx) => (
                                            <FlexRow
                                                py="3"
                                                px="4"
                                                alignItems="center"
                                                gap="2.5"
                                                alignSelf="stretch"
                                                className={cn(
                                                    idx <
                                                        filterObjectivesBySubtheme(
                                                            subtheme.id,
                                                        )?.length -
                                                            1 &&
                                                        "border-b border-tertiary",
                                                )}
                                                w="full"
                                            >
                                                <FlexRow
                                                    w="6"
                                                    h="6"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <StatusTagCircular status="validated" />
                                                </FlexRow>
                                                <Text variant="body">
                                                    {objective.name}
                                                </Text>
                                            </FlexRow>
                                        ))}
                                    </FlexCol>
                                </FlexCol>
                            ))}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer className="justify-center">
                        <Modal.ConfirmCTA
                            className="w-full"
                            onClick={handleInsertObjectiveClick()}
                        >
                            {t(keys.cta, {
                                selected:
                                    indexesToInsert.filter(Boolean).length,
                                total: currentTheme?.subthemes?.length,
                            })}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};

const SubthemeObjectivesMessage = ({
    numObjectives,
}: {
    numObjectives: number;
}) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const alertText = (() => {
        switch (numObjectives) {
            case 0:
                return t("objectives_alert_none");
            case 1:
                return t("objectives_alert_weak");
            case 2:
                return t("objectives_alert_strong");
            default:
                return t("objectives_alert_excellent", {
                    count: numObjectives,
                });
        }
    })();

    const isWarning = numObjectives < 2;
    return (
        <FlexRow
            w="full"
            className={cn(
                "rounded-lg border",
                isWarning ? "border-warning-600" : "border-brand",
            )}
            style={{
                backgroundColor: isWarning
                    ? chroma("#DBA600").alpha(0.1).hex()
                    : chroma("#EEFFF2").hex(),
            }}
        >
            <FlexCol px="3" py="1.5" justifyContent="center" alignItems="start">
                <Text variant="body">{alertText}</Text>
            </FlexCol>
        </FlexRow>
    );
};
