import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { Modal } from "@design-system/Modal";
import { Text } from "@design-system/Typography/Text";
import { QuestionUpdateInput } from "@generated/client/graphql";
import { type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteQuestion, useQuestionUpdate } from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";
import { useQuestion } from "../../question/data";
import { EditQuestionModal } from "../EditQuestionModal";

export const EditOrDeleteDropdown: FC<PropsWithChildren<{ id?: string }>> = ({
    id,
    children,
}) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { question } = useQuestion(id);
    const deleteQuestionMutation = useDeleteQuestion(id);
    const updateQuestionMutation = useQuestionUpdate();
    const deleteQuestion = () => {
        if (!id) return;
        deleteQuestionMutation.deleteQuestion(id);
    };
    const updateQuestion = (input: QuestionUpdateInput) => {
        if (!id) return;
        updateQuestionMutation.updateQuestion({
            id,
            input,
        });
    };

    return (
        <Box display="grid" className="place-items-center">
            <Dropdown>
                <Dropdown.Trigger asChild>{children}</Dropdown.Trigger>
                <Dropdown.Content hasPortal>
                    {question && (
                        <EditQuestionModal
                            key={updateQuestionMutation.status}
                            loading={updateQuestionMutation.isPending}
                            question={question}
                            onConfirm={updateQuestion}
                        >
                            <Dropdown.Item>
                                <Dropdown.ItemIcon name="edit" />
                                <Dropdown.ItemText>
                                    {t(keys.table_actions_dd_edit)}
                                </Dropdown.ItemText>
                            </Dropdown.Item>
                        </EditQuestionModal>
                    )}
                    <Modal>
                        <Modal.Trigger asChild>
                            <Dropdown.Item variant="negative">
                                <Dropdown.ItemIcon name="trash" />
                                <Dropdown.ItemText>
                                    {t(keys.table_actions_dd_delete)}
                                </Dropdown.ItemText>
                            </Dropdown.Item>
                        </Modal.Trigger>
                        <Modal.Overlay centerContent>
                            <Modal.Content>
                                <Modal.Header>
                                    <Modal.Title>
                                        {t(keys.delete_modal_title)}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Text>
                                        {t(keys.delete_modal_description)}
                                    </Text>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Modal.CancelCTA>
                                        {t(keys.cancel)}
                                    </Modal.CancelCTA>
                                    <Modal.ConfirmCTA
                                        onClick={deleteQuestion}
                                        variant="flatDanger"
                                    >
                                        {t(keys.table_actions_dd_delete)}
                                    </Modal.ConfirmCTA>
                                </Modal.Footer>
                            </Modal.Content>
                        </Modal.Overlay>
                    </Modal>
                </Dropdown.Content>
            </Dropdown>
        </Box>
    );
};
