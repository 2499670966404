import CheckboxCell from "@app/components/Table/CheckboxCell";
import CheckboxHeader from "@app/components/Table/Header/Checkbox";
import { useSetToast } from "@app/components/Toast";
import { useNavigation } from "@app/lib/navigation";
import SubthemeMultiSelect from "@app/shared/components/Theme/SubthemeMultiSelect";
import { SubthemeTag } from "@app/shared/components/Theme/SubthemeTag";
import { ThemeMultiSelect } from "@app/shared/components/Theme/ThemeMultiSelect";
import { ThemeTag } from "@app/shared/components/Theme/ThemeTag";
import UserChipSelect from "@app/shared/components/UserChipSelect";
import UserMultiSelect from "@app/shared/components/UserMultiSelect";
import { UserTag } from "@app/shared/components/UserTag";
import { useHandleMutationToasts } from "@app/shared/utils/displayMutationToasts";
import { compareUser } from "@app/shared/utils/tableSortingFns";
import {
    displayStatus,
    getTargetToDisplay,
} from "@app/usecases/ObjectivesUseCases";
import { normalizeString } from "@app/utils/string";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { ColumnDef, compareBasic } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUpdateObjective } from "../../data";
import {
    selectedSubthemesAtomFamily,
    selectedThemesAtomFamily,
    selectedUsersAtomFamily,
} from "./ctx";
import { RESOURCE_NAME, keys } from "./i18n";
import { Objective } from "./types";

const selectCol: ColumnDef<Objective> = {
    id: "select",
    header: ({ table }) => {
        return <CheckboxHeader table={table} />;
    },
    cell: ({ row }) => <CheckboxCell row={row} />,
    meta: {
        colWidth: "50px",
        headerClassName: "min-w-[50px] max-w-[50px] border-r-0 gap-3",
        cellClassName:
            "min-w-[50px] max-w-[50px] group-hover/tablerow:bg-secondary border-r-0 gap-3",
    },
};

const nameCol: ColumnDef<Objective> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "400px",
        headerClassName: "max-w-[400px]",
        cellClassName: "max-w-[400px]",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return (
            <Text variant="body-sm-bold" color="primary">
                {t(keys.header_name)}
            </Text>
        );
    },
    cell: ({ row }) => {
        const { navigateFromHome } = useNavigation();
        const handleNameClick = () => {
            navigateFromHome(`objectives/${row.original.id}`);
        };

        const { t } = useTranslation(RESOURCE_NAME);

        const status = row.original.status;

        return (
            <FlexRow
                alignItems="center"
                w="full"
                gap="3"
                className="cursor-pointer hover:underline"
                onClick={handleNameClick}
            >
                <StatusTagCircular status={displayStatus(status)} />

                <Ellipsis asChild hasTooltip lineClamp={2}>
                    <Text
                        variant="body"
                        color={row.original.name ? "primary" : "secondary"}
                    >
                        {row.original.name || t(keys.default_title_placeholder)}
                    </Text>
                </Ellipsis>
            </FlexRow>
        );
    },
};

const themeCol: ColumnDef<Objective> = {
    id: "theme",
    accessorKey: "themeId",
    filterFn: "arrIncludesSome",
    sortingFn: (rowA, rowB) => {
        const themeA = rowA.original.theme?.name ?? "";
        const themeB = rowB.original.theme?.name ?? "";

        return compareBasic(normalizeString(themeA), normalizeString(themeB));
    },
    meta: {
        colWidth: "200px",
        headerClassName: "max-w-[200px]",
        cellClassName: "max-w-[200px]",
    },
    header: () => {
        const { org_uname } = useParams();
        const [selectedThemesIds, setSelectedThemesIds] = useAtom(
            selectedThemesAtomFamily(org_uname),
        );
        const [searchFilter, setSearchFilter] = useState<string>();

        return (
            <ThemeMultiSelect
                hasPortal
                isHeader
                isModal={true}
                values={selectedThemesIds}
                onValuesChange={setSelectedThemesIds}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
            />
        );
    },
    cell: ({ row }) => {
        const themeId = row.original.themeId;

        return (
            <FlexRow w="fit">
                <ThemeTag
                    themeId={themeId}
                    className="bg-secondary"
                    isTrigger
                    disabled
                />
            </FlexRow>
        );
    },
};

const subthemeCol: ColumnDef<Objective> = {
    id: "subtheme",
    accessorKey: "subthemeId",
    filterFn: "arrIncludesSome",
    sortingFn: (rowA, rowB) => {
        const subthemeA = rowA.original.subtheme?.name ?? "";
        const subthemeB = rowB.original.subtheme?.name ?? "";

        return compareBasic(
            normalizeString(subthemeA),
            normalizeString(subthemeB),
        );
    },
    meta: {
        colWidth: "200px",
        headerClassName: "max-w-[200px]",
        cellClassName: "max-w-[200px]",
    },
    header: () => {
        const { org_uname } = useParams();
        const [selectedSubthemesIds, setSelectedSubthemesIds] = useAtom(
            selectedSubthemesAtomFamily(org_uname),
        );
        const [searchFilter, setSearchFilter] = useState<string>();

        return (
            <SubthemeMultiSelect
                hasPortal
                isHeader
                isModal={false}
                values={selectedSubthemesIds}
                onValuesChange={setSelectedSubthemesIds}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
            />
        );
    },
    cell: ({ row }) => {
        const themeId = row.original.themeId;
        const subThemeId = row.original.subthemeId;

        return (
            <FlexRow className="max-w-fit">
                <SubthemeTag
                    themeId={themeId}
                    subthemeId={subThemeId ?? undefined}
                    className="bg-secondary"
                    isTrigger
                    disabled
                />
            </FlexRow>
        );
    },
};

const ownerCol: ColumnDef<Objective> = {
    id: "owner",
    accessorKey: "owner.id",
    meta: {
        colWidth: "200px",
        headerClassName: "max-w-[200px]",
        cellClassName: "max-w-[200px]",
    },
    filterFn: (row, _, filterValue) => {
        return (
            filterValue.filter((elt: string) => {
                if (elt === null) {
                    return !row.original.owner;
                } else {
                    return row.original.owner?.id === elt;
                }
            }).length > 0
        );
    },
    sortingFn: (rowA, rowB) => {
        const ownerA = rowA.original.owner;
        const ownerB = rowB.original.owner;

        return compareUser(ownerA, ownerB);
    },

    header: () => {
        const { org_uname } = useParams();
        const [selectedUserIds, setSelectedUserIds] = useAtom(
            selectedUsersAtomFamily(org_uname),
        );
        const [searchFilter, setSearchFilter] = useState<string>();
        return (
            <UserMultiSelect
                isHeader
                values={selectedUserIds}
                onValuesChange={setSelectedUserIds}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
                includeNone={true}
            />
        );
    },
    cell: ({ row }) => {
        const { t } = useTranslation(RESOURCE_NAME);
        const [ownerId, setOwnerId] = useState<string | undefined>(
            row.original.owner?.id,
        );
        const [searchFilter, setSearchFilter] = useState<string>();
        const { updateOwner } = useUpdateOwner(row.original.id);

        const handleUserIdChange = (userId: string) => {
            setOwnerId(userId);
            updateOwner(ownerId, userId);
        };

        return (
            <UserChipSelect
                userId={ownerId}
                setUserId={handleUserIdChange}
                searchFilter={searchFilter}
                onSearchFilterChange={setSearchFilter}
                trigger={
                    <FlexRow w="fit">
                        <UserTag
                            userId={ownerId || undefined}
                            noUserText={t(keys.no_owner_placeholder)}
                        />
                    </FlexRow>
                }
            />
        );
    },
};

const targetCol: ColumnDef<Objective> = {
    id: "target",
    meta: {
        colWidth: "125px",
        headerClassName: "max-w-[125px]",
        cellClassName: "max-w-[125px]",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return (
            <FlexRow alignItems="center">
                <Text variant="body-sm-bold">{t(keys.header_target)}</Text>
            </FlexRow>
        );
    },
    cell: ({ row }) => {
        const targetToDisplay = getTargetToDisplay(row.original.targets);

        const textToDisplay =
            targetToDisplay?.value?.number !== undefined
                ? `${targetToDisplay.value.number} ${row.original.indicator?.unit?.shortName || ""}`
                : "-";

        return (
            <FlexRow gap="2" alignItems="center">
                <Text
                    variant="body"
                    color={targetToDisplay?.value ? "primary" : "secondary"}
                >
                    {textToDisplay}
                </Text>
                {targetToDisplay?.value && (
                    <Icon name="flag" size="sm" className="text-secondary" />
                )}
            </FlexRow>
        );
    },
};

const dateCol: ColumnDef<Objective> = {
    id: "date",
    accessorFn: (row) => {
        const targetToDisplay = getTargetToDisplay(row.targets);
        return targetToDisplay?.date;
    },
    sortDescFirst: false,
    meta: {
        colWidth: "125px",
        headerClassName: "max-w-[125px]",
        cellClassName: "max-w-[125px]",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return (
            <FlexRow alignItems="center">
                <Text variant="body-sm-bold">{t(keys.header_date)}</Text>
            </FlexRow>
        );
    },
    cell: ({ row }) => {
        const targetToDisplay = getTargetToDisplay(row.original.targets);

        dayjs.extend(LocalizedFormat);

        const displayDate = targetToDisplay?.date
            ? dayjs(targetToDisplay.date).format("DD MMM YYYY")
            : "-";

        return (
            <FlexRow gap="2" alignItems="center">
                <Ellipsis asChild hasTooltip>
                    <Text>{displayDate}</Text>
                </Ellipsis>
                {targetToDisplay?.date && (
                    <Icon
                        name="calendar"
                        size="sm"
                        className="text-secondary"
                    />
                )}
            </FlexRow>
        );
    },
};

const useUpdateOwner = (objectiveId: string) => {
    const { mutate, isPending } = useUpdateObjective(objectiveId);
    const { t } = useTranslation(RESOURCE_NAME);
    const { setToastError } = useSetToast();
    const handleMutationToasts = useHandleMutationToasts();

    const updateOwner = (currentOwnerId?: string, newUserId?: string) => {
        const newOwnerId = !!newUserId ? newUserId : null;
        if (currentOwnerId === newOwnerId) return;

        mutate(
            { id: objectiveId, ownerId: newOwnerId },
            {
                onSettled: (data) => {
                    handleMutationToasts(data?.updateObjective, {
                        successMessage: t(keys.update_success),
                        defaultErrorMessage: t(keys.update_error),
                    });
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
    };

    return { updateOwner, isPending };
};

export const useColumns = (): ColumnDef<Objective>[] => {
    const columns = [
        selectCol,
        nameCol,
        themeCol,
        subthemeCol,
        ownerCol,
        dateCol,
        targetCol,
    ];
    return columns;
};
