import { useSetToast } from "@app/components/Toast";

import { GraphQLUserRepository } from "@app/repositories/GraphQLRepositories/UserRepository";
import { UserPatch } from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const userQueryKeys = {
    all: () => ["users"],
} as const;

const userRepository = new GraphQLUserRepository();

export const useUsers = () => {
    return useQuery({
        queryKey: userQueryKeys.all(),
        queryFn: userRepository.getDetails,
    });
};

export const useUpdateUser = (userId: string | undefined) => {
    const { t } = useTranslation("Users");
    const { setToastSuccess, setToastError } = useSetToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (patch: UserPatch) => {
            const response =
                userId && (await userRepository.updateUser(userId, patch));
            return response;
        },
        onSuccess: () => {
            setToastSuccess(t("update.success"));
            queryClient.invalidateQueries({ queryKey: userQueryKeys.all() });
        },
        onError: () => {
            setToastError(t("update.error"));
        },
    });
};
export const useCreateUsers = () => {
    const { t } = useTranslation("Users");
    const { setToastSuccess, setToastError } = useSetToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: userRepository.createUsers,
        onSuccess: () => {
            setToastSuccess(t("create.success"));
            queryClient.invalidateQueries({ queryKey: userQueryKeys.all() });
        },
        onError: () => {
            setToastError(t("create.error"));
        },
    });
};

export const useDeleteUser = () => {
    const { t } = useTranslation("Users");
    const { setToastSuccess, setToastError } = useSetToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: userRepository.deleteUser,
        onSuccess: () => {
            setToastSuccess(t("delete.success"));
            queryClient.invalidateQueries({ queryKey: userQueryKeys.all() });
        },
        onError: () => {
            setToastError(t("delete.error"));
        },
    });
};
