import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { keys, RESOURCE_NAME } from "../../i18n";
import { QuestionType } from "@generated/client/graphql";

export const AnswerTypeSelect: FC<{
    defaultValue?: QuestionType[];
    value?: QuestionType[];
    onChange: (value: string[]) => void;
}> = ({ defaultValue, value, onChange }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <Dropdown
            closeOnValueChange={false}
            modal
            defaultValue={defaultValue}
            value={value}
            onValueChange={onChange}
            selectType="single"
        >
            <Dropdown.Trigger
                label={t(keys.create_questions_modal_question_type_dd_label)}
            />
            <Dropdown.Content hasPortal>
                {Object.values(QuestionType).map((type) => (
                    <Dropdown.SelectableItem key={type} value={type}>
                        <Dropdown.SelectedIndicator />
                        <Dropdown.ItemText>
                            {t(
                                keys.create_questions_modal_question_type_dd_item_label_narrative,
                            )}
                        </Dropdown.ItemText>
                    </Dropdown.SelectableItem>
                ))}
            </Dropdown.Content>
        </Dropdown>
    );
};
