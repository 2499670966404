import Dashboard from "@app/pages/Dashboard";
import {
    Link,
    Navigate,
    Outlet,
    RouteObject,
    useParams,
} from "react-router-dom";
import Profile from "../screens/Profile/index.tsx";

import { ReactComponent as BarrierIllustration } from "@app/assets/illustrations/barrier.svg";
import { ReactComponent as LockIllustration } from "@app/assets/illustrations/lock.svg";
import ErrorPage from "@app/shared/components/ErrorPage";
import "@app/shared/components/ErrorPage/i18n.tsx";

import ReportingV2 from "@app/pages/Reporting/index.tsx";
import ReportingProgress from "@app/pages/ReportingProgress/index.tsx";
import Requirements from "@app/pages/Requirements/index.tsx";
import AllFiles from "@app/screens/AllFiles/index.tsx";
import { ExternalQuestionnaires } from "@app/screens/ExternalQuestionnaires";
import { ExternalQuestionnaire } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire";
import { Question } from "@app/screens/ExternalQuestionnaires/externalQuestionnaire/question/index.tsx";
import { Frameworks } from "@app/screens/Frameworks";
import { CsrdGeneral } from "@app/screens/Frameworks/framework/Csrd/index.tsx";
import { Pillar } from "@app/screens/Frameworks/framework/Csrd/pillar/index.tsx";
import Framework from "@app/screens/Frameworks/framework/index.tsx";
import TopBar from "@app/screens/Global/TopBar/index.tsx";
import Objectives from "@app/screens/Objectives/index.tsx";
import { ObjectivePage } from "@app/screens/Objectives/objective/index.tsx";
import { useInitPlanhat } from "@app/shared/planhat/index.ts";
import {
    useSyncCurrOrgUname,
    useSyncDocumentURL,
} from "@app/shared/utils/storeRouterSync.ts";
import { currOrgUnameAtom } from "@app/store/orgStore.ts";
import { currentUserAtom } from "@app/store/userStore.ts";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DocumentType,
    OrgType,
    UserRole,
} from "../../generated/client/graphql.ts";
import Background from "../components/Background/index.tsx";
import Loading from "../components/Loading/index.tsx";
import Navbar from "../components/Navbar/index.tsx";
import EditDocument from "../screens/Document/Edit/index.tsx";
import Document from "../screens/Document/index.tsx";
import Documents2 from "../screens/Documents2/index.tsx";
import General from "../screens/Settings/General/index.tsx";
import Load from "../screens/Settings/Load/index.tsx";
import Sites from "../screens/Settings/Sites/index.tsx";
import Units from "../screens/Settings/Units/index.tsx";
import SupplierDatapoints from "../screens/Supplier/Datapoints/index.tsx";
import Questionnaires from "../screens/Supplier/Questionnaires/index.tsx";
import Supplier from "../screens/Supplier/Supplier/index.tsx";
import Suppliers from "../screens/Supplier/Suppliers/index.tsx";
import SustainabilityCenterDashboard from "../screens/SustainabilityCenter/Dashboard/index.tsx";
import Themes from "../screens/Themes/index.js";
import Users from "../screens/Users/index.tsx";
import { useOrg } from "./data.ts";
import {
    adminPaths,
    documentPaths,
    reportingPaths,
    settingPaths,
    supplierPaths,
} from "./paths.tsx";
import { isEditMode, useGoToHomepage } from "./utils.ts";

const useRefetchUserOnReload = () => {
    const { refetch } = useAtomValue(currentUserAtom);
    useEffect(() => {
        refetch();
    }, []);
};

export const AdminLayout = () => {
    const { t } = useTranslation("ErrorPage");
    useSyncDocumentURL();
    useSyncCurrOrgUname();
    useRefetchUserOnReload();
    const orgUname = useAtomValue(currOrgUnameAtom);
    const { data: org, isLoading: orgIsLoading } = useOrg(orgUname);

    const { data, error, isLoading, isRefetching } =
        useAtomValue(currentUserAtom);
    const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

    const params = useParams();
    const goToHomepage = useGoToHomepage();

    const copyErrorToClipboard = () => {
        const stringifiedError = error ? JSON.stringify(error, null, 2) : "";
        navigator.clipboard.writeText(stringifiedError);
    };

    useInitPlanhat();

    useEffect(() => {
        try {
            const errorCode =
                // @ts-ignore
                error["response"]["errors"][0]["extensions"]["code"];
            setErrorCode(errorCode);
        } catch (e) {}
    }, [error]);

    if (org?.type === OrgType.CustomerChurned) {
        const handleCTA = () => {
            window.location.href = "mailto:contact@beavr.tech";
        };
        return (
            <ErrorPage>
                <ErrorPage.Header />
                <ErrorPage.Body>
                    <ErrorPage.Image Image={LockIllustration} />
                    <ErrorPage.Text
                        text={t("customer_churned.status_message")}
                        type="errorMessage"
                    />
                    <ErrorPage.Text
                        text={t("customer_churned.description")}
                        type="errorDescription"
                    />
                    <ErrorPage.CTA onClick={handleCTA}>
                        {t("customer_churned.cta")}
                    </ErrorPage.CTA>
                </ErrorPage.Body>
            </ErrorPage>
        );
    }

    if (isLoading || isRefetching || orgIsLoading) {
        return <Loading size="large" />;
    }
    if (
        data?.currentUser.permission?.role &&
        [UserRole.Admin, UserRole.BeavrAdmin, UserRole.Contributor].includes(
            data.currentUser.permission?.role,
        )
    ) {
        return (
            <div className="min-h-screen antialiased">
                {isEditMode() ? (
                    <Outlet />
                ) : (
                    <div className="flex">
                        <Navbar />
                        <div className="min-h-screen w-full min-w-[960px]">
                            <Background
                                topBar={<TopBar />}
                                hasBgBars={!params.document_id}
                            >
                                <Outlet />
                            </Background>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    if (
        errorCode === "INTERNAL_SERVER_ERROR" ||
        errorCode === "UNAUTHENTICATED"
    ) {
        return (
            <ErrorPage>
                <ErrorPage.Header />
                <ErrorPage.Body>
                    <ErrorPage.Image Image={LockIllustration} />
                    <ErrorPage.Text
                        text={t("access_denied.status_code")}
                        type="errorCode"
                    />
                    <ErrorPage.Text
                        text={t("access_denied.status_message")}
                        type="errorMessage"
                    />
                    <ErrorPage.Text
                        text={t("access_denied.description")}
                        type="errorDescription"
                    />
                    <ErrorPage.CTA onClick={goToHomepage}>
                        {t("go_to_homepage")}
                    </ErrorPage.CTA>
                </ErrorPage.Body>
            </ErrorPage>
        );
    }

    if (error) {
        return (
            <ErrorPage>
                <ErrorPage.Header />
                <ErrorPage.Body>
                    <ErrorPage.Image Image={BarrierIllustration} />
                    <ErrorPage.Text
                        text={t("maintenance.status_message")}
                        type="errorMessage"
                    />
                    <ErrorPage.Text
                        text={t("maintenance.description")}
                        type="errorDescription"
                    />
                    <ErrorPage.CTA onClick={copyErrorToClipboard}>
                        {t("copy_error_message")}
                    </ErrorPage.CTA>
                </ErrorPage.Body>
            </ErrorPage>
        );
    }

    return <></>;
};

const settingRoutes: RouteObject[] = [
    {
        path: settingPaths.general,
        element: <General />,
    },
    {
        path: settingPaths.themes,
        element: <Themes />,
    },
    {
        path: settingPaths.sites,
        element: <Sites />,
    },
    {
        path: settingPaths.users,
        element: <Users />,
    },
    {
        path: settingPaths.units,
        element: <Units />,
    },
    {
        path: "load",
        element: <Load />,
    },
];

const reportingRoutes: RouteObject[] = [
    {
        index: true,
        path: "*",
        element: <Navigate to={"./" + reportingPaths.overview} />,
    },
    {
        index: true,
        path: reportingPaths.overview,
        element: <ReportingV2 />,
    },
    {
        index: true,
        path: reportingPaths.progress,
        element: <ReportingProgress />,
    },
];

const supplierRoutes: RouteObject[] = [
    {
        path: supplierPaths.questionnaires,
        element: <Questionnaires />,
    },
    {
        index: true,
        path: supplierPaths.suppliers,
        element: <Suppliers />,
    },
    {
        path: supplierPaths.datapoints,
        element: <SupplierDatapoints />,
    },
    {
        path: supplierPaths.supplier_id_param,
        element: <Supplier />,
    },
];

const documentRoute: RouteObject = {
    path: documentPaths.document_id_param,
    element: <Outlet />,
    children: [
        {
            index: true,
            element: <Document />,
            handle: {
                crumb: Link,
                page: documentPaths.document_id_param,
            },
        },
        {
            path: "edit",

            element: <EditDocument />,
        },
    ],
};

export const adminRoutes: RouteObject[] = [
    {
        index: true,
        element: <Dashboard />,
    },
    {
        path: adminPaths.policies,
        handle: {
            crumb: Link,
            page: adminPaths.policies,
        },
        children: [
            {
                index: true,
                element: <Documents2 type={DocumentType.Policy} />,
            },
            documentRoute,
        ],
    },
    {
        path: adminPaths.objectives,
        handle: {
            crumb: Link,
            page: adminPaths.objectives,
        },
        children: [
            { index: true, element: <Objectives /> },
            {
                path: adminPaths.objective_id_param,
                element: <ObjectivePage />,
                handle: {
                    crumb: Link,
                    page: adminPaths.objective_id_param,
                },
            },
        ],
    },
    {
        path: adminPaths.measures,
        handle: {
            crumb: Link,
            page: adminPaths.measures,
        },
        children: [
            {
                index: true,
                element: <Documents2 type={DocumentType.Procedure} />,
            },
            documentRoute,
        ],
    },
    {
        path: adminPaths.custom_documents,
        handle: {
            crumb: Link,
            page: adminPaths.custom_documents,
        },
        children: [
            {
                index: true,
                element: <Documents2 type={DocumentType.CustomDocument} />,
            },
            documentRoute,
        ],
    },
    {
        path: adminPaths.all_files,
        children: [
            {
                index: true,
                element: <AllFiles />,
            },
        ],
    },
    {
        path: adminPaths.settings,
        children: settingRoutes,
    },
    {
        path: adminPaths.profile,
        element: <Profile />,
    },
    {
        path: adminPaths.reporting,
        children: reportingRoutes,
    },
    {
        path: adminPaths.supplier,
        children: supplierRoutes,
    },
    {
        path: adminPaths.requirements,
        element: <Requirements />,
    },
    {
        path: adminPaths.frameworks,
        handle: {
            crumb: Link,
            page: adminPaths.frameworks,
        },
        children: [
            { index: true, element: <Frameworks /> },
            {
                path: adminPaths.framework_csrd,
                handle: {
                    crumb: Link,
                    page: adminPaths.framework_csrd,
                },
                children: [
                    {
                        path: adminPaths.framework_csrd_pillar_id_param,
                        element: <Pillar />,
                        handle: {
                            crumb: Link,
                            page: adminPaths.framework_csrd_pillar_id_param,
                        },
                    },
                    {
                        index: true,
                        path: "*",
                        element: <CsrdGeneral />,
                    },
                ],
            },
            {
                path: adminPaths.framework_id_param,
                element: <Framework />,
                handle: {
                    crumb: Link,
                    page: adminPaths.framework_id_param,
                },
            },
        ],
    },
    {
        path: adminPaths.sustainability_center,
        element: <SustainabilityCenterDashboard />,
    },
    {
        path: adminPaths.external_questionnaires,
        handle: {
            crumb: Link,
            page: adminPaths.external_questionnaires,
        },
        children: [
            {
                index: true,
                element: <ExternalQuestionnaires />,
            },
            {
                path: adminPaths.external_questionnaire_id_param,
                children: [
                    {
                        index: true,
                        element: <ExternalQuestionnaire />,
                    },
                    {
                        path: adminPaths.external_questionnaire_question_id_param,
                        element: <Question />,
                        handle: {
                            crumb: Link,
                            page: adminPaths.external_questionnaire_question_id_param,
                        },
                    },
                ],
                handle: {
                    crumb: Link,
                    page: adminPaths.external_questionnaire_id_param,
                },
            },
        ],
    },
    {
        path: "*",
        element: <Navigate to="." />,
    },
];
