import { Button } from "@design-system/Inputs/Button";
import { Calendar } from "@design-system/Inputs/Calendar";
import { Textfield } from "@design-system/Inputs/Textfield";
import { cn } from "@design-system/Utilities";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";

import InputSelectTrigger from "@app/components/inputs_v2/SelectTrigger";

import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import Select from "@design-system/Inputs/Select";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";

const StartFromList = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [email, setEmail] = useState<string>();
    const [item, setItem] = useState<string>();
    const [deadline, setDeadline] = useState<Date>();

    const makeEventHandler =
        (setter: Dispatch<SetStateAction<string | undefined>>) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setter(e.target.value);
        };
    const list = [
        "Chanel (Small)",
        "Chanel (Large)",
        "L'Oréal",
        "Schneider Electric",
    ];
    return (
        <FlexCol className="gap-2">
            <Select
                value={item}
                onValueChange={(value) => {
                    setItem(value);
                }}
            >
                <InputSelectTrigger
                    stringValue={
                        item || "Choose from our partner questionnaires"
                    }
                />
                <Select.Content>
                    {list.map((item) => (
                        <Select.Item
                            value={item}
                            disabled
                            className="disabled:opacity-50"
                        >
                            {item}
                        </Select.Item>
                    ))}
                </Select.Content>

                <Textfield
                    placeholder={t(
                        keys.create_questionnaire_modal_requester_email_placeholder,
                    )}
                    value={email}
                    onChange={makeEventHandler(setEmail)}
                />

                <Popover>
                    <PopoverTrigger
                        className="w-full flex items-stretch justify-stretch"
                        asChild
                    >
                        <Button
                            variant={"outlined"}
                            className={cn(
                                "w-full",
                                "px-4",
                                "font-normal text-primary",
                                // !deadline &&
                                //     "text-muted-foreground",
                            )}
                        >
                            {deadline ? (
                                format(deadline, "PPP")
                            ) : (
                                <span className="text-secondary">
                                    Set a deadline
                                </span>
                            )}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent
                        align="start"
                        alignOffset={-2}
                        sideOffset={9}
                        className="absolute transform -translate-x-3 shadow-lg bg-white"
                    >
                        <Calendar
                            mode="single"
                            defaultMonth={
                                deadline ? new Date(deadline) : new Date()
                            }
                            selected={deadline}
                            onSelect={setDeadline}
                            className="rounded-md border"
                        />
                    </PopoverContent>
                </Popover>
            </Select>
            <Tooltip>
                <Tooltip.Trigger asChild>
                    <div className="w-fit self-end" tabIndex={0}>
                        <Modal.ConfirmCTA
                            disabled={true}
                            className="w-fit self-end pointer-events-none"
                        >
                            {t(keys.create_questionnaire_modal_confirm_label)}
                        </Modal.ConfirmCTA>
                    </div>
                </Tooltip.Trigger>

                <Tooltip.Content>
                    {t(keys.create_questionnaire_modal_tooltip_no_name)}
                </Tooltip.Content>
            </Tooltip>
        </FlexCol>
    );
};

export default StartFromList;
