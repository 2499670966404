import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";

export const ObjectiveSideBarSkeleton = () => {
    return (
        <FlexCol
            justifyContent="start"
            alignSelf="stretch"
            alignItems="start"
            className="w-[320px]"
            px="4"
        >
            <FlexRow
                py="4"
                justifyContent="between"
                alignItems="center"
                w="full"
            >
                <FlexRow gap="1" alignItems="center" className="h-[30px]">
                    <Skeleton h="2" w="4" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
                <Skeleton h="4" w="16" br="lg" />
            </FlexRow>
            <FlexCol gap="2" alignItems="start">
                <FlexRow alignItems="center" className="h-[20px]">
                    <Skeleton h="3" w="12" br="lg" />
                </FlexRow>
                <FlexRow
                    p="1"
                    alignItems="center"
                    justifyContent="center"
                    elevation="b-100"
                    className="h-[30px] rounded-xl border border-secondary"
                >
                    <Skeleton h="2" w="20" br="lg" />
                </FlexRow>
            </FlexCol>
        </FlexCol>
    );
};
