import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "../i18n";

export const MessageLink = ({ href }: { href: string }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    if (href.startsWith("mailto:")) {
        const email = href.split("mailto:")[1];
        return <a href={href}>{email}</a>;
    }

    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <a href={href} target="_blank">
                    {href}
                </a>
            </Tooltip.Trigger>
            <Tooltip.Content>{t(keys.link_tooltip)}</Tooltip.Content>
        </Tooltip>
    );
};
