import Loading from "@app/components/Loading";
import { useEntities } from "@app/store/entityStore";

import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import Select from "@design-system/Inputs/Select";
import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

const EntitySelect: FC<
    PropsWithChildren<{
        entityId: string | undefined;
        setEntityId: (entityId: string) => void;
        className?: string;
    }>
> = ({ entityId, setEntityId, className }) => {
    const { entities, isLoading } = useEntities();
    const { t } = useTranslation("EntitySelect");

    return isLoading ? (
        <Loading size="small" />
    ) : (
        <Select
            value={entityId}
            onValueChange={(entityId: string) => {
                const currentEntity = entities?.find(
                    (elt) => elt.id === entityId,
                );

                currentEntity && setEntityId(currentEntity?.id);
            }}
        >
            <Select.Trigger asChild className={className}>
                <Button
                    variant="tonal"
                    className={cn(
                        "w-[140px] justify-between overflow-hidden",
                        className,
                    )}
                    size="sm"
                >
                    <FlexRow alignItems="center" justifyContent="start" gap="2">
                        <Button.Icon name="building" />
                        <Ellipsis asChild hasTooltip>
                            <Text
                                className="leading-none"
                                variant="body-sm-bold"
                                truncate
                            >
                                {entities?.find((elt) => elt.id === entityId)
                                    ?.name || t("placeholder")}
                            </Text>
                        </Ellipsis>
                    </FlexRow>

                    <Icon name="angleDown" size="sm" />
                </Button>
            </Select.Trigger>
            <Select.Content className="max-h-[260px] overflow-auto">
                {entities?.map((entity) => (
                    <Select.Item key={entity.id} value={entity.id}>
                        <div className="group-hover:text-brand_hover text-sm">
                            {entity.name}
                        </div>
                    </Select.Item>
                ))}
            </Select.Content>
        </Select>
    );
};

export default EntitySelect;
