import { useSetToast } from "@app/components/Toast";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { GetObjectiveQuery } from "@generated/client/graphql";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateObjectiveTarget } from "../../data";
import { Target } from "../Target";
import { AddTargetForm } from "./AddTargetForm";
import { RESOURCE_NAME, keys } from "./i18n";

type ObjectiveTargetsProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveTargets: FC<ObjectiveTargetsProps> = ({ objective }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const totalTargets = objective.targets.length;
    const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);

    const handleAddButtonClick = () => setIsAddMenuOpen(true);
    const handleCancelButtonClick = (
        setNewDate: (date: Date | undefined) => void,
        setNewValue: (value: number | undefined) => void,
    ) => {
        setIsAddMenuOpen(false);
        setNewValue(undefined);
        setNewDate(undefined);
    };
    const { setToastSuccess, setToastError } = useSetToast();
    const handleCreateButtonClick = (
        newDate: Date,
        newValue: number,
        setNewDate: (date: Date | undefined) => void,
        setNewValue: (value: number | undefined) => void,
    ) => {
        setIsAddMenuOpen(false);
        setNewValue(undefined);
        setNewDate(undefined);
        createObjectiveTarget(
            {
                objectiveId: objective.id,
                value: { number: newValue },
                date: newDate,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t(keys.update_success));
                },
                onError: () => {
                    setToastError(t(keys.update_error));
                },
            },
        );
    };
    const { mutate: createObjectiveTarget } = useCreateObjectiveTarget(
        objective?.id,
    );

    const sortedTargets = useMemo(
        () =>
            objective?.targets?.sort(
                (a, b) =>
                    new Date(a.date).getTime() - new Date(b.date).getTime(),
            ),
        [objective?.targets],
    );

    return (totalTargets > 0 || isAddMenuOpen) && !!objective.indicatorId ? (
        <FlexCol w="full" br="lg" gap="4" px="5" py="4">
            {totalTargets > 0 && (
                <FlexRow alignItems="center" justifyContent="between">
                    <Text variant="body" color="secondary">
                        {t(keys.targets)}
                    </Text>
                    <Button
                        size="sm"
                        variant="plain"
                        onClick={handleAddButtonClick}
                    >
                        <Text variant="body-sm" color="primary">
                            {t(keys.add_target)}
                        </Text>
                        <Icon name="plus" size="sm" />
                    </Button>
                </FlexRow>
            )}
            {isAddMenuOpen && (
                <AddTargetForm
                    handleCancelButtonClick={handleCancelButtonClick}
                    handleCreateButtonClick={handleCreateButtonClick}
                    unitShortname={objective.indicator?.unit?.shortName || ""}
                />
            )}
            {totalTargets > 0 && (
                <FlexCol alignItems="start">
                    {sortedTargets.map((target, idx) => (
                        <FlexCol key={target.id} gap="0" alignSelf="stretch">
                            <Target
                                key={target.id}
                                target={target}
                                unitShortName={
                                    objective?.indicator?.unit?.shortName || ""
                                }
                                isLast={idx === sortedTargets.length - 1}
                                objectiveId={objective.id}
                            />
                            {idx < sortedTargets.length - 1 && (
                                <FlexRow
                                    alignItems="center"
                                    className="w-[14px] h-[16px]  border-r"
                                />
                            )}
                        </FlexCol>
                    ))}
                </FlexCol>
            )}
        </FlexCol>
    ) : !!objective.indicatorId ? (
        <FlexCol
            gap="4"
            py="6"
            justifyContent="center"
            alignItems="center"
            br="lg"
            className="bg-secondary"
        >
            <FlexCol gap="2" alignItems="center">
                <Icon name="flag" size="sm" />
                <FlexCol gap="1" alignItems="center">
                    <Text variant="body">{t(keys.no_targets)}</Text>
                    <Text variant="body-sm" color="secondary">
                        {t(keys.target_description)}
                    </Text>
                </FlexCol>
            </FlexCol>
            <Button variant="outlined" size="sm" onClick={handleAddButtonClick}>
                <FlexRow gap="1" alignItems="center">
                    <Text variant="body-sm">{t(keys.add_target)}</Text>
                    <Icon name="plus" size="sm" />
                </FlexRow>
            </Button>
        </FlexCol>
    ) : null;
};
