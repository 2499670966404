import i18next from "../../i18n";

i18next.addResourceBundle("en", "Frameworks", {
    title: "Frameworks",
    subtitle: "Frameworks currently supported by the Beavr platform.",
    reqs_ok: "{{count}} approved requirements",
    datapoints_ok: "{{count}} completed datapoints",
    reqs_total: "{{count}} total",
    datapoints_total: "{{count}} total",
    coming_soon: "Coming soon...",
    coming_soon_Q2_2025: "Q2 2025",
    coming_soon_beta: "Almost ready...",
    no_data: "No data available",
    available_sales: "Available",
});

i18next.addResourceBundle("fr", "Frameworks", {
    title: "Référentiels",
    subtitle: "Les référentiels supportés par la plateforme Beavr.",
    reqs_ok: "{{count}} contrôles validés",
    datapoints_ok: "{{count}} datapoints completés",
    reqs_total: "{{count}} total",
    datapoints_total: "{{count}} total",
    coming_soon: "Bientôt disponible...",
    coming_soon_Q2_2025: "T2 2025",
    coming_soon_beta: "Presque prêt...",
    no_data: "Pas de données disponibles",
    available_sales: "Disponible",
});
