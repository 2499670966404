import i18next from "@app/i18n";

const enResources = {
    page_title: "Questionnaire",
    page_subtitle: "External questionnaire",

    back_button_label: "Back to questionnaires",

    table_header_question: "Question",
    table_header_answer: "Answer",
    table_header_status: "Status",
    table_actions_dd_delete: "Delete",
    table_actions_dd_edit: "Edit",

    delete_modal_title: "Delete question",
    delete_modal_description: "Are you sure you want to delete this question?",
    cancel: "Cancel",

    create_questions_modal_trigger: "Add questions",
    create_questions_modal_title: "Add questions",
    create_questions_modal_confirm: "Add questions",
    create_questions_modal_cancel: "Cancel",
    create_questions_modal_confirm_disabled_tooltip:
        "Please fill question and type for each block",
    create_questions_modal_add_question: "Add question",

    create_questions_modal_question_name_label: "Question",
    create_questions_modal_question_name_placeholder:
        "Example: Total CO2 emissions in the current year",
    create_questions_modal_question_type_dd_label: "Type",
    create_questions_modal_question_type_dd_item_label_narrative: "Text",

    edit_question_modal_title: "Edit question",
    edit_question_modal_confirm: "Edit",
    edit_question_modal_name_label: "Question",
    edit_question_modal_description_label: "Description",
    edit_question_modal_owner_label: "Owner",
    edit_question_modal_type_label: "Type",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    page_title: "Questionnaire externe",
    page_subtitle: "Questionnaire externe",

    back_button_label: "Retour à la liste des questionnaires",

    delete_modal_title: "Supprimer la question",
    delete_modal_description:
        "Êtes-vous sûr de vouloir supprimer cette question ?",

    cancel: "Annuler",

    table_header_question: "Question",
    table_header_answer: "Réponse",
    table_header_status: "Status",
    table_actions_dd_delete: "Supprimer",
    table_actions_dd_edit: "Modifier",

    create_questions_modal_trigger: "Ajouter des questions",
    create_questions_modal_title: "Ajouter des questions",
    create_questions_modal_confirm: "Ajouter des questions",
    create_questions_modal_cancel: "Annuler",
    create_questions_modal_confirm_disabled_tooltip:
        "Veuillez remplir question et type pour chaque bloc",
    create_questions_modal_add_question: "Rajouter une question",

    create_questions_modal_question_name_label: "Question",
    create_questions_modal_question_name_placeholder:
        "Exemple: Total des émissions de carbone en l'année en cours",
    create_questions_modal_question_type_dd_label: "Type",
    create_questions_modal_question_type_dd_item_label_narrative: "Texte",

    edit_question_modal_title: "Modifier la question",
    edit_question_modal_confirm: "Modifier",
    edit_question_modal_name_label: "Question",
    edit_question_modal_description_label: "Description",
    edit_question_modal_owner_label: "Owner",
    edit_question_modal_type_label: "Type",
} as const;

export const RESOURCE_NAME = "ExternalQuestionnaire";
export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
