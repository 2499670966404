import { useSetToast } from "@app/components/Toast";
import { useUpdateObjective } from "@app/screens/Objectives/data";
import { FlexCol } from "@design-system/Layout/Flex";
import { GetObjectiveQuery, ResponseStatus } from "@generated/client/graphql";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditableText } from "../EditableText";
import { RESOURCE_NAME, keys } from "./i18n";

type ObjectiveActionPlanProps = {
    objective: GetObjectiveQuery["objective"];
};

export const ObjectiveActionPlan: FC<ObjectiveActionPlanProps> = ({
    objective,
}) => {
    const [actionPlan, setActionPlan] = useState<string | undefined>(
        objective?.actionPlan || undefined,
    );
    const { mutate } = useUpdateObjective(objective?.id);
    const { setToastSuccess, setToastError } = useSetToast();
    const handleActionPlanSave = () => {
        if (actionPlan !== objective.actionPlan) {
            const newActionPlan = actionPlan || null;
            mutate(
                { id: objective.id, actionPlan: newActionPlan },
                {
                    onSettled: (data) => {
                        if (
                            data?.updateObjective?.status ===
                            ResponseStatus.Error
                        ) {
                            setToastError(
                                `${data.updateObjective.error?.message}`,
                            );
                        } else if (
                            data?.updateObjective?.status ===
                            ResponseStatus.Success
                        ) {
                            setToastSuccess(t(keys.update_success));
                        }
                    },
                    onError: () => {
                        setToastError(t(keys.update_error));
                    },
                },
            );
        }
    };

    const onInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setActionPlan(event.target.value || undefined);
    };
    const { t } = useTranslation(RESOURCE_NAME);

    return (
        <FlexCol w="full">
            <EditableText
                value={actionPlan}
                onInput={onInput}
                onBlur={handleActionPlanSave}
                placeholder={t(keys.action_plan_placeholder)}
                variant="body"
                className="min-h-40"
            />
        </FlexCol>
    );
};
