import { cn } from "@design-system/Utilities";
import { KeyboardEvent, useLayoutEffect, useRef } from "react";

type EditableTextProps = {
    value: string | undefined;
    onBlur: () => void;
    placeholder?: string;
    variant?: "header1" | "body";
    className?: string;
    error?: string;
    auto_resize?: boolean;
    ref?: React.RefObject<HTMLTextAreaElement>;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const EditableText = ({
    value,
    onBlur,
    placeholder,
    variant = "body",
    className,
    error,
    ref,
    ...props
}: EditableTextProps) => {
    const _ref = useRef<HTMLTextAreaElement>(null);
    const textAreaRef = ref ?? _ref;
    const adjustHeight = () => {
        if (textAreaRef?.current) {
            textAreaRef.current.style.height = "inherit";
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    };

    useLayoutEffect(adjustHeight, []);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        adjustHeight();
        props.onChange?.(e);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter" && event.metaKey) {
            event.preventDefault();
            textAreaRef.current?.blur();
        }
    };
    return (
        <textarea
            ref={textAreaRef}
            className={cn(
                "p-0 border-none w-full focus:ring-0 focus:text-secondary outline-none whitespace-pre-wrap resize-none",
                "w-full rounded-lg py-1 hover:bg-neutral-100",
                !value && "text-secondary",
                variant === "header1" && "text-2xl font-bold",
                variant === "body" && "text-base",
                className,
            )}
            placeholder={placeholder}
            value={value}
            onBlur={onBlur}
            onKeyDown={handleKeyDown}
            {...props}
            onChange={handleChange}
        />
    );
};
