import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Link } from "react-router-dom";
import { useAnnotationDocument } from "../data";

export const MessageAnnotation = ({
    openAiFileId,
}: {
    openAiFileId: string;
}) => {
    const { annotationDocument, documentLink, isPending, isFetched } =
        useAnnotationDocument(openAiFileId);

    if (isFetched && !annotationDocument) return null;

    if (isPending) {
        return <Skeleton className="inline-block w-11 h-3 ml-2" />;
    }

    return (
        <Link className="font-bold ml-2 text-base" to={documentLink}>
            [{annotationDocument?.name}]
        </Link>
    );
};
