import { ValidationStatus } from "@generated/client/graphql";
import { type ExternalQuestionnairesListItem } from "./types";

export const getQuestionnaireProgress = (questionnaire: ExternalQuestionnairesListItem) => {
    const totalQuestions = questionnaire.questions.length;
    const completedQuestions = questionnaire.questions.filter(
        (question) => question.answer?.status === ValidationStatus.Validated,
    ).length;
    return {
        completedQuestions,
        totalQuestions,
    };
};
