import { orderSort } from "@app/lib/utils";
import { themesAtom } from "@app/store/themeStore";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { HeaderFilter } from "@design-system/Table";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { useAtom } from "jotai";
import { FC, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";

export const ThemeMultiSelect: FC<
    PropsWithChildren<{
        isHeader?: boolean;
        values: (string | null)[] | undefined;
        onValuesChange: (val: (string | null)[]) => void;
        searchFilter?: string | undefined;
        onSearchFilterChange?: (val: string | undefined) => void;
        hasPortal?: boolean;
        isModal?: boolean;
    }>
> = ({
    isHeader,
    values,
    onValuesChange,
    searchFilter,
    onSearchFilterChange,
    isModal,
    hasPortal,
}) => {
    const [{ data }] = useAtom(themesAtom);
    const { t } = useTranslation("ThemeMultiSelect");

    const filteredThemes = useMemo(() => {
        return orderSort(data?.themes || []).filter(
            ({ name }) =>
                !searchFilter ||
                name.toLowerCase().includes(searchFilter.toLowerCase()),
        );
    }, [data, searchFilter]);

    const active = !!values?.length;

    return (
        <Dropdown
            selectType="multi"
            value={values?.filter((v) => v != null) || []}
            onValueChange={onValuesChange}
            modal={isModal}
            closeOnValueChange={false}
        >
            <Dropdown.Trigger
                label={t("placeholder")}
                className="w-full h-full"
            >
                {isHeader ? (
                    <HeaderFilter active={active}>
                        {t("placeholder", {
                            context: active && "active",
                            count: values?.length,
                        })}
                    </HeaderFilter>
                ) : (
                    <Button active={active} variant="outlined" size="sm">
                        {t("placeholder")}
                        <Button.Icon name="angleDown" />
                    </Button>
                )}
            </Dropdown.Trigger>
            <Dropdown.Content hasPortal={hasPortal}>
                {onSearchFilterChange && (
                    <MultiSelect.Search
                        searchString={searchFilter || ""}
                        setSearchString={onSearchFilterChange}
                        placeholder={t("searchbar_placeholder")}
                    />
                )}
                {filteredThemes
                    .filter((theme) => theme.id != null)
                    .map((theme, idx) => (
                        <Dropdown.SelectableItem
                            key={idx}
                            value={theme.id || ""}
                        >
                            <Dropdown.Checkbox />
                            <Dropdown.ItemText>
                                <Ellipsis asChild>
                                    <span>{theme.name}</span>
                                </Ellipsis>
                            </Dropdown.ItemText>
                        </Dropdown.SelectableItem>
                    ))}
            </Dropdown.Content>
        </Dropdown>
    );
};
