import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";

export const ObjectiveBodySkeleton = () => {
    return (
        <FlexCol gap="8">
            <IndicatorDisplaySkeleton />
            <ObjectiveDescriptionSkeleton />
            <ObjectiveDescriptionSkeleton />
        </FlexCol>
    );
};

const IndicatorDisplaySkeleton = () => {
    return (
        <FlexCol gap="5">
            <FlexRow justifyContent="between">
                <Skeleton h="4" w="40" br="lg" />
                <Skeleton h="4" w="12" br="lg" />
            </FlexRow>
            <hr />
            <FlexRow
                px="3"
                py="2.5"
                gap="3"
                br="lg"
                className="bg-accent-100 border border-accent-450"
            >
                <Skeleton h="2" w="4" br="lg" />
                <Skeleton h="4" w="40" br="lg" />
            </FlexRow>
            <FlexCol w="full" br="lg" className="border border-secondary">
                <FlexCol
                    gap="2"
                    px="5"
                    py="4"
                    w="full"
                    className="border-b border-secondary"
                >
                    <Skeleton h="4" w="20" br="lg" />
                    <Skeleton h="4" w="40" br="lg" />
                </FlexCol>
                <ObjectiveTargetsSkeleton />
            </FlexCol>
        </FlexCol>
    );
};

const ObjectiveDescriptionSkeleton = () => {
    return (
        <FlexCol gap="5">
            <Skeleton h="4" w="40" br="lg" />
            <hr />
            <FlexRow
                px="3"
                py="2.5"
                gap="3"
                br="lg"
                className="bg-accent-100 border border-accent-450"
            >
                <Skeleton h="2" w="4" br="lg" />
                <Skeleton h="4" w="40" br="lg" />
            </FlexRow>
            <FlexCol gap="2">
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="full" br="lg" />
                <Skeleton h="2" w="40" br="lg" />
            </FlexCol>
        </FlexCol>
    );
};

const ObjectiveTargetsSkeleton = () => {
    return (
        <FlexCol gap="4" px="5" py="4">
            <FlexRow justifyContent="between">
                <Skeleton h="4" w="20" br="lg" />
                <Skeleton h="4" w="12" br="lg" />
            </FlexRow>
            <FlexCol gap="4">
                <FlexRow justifyContent="between">
                    <FlexRow gap="7" alignItems="center">
                        <Skeleton h="2" w="6" br="lg" />
                        <Skeleton h="2" w="28" br="lg" />
                    </FlexRow>
                    <Skeleton h="2" w="40" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
                <FlexRow justifyContent="between">
                    <FlexRow gap="7" alignItems="center">
                        <Skeleton h="2" w="6" br="lg" />
                        <Skeleton h="2" w="28" br="lg" />
                    </FlexRow>
                    <Skeleton h="2" w="40" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
                <FlexRow justifyContent="between">
                    <FlexRow gap="7" alignItems="center">
                        <Skeleton h="2" w="6" br="lg" />
                        <Skeleton h="2" w="28" br="lg" />
                    </FlexRow>
                    <Skeleton h="2" w="40" br="lg" />
                    <Skeleton h="2" w="4" br="lg" />
                </FlexRow>
            </FlexCol>
        </FlexCol>
    );
};
