import { Spinner } from "@app/components/TipTap/components/ui/Spinner";
import { InfoBox } from "@app/shared/components/InfoBox";
import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import { Button } from "@design-system/Inputs/Button";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { Flex, FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    AiThreadMessageRole,
    OpenAiThreadRunStatus,
} from "@generated/client/graphql";
import { useMutationState } from "@tanstack/react-query";
import { useAtom } from "jotai";
import { useEffect, useRef, type FC, type FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { currentAiThreadIdAtom, RunStatus } from "../ctx";
import {
    askAiKeys,
    useAsyncCreateEmptyThread,
    useSendPrompt,
    useThread,
    useThreads,
} from "../data";
import { keys, RESOURCE_NAME } from "../i18n";
import "../index.css";
import { AiErrorMessage } from "./AiErrorMessage";
import { AskAiPlaceholder } from "./AskAiPlaceholder";
import { MessageBlock } from "./MessageBlock";

export const AiChat: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);

    const [currentAiThreadId, setCurrentAiThreadId] = useAtom(
        currentAiThreadIdAtom,
    );
    const { threads } = useThreads();
    const {
        thread,
        isPending,
        isError: threadHasError,
    } = useThread(currentAiThreadId);
    const {
        isError: promptHasError,
        isPending: isPromptPending,
        runStatus,
        lastRunThreadId,
        sendPrompt,
    } = useSendPrompt();

    const { createThreadAsync } = useAsyncCreateEmptyThread();

    const isRunCancelling =
        thread?.runStatus === OpenAiThreadRunStatus.Cancelling;
    const isRunQueued = thread?.runStatus === OpenAiThreadRunStatus.Queued;
    const isRunInProgress =
        thread?.runStatus === OpenAiThreadRunStatus.InProgress;

    const createFirstThread = async (prompt: string) => {
        const thread = await createThreadAsync();
        await sendPrompt({ prompt, thread });
        setCurrentAiThreadId(thread.id);
    };

    const isFirstThread = threads.length === 0;
    const isLoading =
        runStatus === RunStatus.Streaming ||
        runStatus === RunStatus.SearchingFiles ||
        runStatus === RunStatus.SearchingReporting ||
        isPromptPending ||
        isRunQueued ||
        isRunInProgress;
    const hasError = threadHasError || promptHasError;
    const isInputDisabled = isLoading || isRunCancelling;
    const isCurrentRunFailed =
        runStatus === RunStatus.Failed && lastRunThreadId === currentAiThreadId;

    const inputRef = useRef<HTMLInputElement>(null);
    const clearInput = () => {
        if (!inputRef.current) return;
        inputRef.current.value = "";
    };

    const messages = thread?.messages || [];
    const centerInput = messages.length === 0;

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const question = inputRef.current?.value;
        if (!question) return;

        if (!thread || !currentAiThreadId) {
            return createFirstThread(question);
        }

        sendPrompt({ prompt: question, thread });
        clearInput();
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const debouncedScrollIntoView = useDebouncedStableCallback(() => {
        const container = containerRef.current;
        if (!container) return;
        container.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);

    useEffect(() => {
        // Create ResizeObserver to detect height changes
        const resizeObserver = new ResizeObserver(debouncedScrollIntoView);

        // Start observing the container
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        // Cleanup observer on unmount
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [debouncedScrollIntoView, runStatus, isPromptPending]);

    const createThreadMutationState = useMutationState({
        filters: { mutationKey: askAiKeys.createThread() },
        select: (mutation) => mutation.state.status,
    });

    const isCreateThreadPending =
        !!createThreadMutationState?.length &&
        createThreadMutationState[createThreadMutationState.length - 1] ===
            "pending";
    const isLoadingText =
        runStatus === RunStatus.SearchingFiles
            ? t(keys.searching_files)
            : runStatus === RunStatus.SearchingReporting
              ? t(keys.searching_reporting)
              : t(keys.loader);
    return hasError ? (
        <Box w="full">
            <AiErrorMessage />
        </Box>
    ) : (isPending || isCreateThreadPending) && !isFirstThread ? (
        <Box
            className="bg-secondary place-content-center"
            display="grid"
            h="full"
            w="full"
        >
            <Spinner className="border-t-beavrGreen" />
        </Box>
    ) : (
        <FlexCol
            className="bg-secondary p-5 transition-all duration-300 w-[636px]"
            gap="3"
            h="full"
            justifyContent={centerInput ? "center" : "end"}
        >
            <FlexCol
                className="overflow-y-scroll scrollbar-hide"
                maxH="full"
                w="full"
                ref={containerRef}
            >
                {messages.length === 0 ? (
                    <AskAiPlaceholder isFirstThread={isFirstThread} />
                ) : (
                    <Flex direction="col-reverse" gap="4">
                        {messages.map((msg, idx) => (
                            <MessageBlock
                                key={msg.openAiId}
                                message={msg}
                                scrollIntoView={idx === 0}
                                appendBall={
                                    idx === 0 &&
                                    msg.role ===
                                        AiThreadMessageRole.Assistant &&
                                    runStatus === RunStatus.Writing
                                }
                            />
                        ))}
                        {messages.length > 99 ? (
                            <FlexRow w="full" justifyContent="center">
                                <InfoBox text={t(keys.passed_100_messages)} />
                            </FlexRow>
                        ) : null}
                    </Flex>
                )}
            </FlexCol>
            {isLoading && <Loader loadingText={isLoadingText} />}
            {isCurrentRunFailed && (
                <Box>
                    <Text>{t(keys.run_error)}</Text>
                </Box>
            )}
            {isRunCancelling && (
                <Box>
                    <Text>{t(keys.run_status_cancelling)}</Text>
                </Box>
            )}
            <form className="[all:unset] w-full" onSubmit={handleSubmit}>
                <FlexRow w="full">
                    <Textfield
                        disabled={isInputDisabled}
                        placeholder={t(keys.ask_me_anything)}
                        type="text"
                        className="w-full"
                        ref={inputRef}
                    />
                    <Button
                        className="rounded-[120px] relative right-8 top-3 min-h-[32px] min-w-[32px]"
                        disabled={isInputDisabled}
                        size="sm"
                        type="submit"
                    >
                        <Button.Icon name="arrowUp" />
                    </Button>
                </FlexRow>
            </form>
        </FlexCol>
    );
};

const Loader = ({ loadingText }: { loadingText?: string }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    return (
        <FlexRow ml="1" mt="2">
            <Text variant="body" color="tertiary" mr="2">
                {loadingText ?? t(keys.loader)}
            </Text>
            <div className="loader" />
        </FlexRow>
    );
};
