import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { graphql } from "@generated/client";
import {
    CreateQuestionsFromTextInput,
    type AnswerUpsertInput,
    type QuestionCreateInput,
    type QuestionFilter,
    type QuestionnaireCreateInput,
    type QuestionUpdateInput,
} from "@generated/client/graphql";

const GetExternalQuestionnaireQuery = graphql(`
    query GetExternalQuestionnaire($id: ID!) {
        questionnaire(id: $id) {
            id
            name
            requesterEmail
            companyName
            deadline
        }
    }
`);

const GetExternalQuestionnairesQuery = graphql(`
    query GetExternalQuestionnaires {
        questionnaires {
            id
            name
            requesterEmail
            companyName
            deadline
            questions {
                answer {
                    status
                }
            }
        }
    }
`);

const CreateExternalQuestionnaireQuery = graphql(`
    mutation CreateExternalQuestionnaire($input: QuestionnaireCreateInput!) {
        createQuestionnaire(input: $input) {
            id
        }
    }
`);

const CreateQuestionsMutation = graphql(`
    mutation CreateQuestions($input: [QuestionCreateInput!]!) {
        createQuestions(input: $input)
    }
`);

const GetQuestionsQuery = graphql(`
    query GetQuestions($filter: QuestionFilter!) {
        questions(filter: $filter) {
            id
            activation
            name
            description
            type
            ownerId
            answer {
                id
                value
            }
        }
    }
`);

const GetQuestionQuery = graphql(`
    query GetQuestion($id: ID!) {
        question(id: $id) {
            id
            activation
            answer {
                id
                value
                comment
                status
                aiSuggestion
                evidenceFiles {
                    id
                    name
                    signedUrl
                    mimeType
                    documentVersions {
                        document {
                            name
                        }
                    }
                }
            }
            name
            description
            type
            ownerId
            questionnaireId
        }
    }
`);

const UpdateQuestionMutation = graphql(`
    mutation UpdateQuestion($id: ID!, $input: QuestionUpdateInput!) {
        updateQuestion(id: $id, input: $input) {
            id
        }
    }
`);

const UpsertAnswerMutation = graphql(`
    mutation UpsertAnswer($input: AnswerUpsertInput!) {
        upsertAnswer(input: $input) {
            id
        }
    }
`);

const DeleteQuestionMutation = graphql(`
    mutation DeleteQuestion($id: ID!) {
        deleteQuestion(id: $id) {
            id
        }
    }
`);

const GenerateQuestionAiSuggestionMutation = graphql(`
    mutation generateQuestionAiSuggestion($id: ID!) {
        generateQuestionAiSuggestion(id: $id) {
            id
        }
    }
`);

const ImportQuestionsFromTextMutation = graphql(`
    mutation ImportQuestionsFromText(
        $questionnaireId: ID!
        $input: CreateQuestionsFromTextInput!
    ) {
        createQuestionsFromText(
            questionnaireId: $questionnaireId
            input: $input
        )
    }
`);

export class GraphQLExternalQuestionnaireRepository {
    getExternalQuestionnaire = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetExternalQuestionnaireQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getExternalQuestionnaires = async () => {
        return graphqlClientKoyeb.request(
            GetExternalQuestionnairesQuery,
            {},
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createExternalQuestionnaire = async (input: QuestionnaireCreateInput) => {
        return graphqlClientKoyeb.request(
            CreateExternalQuestionnaireQuery,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createQuestions = async (input: QuestionCreateInput[]) => {
        return graphqlClientKoyeb.request(
            CreateQuestionsMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestion = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetQuestionQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getQuestions = async (filter: QuestionFilter) => {
        return graphqlClientKoyeb.request(
            GetQuestionsQuery,
            { filter },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateQuestion = async (id: string, input: QuestionUpdateInput) => {
        return graphqlClientKoyeb.request(
            UpdateQuestionMutation,
            { id, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    upsertAnswer = async (input: AnswerUpsertInput) => {
        return graphqlClientKoyeb.request(
            UpsertAnswerMutation,
            { input },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteQuestion = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteQuestionMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    generateAiSuggestion = async (questionId: string) => {
        return graphqlClientKoyeb.request(
            GenerateQuestionAiSuggestionMutation,
            { id: questionId },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    importQuestionsFromText = async (
        questionnaireId: string,
        input: CreateQuestionsFromTextInput,
    ) => {
        return graphqlClientKoyeb.request(
            ImportQuestionsFromTextMutation,
            { questionnaireId, input },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
