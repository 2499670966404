import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { type ColumnDef } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EditOrDeleteDropdown } from "./components/EditOrDeleteDropdown";
import { RESOURCE_NAME, keys } from "./i18n";
import { type ExternalQuestionnaireQuestion } from "./types";
import { Ellipsis } from "@design-system/Typography/Ellipsis";

export const columns: ColumnDef<ExternalQuestionnaireQuestion>[] = [
    {
        id: "question",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_question);
        },
        cell: ({ row }) => (
            <Link className="[all:unset]" to={row.original.id}>
                <Ellipsis hasTooltip asChild>
                    <Text className="cursor-pointer">{row.original.name}</Text>
                </Ellipsis>
            </Link>
        ),
        meta: {
            cellClassName: "max-w-[300px]",
            headerClassName: "max-w-[300px]",
            colWidth: "300px",
        },
    },
    {
        id: "answer",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_answer);
        },
        cell: ({ row }) => {
            const answer = row.original.answer?.value?.string;
            if (!answer) {
                return "-";
            }
            return (
                <Ellipsis hasTooltip asChild>
                    <Text>{answer}</Text>
                </Ellipsis>
            );
        },
        meta: {
            cellClassName: "max-w-[300px]",
            headerClassName: "max-w-[300px]",
            colWidth: "300px",
        },
    },
    {
        id: "status",
        header: () => {
            const { t } = useTranslation(RESOURCE_NAME);
            return t(keys.table_header_status);
        },
        cell: ({ row }) => row.original.activation,
        meta: {
            cellClassName: "min-w-[100px] max-w-[100px]",
            headerClassName: "min-w-[100px] max-w-[100px]",
            colWidth: "100px",
        },
    },
    {
        id: "actions",
        cell: ({ row }) => (
            <Box display="grid" className="place-items-center min-w">
                <EditOrDeleteDropdown id={row.original.id}>
                    <Button size="sm" variant="outlined">
                        <Button.Icon name="more" />
                    </Button>
                </EditOrDeleteDropdown>
            </Box>
        ),
        maxSize: 80,
        meta: {
            cellClassName: "min-w-[72px] max-w-[72px]",
            headerClassName: "min-w-[72px] max-w-[72px]",
            colWidth: "72px",
        },
    },
];
