import { cn } from "@design-system/Utilities";
import { type FC, type HTMLAttributes, type Ref } from "react";

type SvgProps = {
    disabled?: boolean;
};
export interface RadioProps extends HTMLAttributes<HTMLSpanElement> {
    checked?: boolean;
    disabled?: boolean;
    ref?: Ref<HTMLSpanElement>;
}

export const Radio: FC<RadioProps> = ({ checked, disabled, ref, ...props }) => {
    return (
        <span {...props} ref={ref}>
            {!checked ? (
                <Off disabled={disabled} />
            ) : (
                <On disabled={disabled} />
            )}
        </span>
    );
};

const On: FC<SvgProps> = ({ disabled }) => (
    <svg
        className={cn(disabled ? "text-neutral-400" : "text-brand")}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.857117 9C0.857117 4.50283 4.5028 0.857147 8.99997 0.857147C13.4971 0.857147 17.1428 4.50283 17.1428 9C17.1428 13.4972 13.4971 17.1429 8.99997 17.1429C4.5028 17.1429 0.857117 13.4972 0.857117 9ZM8.99997 3.14286C5.76516 3.14286 3.14283 5.76519 3.14283 9C3.14283 12.2348 5.76516 14.8571 8.99997 14.8571C12.2348 14.8571 14.8571 12.2348 14.8571 9C14.8571 5.76519 12.2348 3.14286 8.99997 3.14286Z"
            fill="currentColor"
        />
        <rect x="5" y="5" width="8" height="8" rx="4" fill="currentColor" />
    </svg>
);

const Off: FC<SvgProps> = ({ disabled }) => (
    <svg
        className={cn(
            disabled ? "text-neutral-200" : "text-neutral-500",
            !disabled && "hover:text-brand",
        )}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z"
            fill="currentColor"
        />
    </svg>
);
