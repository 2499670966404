import { Icon } from "@design-system/Icon";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtom } from "jotai";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document } from "../../../../../generated/client/graphql";
import { DocumentRelationType, Relations } from "./InfoTab";
import RelatedDocumentListElement from "./RelatedDocumentsListElement";
import { isRelatedDocumentsListOpenAtom } from "./ctx";
import "./i18n";

const RelatedDocumentList = ({
    relatedDocuments,
    relationType,
    insertDocument,
}: {
    relatedDocuments: Document[];
    relationType: DocumentRelationType;
    insertDocument: ((relatedDocument: Document) => void) | null;
}) => {
    const { t } = useTranslation("DocumentEditorSideBar");

    const [activeDocIdx, setActiveDocIdx] = useState<number | undefined>(
        undefined,
    );
    const toggleActive = (activeDocIdx: number | undefined, idx: number) => {
        activeDocIdx === idx
            ? setActiveDocIdx(undefined)
            : setActiveDocIdx(idx);
    };

    const [isRelatedDocumentsListOpen, setIsRelatedDocumentsListOpen] = useAtom(
        isRelatedDocumentsListOpenAtom,
    );

    const handleToggleRelatedDocumentsList = () => {
        setIsRelatedDocumentsListOpen(!isRelatedDocumentsListOpen);
    };

    const objectivesLegacyFlag = useFeatureFlagEnabled("legacyObjectives");

    return (
        <>
            {relatedDocuments &&
                relatedDocuments.length > 0 &&
                objectivesLegacyFlag && (
                    <div className="flex flex-col items-start gap-2 self-stretch">
                        <FlexRow
                            w="full"
                            py="1"
                            justifyContent="between"
                            className="font-bold text-sm hover:bg-secondary rounded-lg cursor-pointer"
                            onClick={handleToggleRelatedDocumentsList}
                        >
                            <Text variant="body-sm-bold">
                                {relationType === Relations.CHILDREN
                                    ? t("children_documents")
                                    : t("parent_documents")}
                            </Text>
                            <Icon
                                name={
                                    isRelatedDocumentsListOpen
                                        ? "angleUp"
                                        : "angleDown"
                                }
                                size="sm"
                            />
                        </FlexRow>
                        {isRelatedDocumentsListOpen && (
                            <div className="flex flex-col items-start self-stretch">
                                {relatedDocuments.map((doc, idx) => (
                                    <RelatedDocumentListElement
                                        key={doc.id}
                                        isActive={activeDocIdx === idx}
                                        relatedDocument={doc}
                                        isFirst={idx === 0}
                                        isLast={
                                            idx === relatedDocuments.length - 1
                                        }
                                        relationType={relationType}
                                        insertDocument={insertDocument}
                                        toggleActive={() =>
                                            toggleActive(activeDocIdx, idx)
                                        }
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                )}
        </>
    );
};

export default RelatedDocumentList;
