import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { cn } from "@design-system/Utilities";
import { forwardRef } from "react";

export const PrefillButton = forwardRef<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement> & { tooltipContent?: string }
>(({ tooltipContent, ...props }, ref) => {
    if (!tooltipContent) {
        return <BaseButton {...props} ref={ref} />;
    }
    return (
        <Tooltip>
            <Tooltip.Trigger asChild>
                <BaseButton {...props} ref={ref} />
            </Tooltip.Trigger>
            <Tooltip.Content>{tooltipContent}</Tooltip.Content>
        </Tooltip>
    );
});

const BaseButton = forwardRef<
    HTMLButtonElement,
    React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, ...props }, ref) => {
    return (
        <Button
            className={cn(
                "!h-7 !w-7 !max-h-7 !max-w-7 min-w-[40px]",
                "p-0",
                "grid place-content-center",
                "border border-tertiary",
                "rounded-full",
                "shadow-b-100",
                className,
            )}
            ref={ref}
            {...props}
        >
            <Button.Icon name="loadData" size="md" />
        </Button>
    );
});
