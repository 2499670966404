import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UpdateUserModal", {
    title: "Modify user",
    subtitle: "Modify name, email, role or department",
    firstname: "First name",
    lastname: "Last name",
    email: "Email",
    department: "Department",
});

i18next.addResourceBundle("fr", "UpdateUserModal", {
    title: "Modifier l'utilisateur",
    subtitle: "Modifier le nom, l'e-mail, le rôle ou le département",
    firstname: "Prénom",
    lastname: "Nom",
    email: "Email",
    department: "Département",
});
