import { cn } from "@design-system/Utilities";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { type ClassValue } from "class-variance-authority/types";
import { forwardRef, type FC } from "react";

/**
 * It's a separate component because we need to wrap the tooltip
 * and
 * ```
 * <Tooltip.Provider>
 *      <Tooltip> ...</Tooltip>
 * </Tooltip.Provider>
 * ```
 * is ugly.
 */
export const TooltipProvider = RadixTooltip.Provider;

const Trigger = RadixTooltip.Trigger;
interface TooltipContentProps extends RadixTooltip.TooltipContentProps {
    arrowClassName?: ClassValue;
}
const Content = forwardRef<HTMLDivElement, TooltipContentProps>(
    (
        { children, className, sideOffset = 5, arrowClassName, ...props },
        ref,
    ) => (
        <RadixTooltip.Portal>
            <RadixTooltip.Content
                ref={ref}
                {...props}
                sideOffset={sideOffset}
                className={cn(
                    "z-50 bg-neutral-900 text-white rounded-lg min-h-5 py-1 px-2 text-sm color-white font-bold",
                    className,
                )}
            >
                {children}
                <RadixTooltip.Arrow
                    className={cn(
                        "fill-neutral-900 w-[18px] h-[6px]",
                        arrowClassName,
                    )}
                />
            </RadixTooltip.Content>
        </RadixTooltip.Portal>
    ),
);

type TooltipType = FC<RadixTooltip.TooltipProps> & {
    Trigger: typeof Trigger;
    Content: typeof Content;
};
export const Tooltip: TooltipType = ({ delayDuration, ...props }) => (
    <RadixTooltip.Root delayDuration={delayDuration ?? 0} {...props} />
);
Tooltip.Trigger = Trigger;
Tooltip.Content = Content;
