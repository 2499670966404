import { GraphQLThemeRepository } from "@app/repositories/GraphQLRepositories/ThemeRepository";
import { skipToken, useQuery } from "@tanstack/react-query";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { useMemo } from "react";
import { themeKeys } from "./queryKeys";

const themeRepository = new GraphQLThemeRepository();

export const activeReportingEntityIdAtom = atom<string>("");

export const themesAtom = atomWithQuery(() => {
    return {
        queryKey: [themeKeys.themes],
        queryFn: async () => themeRepository.get(),
    };
});

export const useThemes = () => {
    const { data, isPending } = useQuery({
        queryKey: ["themes"],
        queryFn: themeRepository.get,
    });

    const activeThemes = data?.themes.filter(
        (theme) =>
            theme.active && theme.subthemes.some((subtheme) => subtheme.active),
    );
    const sortedThemes = activeThemes?.sort((a, b) => a.order - b.order);

    return {
        themes: sortedThemes,
        isPending,
    };
};

export const useTheme = (themeId: string | undefined) => {
    const { data } = useQuery({
        queryKey: ["theme", themeId],
        queryFn: !!themeId ? () => themeRepository.find(themeId) : skipToken,
    });

    const activeSubthemes = useMemo(
        () => data?.theme?.subthemes.filter((subtheme) => subtheme.active),
        [data?.theme?.subthemes],
    );

    const themeWithSortedSubthemes = useMemo(
        () => ({
            ...data?.theme,
            subthemes: activeSubthemes?.sort((a, b) => a.order - b.order),
        }),
        [data?.theme, activeSubthemes],
    );

    return themeWithSortedSubthemes;
};
