import { TabContent, TabList, TabRoot, TabTrigger } from "@app/components/Tab";
import { Button } from "@design-system/Inputs/Button";
import { Modal } from "@design-system/Modal";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import StartFromList from "./StartFromList";
import StartFromScratch from "./StartFromScratch";
import StartFromYourUpload from "./StartFromUpload";

export const CreateQuestionnaireModal: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [mode, setMode] = useState<string>("list");

    return (
        <Modal>
            <Modal.Trigger asChild>
                <Button>
                    <Button.Icon name="plus" />
                    {t(keys.create_questionnaire_modal_trigger_label)}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay>
                <Modal.Content>
                    <Modal.Header withClose>
                        <Modal.Title>
                            {t(keys.create_questionnaire_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TabRoot
                            value={mode}
                            onValueChange={setMode}
                            orientation="horizontal"
                        >
                            <TabList className="pb-4">
                                <TabTrigger
                                    text={"Verified questionnaires"}
                                    value="list"
                                    icon="list"
                                />
                                <TabTrigger
                                    text={"Upload"}
                                    value="upload"
                                    icon={"upload"}
                                />
                                <TabTrigger
                                    text={"From scratch"}
                                    value="scratch"
                                    icon={"pencil"}
                                />
                            </TabList>
                            <TabContent value="list">
                                <StartFromList />
                            </TabContent>
                            <TabContent value="upload">
                                <StartFromYourUpload />
                            </TabContent>
                            <TabContent value="scratch">
                                <StartFromScratch />
                            </TabContent>
                        </TabRoot>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
