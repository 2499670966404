import { UserPlus } from "iconoir-react";
import ButtonRegular from "../../components/Button/ButtonRegular";
import Container from "../../components/Container";
import BodyHeader from "../../shared/components/BodyHeader";
import NewUserModal, { newUsersModalOpenAtom } from "./components/NewUserModal";
import UsersTable from "./components/UsersTable";

import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import "./i18n";

export default function Users() {
    const { t } = useTranslation("Users");

    const setNewUserModalOpen = useSetAtom(newUsersModalOpenAtom);

    return (
        <>
            <Container>
                <BodyHeader
                    dataChameleon={{
                        key: "users",
                        type: "tutorial",
                    }}
                    title={t("title")}
                    subTitle={t("subtitle")}
                    action={
                        <ButtonRegular
                            title={t("add_user")}
                            IconRight={UserPlus}
                            onClick={() => setNewUserModalOpen(true)}
                        />
                    }
                />
                <div className="p-4">
                    <UsersTable />
                </div>
            </Container>
            <NewUserModal />
        </>
    );
}
