import { graphql } from "@generated/client";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetThemesAndSubthemesQuery = graphql(`
    query getThemesAndSubthemes {
        themes {
            id
            name
            active
            cmsThemeId
            subthemes {
                id
                cmsSubThemeId
                name
                active
                themeId
                order
                active
                objectivesTitle
                objectivesIntro
            }
            color
            order
        }
    }
`);

const FindTheme = graphql(`
    query Theme($themeId: String!) {
        theme(id: $themeId) {
            id
            name
            color
            subthemes {
                id
                name
                active
                order
                objectivesTitle
                objectivesIntro
            }
        }
    }
`);

export class GraphQLThemeRepository {
    get = async () => {
        return graphqlClientKoyeb.request(
            GetThemesAndSubthemesQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    find = async (themeId: string) => {
        return graphqlClientKoyeb.request(
            FindTheme,
            { themeId },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
