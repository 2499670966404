import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { Shutters } from "@design-system/Layout/RollupShutters";
import { Modal } from "@design-system/Modal";
import { QuestionType } from "@generated/client/graphql";
import { Fragment, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useCreateQuestions } from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";
import { canSubmitQuestion } from "../../services";
import { Question } from "../../types";
import { QuestionBlock } from "./QuestionBlock";

export const CreateQuestionsModal: FC = () => {
    const { external_questionnaire_id } = useParams();
    const { t } = useTranslation(RESOURCE_NAME);
    const { createManyAsync, isPending } = useCreateQuestions({
        questionnaireId: external_questionnaire_id,
    });
    const [questions, setQuestions] = useState<Partial<Question>[]>([
        { type: QuestionType.Narrative },
    ]);

    const addQuestion = () => {
        setQuestions([...questions, { type: QuestionType.Narrative }]);
    };

    const editQuestion = (index: number, question: Partial<Question>) => {
        setQuestions(
            questions.map((q, i) => (i === index ? { ...q, ...question } : q)),
        );
    };

    const deleteQuestion = (index: number) => {
        setQuestions(questions.filter((_, i) => i !== index));
    };

    const createQuestions = async () => {
        if (isPending || questions.some((q) => !canSubmitQuestion(q))) {
            return;
        }

        await createManyAsync(questions);
        setQuestions([{}]);
    };

    const disableConfirm = questions.some((q) => !canSubmitQuestion(q));

    return (
        <Modal>
            <Modal.Trigger asChild>
                <Button variant="tonal">
                    <Button.Icon name="plus" />
                    {t(keys.create_questions_modal_trigger)}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay centerContent>
                <Modal.Content>
                    <Modal.Header withClose>
                        <Modal.Title>
                            {t(keys.create_questions_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Box
                            className="bg-tertiary border border-tertiary max-h-[600px] overflow-y-auto"
                            p="4"
                            w="full"
                            h="full"
                            br="lg"
                        >
                            <Shutters classNames="max-w-full">
                                {questions.map((question, idx) => (
                                    <Fragment key={idx}>
                                        {idx !== 0 && <Shutters.Thread />}
                                        <Shutters.Louver>
                                            <QuestionBlock
                                                key={idx}
                                                id={idx}
                                                question={question}
                                                onChange={editQuestion}
                                                onDelete={deleteQuestion}
                                            />
                                        </Shutters.Louver>
                                    </Fragment>
                                ))}
                                <Shutters.Thread />
                                <Button
                                    className="max-w-fit"
                                    onClick={addQuestion}
                                >
                                    <Button.Icon name="circlePlus" />
                                    {t(
                                        keys.create_questions_modal_add_question,
                                    )}
                                </Button>
                            </Shutters>
                        </Box>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>
                            {t(keys.create_questions_modal_cancel)}
                        </Modal.CancelCTA>
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Box>
                                    <Modal.ConfirmCTA
                                        onClick={createQuestions}
                                        disabled={disableConfirm || isPending}
                                    >
                                        {t(keys.create_questions_modal_confirm)}
                                    </Modal.ConfirmCTA>
                                </Box>
                            </Tooltip.Trigger>
                            {disableConfirm && (
                                <Tooltip.Content>
                                    {t(
                                        keys.create_questions_modal_confirm_disabled_tooltip,
                                    )}
                                </Tooltip.Content>
                            )}
                        </Tooltip>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
