import { adminPaths } from "@app/routing/paths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { useEffect } from "react";
import { useQuestion } from "../data";
import { useQuestions } from "../../data";

export function useQuestionBreadcrumb(
    id: string | undefined,
    questionnaireId?: string,
) {
    const setBreadcrumb = useBreadcrumb();
    const { question } = useQuestion(id);
    const { questions } = useQuestions(
        question?.questionnaireId ?? questionnaireId,
    );

    useEffect(() => {
        const options =
            questions?.map((q) => ({
                name: q.name,
                param: q.id,
            })) ?? [];

        setBreadcrumb(
            adminPaths.external_questionnaire_question_id_param,
            question?.name ?? "...",
            options,
        );
    }, [question, questions, setBreadcrumb]);
}
