import { useSetToast } from "@app/components/Toast";
import { useNavigation } from "@app/lib/navigation";
import { Button } from "@design-system/Inputs/Button";
import { Modal } from "@design-system/Modal";
import { useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { activeEntityIdAtom, isCreateObjectiveModalOpenAtom } from "../../ctx";
import { useCreateObjective } from "../../data";
import { selectedIndicatorIdAtom, selectedThemeIdAtom } from "./ctx";
import { RESOURCE_NAME, keys } from "./i18n";
import { SelectIndicatorPage } from "./SelectIndicatorPage";

export const CreateObjectiveModal = () => {
    enum pages {
        setIndicatorStage = "setIndicatorStage",
        setTargetsStage = "setTargetsStage",
    }
    const [currentPageNumber, setCurrentPageNumber] = useState<pages>(
        pages.setIndicatorStage,
    );
    const [isCreateObjectiveModalOpen, setIsCreateObjectiveModalOpen] = useAtom(
        isCreateObjectiveModalOpenAtom,
    );
    const selectedThemeId = useAtomValue(selectedThemeIdAtom);
    const [selectedIndicatorId, setSelectedIndicatorId] = useAtom(
        selectedIndicatorIdAtom,
    );
    const activeEntityId = useAtomValue(activeEntityIdAtom);

    const { mutate, isPending: isCreateObjectivePending } =
        useCreateObjective();
    const { navigateFromHome } = useNavigation();
    const { setToastSuccess, setToastError } = useSetToast();

    const { t } = useTranslation(RESOURCE_NAME);

    const handleConfirmClick = () => {
        if (!activeEntityId) {
            setToastError(t(keys.toast_error_entity));
            return;
        }
        if (!selectedThemeId) {
            setToastError(t(keys.toast_error_theme));
            return;
        }

        mutate(
            {
                themeId: selectedThemeId,
                entityId: activeEntityId,
                indicatorId: selectedIndicatorId,
            },
            {
                onSuccess: (data) => {
                    setToastSuccess(t(keys.toast_success));
                    setIsCreateObjectiveModalOpen(false);
                    const objectiveId = data.createObjective.id;
                    navigateFromHome(`objectives/${objectiveId}`);
                    setSelectedIndicatorId(undefined);
                },
                onError: () => {
                    setToastError(t(keys.toast_error_unknown));
                },
            },
        );
    };

    const handleCancelClick = () => {
        setIsCreateObjectiveModalOpen(false);
        setSelectedIndicatorId(undefined);
    };

    const handleOpenChange = (open: boolean) => {
        setIsCreateObjectiveModalOpen(open);
        setCurrentPageNumber(pages.setIndicatorStage);
        setSelectedIndicatorId(undefined);
    };

    return (
        <Modal
            open={isCreateObjectiveModalOpen}
            onOpenChange={handleOpenChange}
        >
            <Modal.Overlay scrollable>
                <Modal.Content>
                    <Modal.Header withClose className="gap-2">
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                        <Modal.Subtitle variant="body-bold" color="secondary">
                            {t(keys.title_theme)}
                        </Modal.Subtitle>
                    </Modal.Header>
                    <Modal.Body>
                        {currentPageNumber === pages.setIndicatorStage && (
                            <SelectIndicatorPage />
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="plain"
                            size="lg"
                            onClick={handleCancelClick}
                        >
                            {t("cancel_button")}
                        </Button>
                        <Modal.ConfirmCTA
                            onClick={handleConfirmClick}
                            preventClose
                            loading={isCreateObjectivePending}
                        >
                            {t("confirm_button", {
                                context: !selectedIndicatorId && "skip",
                            })}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
