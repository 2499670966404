import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { useParams } from "react-router-dom";
import { useObjective } from "../data";
import { ExampleDrawer } from "./components/ExampleDrawer";
import { IndicatorSelectModal } from "./components/IndicatorSelectModal";
import { ObjectiveBody } from "./components/ObjectiveBody";
import { ObjectiveHeader } from "./components/ObjectiveHeader";
import { ObjectiveSideBar } from "./components/ObjectiveSideBar";
import { ObjectiveBodySkeleton } from "./components/Skeletons/ObjectiveBodySkeleton";
import { ObjectiveHeaderSkeleton } from "./components/Skeletons/ObjectiveHeaderSkeleton";
import { ObjectiveSideBarSkeleton } from "./components/Skeletons/ObjectiveSidebarSkeleton";
import { useObjectivesBreadcrumb } from "./useObjectivesBreadcrumb";

export const ObjectivePage = () => {
    const { objective_id } = useParams();
    const { objective, isPending } = useObjective(objective_id);
    useObjectivesBreadcrumb(objective?.entityId, objective?.themeId);

    return (
        <PageContainer>
            <FlexRow alignItems="start" h="full">
                <FlexCol px="11" py="8" gap="8" className="border-r" w="full">
                    {isPending ? (
                        <>
                            <ObjectiveHeaderSkeleton />
                            <ObjectiveBodySkeleton />
                        </>
                    ) : (
                        objective && (
                            <>
                                <ObjectiveHeader objective={objective} />
                                <ObjectiveBody objective={objective} />
                            </>
                        )
                    )}
                </FlexCol>
                {isPending ? (
                    <ObjectiveSideBarSkeleton />
                ) : (
                    objective && <ObjectiveSideBar objective={objective} />
                )}
            </FlexRow>
            {objective && <IndicatorSelectModal objective={objective} />}
            <ExampleDrawer />
        </PageContainer>
    );
};
