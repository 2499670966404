import { UserAssign } from "@app/shared/components/UserAssign";
import { Textarea } from "@design-system/Inputs/Textarea";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { type QuestionType } from "@generated/client/graphql";
import {
    useState,
    type ChangeEvent,
    type FC,
    type PropsWithChildren,
} from "react";
import { useTranslation } from "react-i18next";
import { keys, RESOURCE_NAME } from "../../i18n";
import { canSubmitQuestion } from "../../services";
import { type ExternalQuestionnaireQuestion } from "../../types";
import { AnswerTypeSelect } from "../AnswerTypeSelect";

type EditQuestionModalProps = {
    loading?: boolean;
    onConfirm: (update: {
        name?: string;
        description?: string | null;
        owner?: string;
        type?: QuestionType;
    }) => void;
    question: ExternalQuestionnaireQuestion;
};

export const EditQuestionModal: FC<
    PropsWithChildren<EditQuestionModalProps>
> = ({ question, children, onConfirm }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const [inputQuestion, setInputQuestion] = useState({
        name: question.name,
        description: question.description,
        ownerId: question.ownerId,
        type: question.type,
    });

    const makeEventHandler =
        (key: keyof ExternalQuestionnaireQuestion) =>
        (e: ChangeEvent<HTMLTextAreaElement> | string[]) => {
            setInputQuestion({
                ...inputQuestion,
                [key]: Array.isArray(e) ? e[0] : e.target.value,
            });
        };

    const disableConfirm = !canSubmitQuestion(inputQuestion);
    const handleConfirm = () => {
        if (disableConfirm) {
            return;
        }
        onConfirm(inputQuestion);
    };

    const typeValue = inputQuestion.type ? [inputQuestion.type] : [];
    const ownerValue = inputQuestion.ownerId ? [inputQuestion.ownerId] : [];

    return (
        <Modal>
            <Modal.Trigger asChild>{children}</Modal.Trigger>
            <Modal.Overlay centerContent>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>
                            {t(keys.edit_question_modal_title)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="2">
                            <Textarea
                                placeholder={t(
                                    keys.edit_question_modal_name_label,
                                )}
                                value={inputQuestion.name}
                                onChange={makeEventHandler("name")}
                            />
                            <Textarea
                                placeholder={t(
                                    keys.edit_question_modal_name_label,
                                )}
                                value={inputQuestion.description ?? undefined}
                                onChange={makeEventHandler("description")}
                            />
                            <FlexRow gap="4">
                                <AnswerTypeSelect
                                    value={typeValue}
                                    onChange={makeEventHandler("type")}
                                />
                                <UserAssign
                                    selectedUsers={ownerValue}
                                    onSelect={makeEventHandler("ownerId")}
                                    selectMode="single"
                                />
                            </FlexRow>
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            onClick={handleConfirm}
                            disabled={disableConfirm}
                        >
                            {t(keys.edit_question_modal_confirm)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
