import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLDocumentRepository } from "@app/repositories/GraphQLRepositories/DocumentRepository";
import { GraphQLExternalQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/externalQuestionnaire";

import {
    type AnswerUpsertInput,
    type CreateFileInput,
    type QuestionType,
    ValidationStatus,
} from "@generated/client/graphql";
import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { externalQuestionnairesKeys } from "../../data";
import { useQuestionUpdate } from "../data";

export const externalQuestionnairesRepository =
    new GraphQLExternalQuestionnaireRepository();
const DocumentRepository = new GraphQLDocumentRepository();

export function useQuestion(id?: string) {
    const { data: { question } = {}, ...query } = useQuery({
        queryKey: externalQuestionnairesKeys.question(id),
        queryFn: id
            ? () => externalQuestionnairesRepository.getQuestion(id)
            : skipToken,
    });
    return { question, ...query };
}

export function useAnswerUpsert() {
    const queryClient = useQueryClient();
    const {
        mutate: upsertAnswer,
        mutateAsync: upsertAnswerAsync,
        ...mutation
    } = useMutation({
        mutationFn: (input: AnswerUpsertInput) =>
            externalQuestionnairesRepository.upsertAnswer(input),
        onSettled(_, __, variables) {
            queryClient.invalidateQueries({
                queryKey: externalQuestionnairesKeys.question(
                    variables?.questionId,
                ),
            });
        },
    });
    return { upsertAnswer, upsertAnswerAsync, ...mutation };
}

export function useQuestionAndAnswerUpdate(
    questionId: string | undefined,
    answerId?: string,
) {
    const { question } = useQuestion(questionId);

    const questionMutation = useQuestionUpdate();
    const answerMutation = useAnswerUpsert();

    const updateName = (name: string) => {
        if (!questionId) return;
        return questionMutation.updateQuestion({
            id: questionId,
            input: { name },
        });
    };

    const updateDescription = (description: string) => {
        if (!questionId) return;
        return questionMutation.updateQuestion({
            id: questionId,
            input: { description },
        });
    };

    const updateOwner = (ownerId?: string) => {
        if (!questionId) return;
        return questionMutation.updateQuestion({
            id: questionId,
            input: { ownerId: ownerId ?? null },
        });
    };

    const updateQuestionType = (questionType: QuestionType) => {
        if (!questionId) return;
        return questionMutation.updateQuestion({
            id: questionId,
            input: { type: questionType },
        });
    };

    const updateAnswer = (
        answer: string,
        opts: { onSuccess?: () => void; onError?: () => void } = {},
    ) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer(
            {
                id: answerId,
                value: { string: answer },
                questionId,
            },
            opts,
        );
    };

    const updateComment = (
        comment: string,
        opts: { onSuccess?: () => void; onError?: () => void } = {},
    ) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer(
            {
                id: answerId,
                comment,
                questionId,
            },
            opts,
        );
    };

    const toggleValidation = () => {
        if (!questionId || answerMutation.isPending) return;
        return answerMutation.upsertAnswer({
            id: answerId,
            status:
                question?.answer?.status === ValidationStatus.Validated
                    ? ValidationStatus.Notvalidated
                    : ValidationStatus.Validated,
            questionId,
        });
    };

    const updateEvidenceFilesAsync = ({
        fileIds,
        files,
    }: {
        fileIds?: string[];
        files?: CreateFileInput[];
    }) => {
        if (!questionId) return;
        return answerMutation.upsertAnswerAsync({
            id: answerId,
            questionId,
            connectEvidenceFileIds: fileIds,
            createAndConnectEvidenceFiles: files,
        });
    };

    const removeEvidenceFiles = (fileIds: string[]) => {
        if (!questionId) return;
        return answerMutation.upsertAnswer({
            id: answerId,
            questionId,
            disconnectEvidenceFileIds: fileIds,
        });
    };

    return {
        toggleValidation,
        updateName,
        updateDescription,
        updateOwner,
        updateAnswer,
        updateComment,
        updateQuestionType,
        updateEvidenceFilesAsync,
        removeEvidenceFiles,
        isPending: questionMutation.isPending || answerMutation.isPending,
    };
}

export function useGenerateAiSuggestion() {
    return useMutation({
        mutationFn: externalQuestionnairesRepository.generateAiSuggestion,
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: externalQuestionnairesKeys.all,
            });
        },
    });
}

export function useGetDocuments(documentIds: string[]) {
    const { data, ...query } = useQuery({
        queryKey: ["getDocuments", documentIds],
        queryFn: () => DocumentRepository.getDocumentsByIds(documentIds),
    });

    return {
        ...query,
        documents: data?.documents ?? [],
    };
}
