import i18next from "@app/i18n";

const enResources = {
    open: "Open",
    defaultTitle: "AI suggestion",
    copy_to_clipboard: "Copied to clipboard",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    open: "Ouvrir",
    defaultTitle: "Suggestion AI",
    copy_to_clipboard: "Copié dans le presse-papiers",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "AiSuggestion";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
