import i18next from "../../../../i18n";

i18next.addResources("en", "DocumentVersionBlock", {
    view: "View",
    edit: "Edit",
    delete: "Delete",
    delete_modal_title: "Delete version",
    delete_modal_description:
        "Are you sure you want to delete this version? This action cannot be undone.",
    delete_modal_cancel: "Cancel",
    approve: "Approve",
    approve_tooltip: "Edit and mark as ready for approval first",
    archived: "Archived",
    last_edited_by: "Last edited by",
    last_edit_time: "on {{date}} at {{time}}",
    approved_by: "Approved by {{approver}}",
    approve_desc:
        "After you approve a version, you will not be able to unapprove or modify it. You will only be able to create a new version of your document.",
    draft: "(Draft)",
    download: "Download",
    cannot_download_file: "This file cannot be downloaded",
    cannot_edit_approved_version_with_editor:
        "You cannot edit an approved version. Duplicate it to make changes.",
    cannot_edit_approved_version_pdf_only:
        "You cannot edit an approved version.",
    duplicate: "Duplicate to make changes",
});
i18next.addResources("fr", "DocumentVersionBlock", {
    view: "Voir",
    edit: "Modifier",
    delete: "Supprimer",
    delete_modal_title: "Supprimer la version",
    delete_modal_description:
        "Êtes-vous sûr de vouloir supprimer cette version ? Vous ne pourrez plus la récupérer.",
    delete_modal_cancel: "Annuler",
    approve: "Approuver",
    approve_tooltip: "Modifier et marquer comme prêt pour approbation d'abord",
    archived: "Archivé",
    last_edited_by: "Dernière modification par",
    last_edit_time: "le {{date}} à {{time}}",
    approved_by: "Approuvé par",
    approve_desc:
        "Après avoir approuvé une version, vous ne pourrez pas la désapprouver ou la modifier. Vous ne pourrez créer qu'une nouvelle version de votre document.",
    draft: "(Brouillon)",
    download: "Télécharger",
    cannot_download_file: "Ce fichier ne peut pas être téléchargé",
    cannot_edit_approved_version_with_editor:
        "Vous ne pouvez pas modifier une version approuvée. Dupliquez-la pour faire des modifications.",
    cannot_edit_approved_version_pdf_only:
        "Vous ne pouvez pas modifier une version approuvée.",
    duplicate: "Dupliquer pour faire des modifications",
});
