import { FileIcon } from "@app/shared/components/FileIcon";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";

type FileType = {
    id: string;
    signedUrl?: string | null;
    name?: string | null;
    mimeType?: string | null;
    documentVersions?:
        | {
              document?: {
                  name?: string | null;
              } | null;
          }[]
        | null;
};

export const UploadedDocumentsList = <T extends FileType>({
    files,
    onRemove,
}: {
    files: T[];
    onRemove: (fileId: string) => void;
}) => {
    const handleDeleteDocumentClick = (fileId: string) => {
        return () => {
            onRemove(fileId);
        };
    };

    return (
        <FlexCol gap="3" w="full">
            {files.map((file) => (
                <FlexRow
                    alignItems="center"
                    justifyContent="between"
                    gap="3"
                    alignSelf="stretch"
                    key={file.id}
                >
                    <a
                        href={file.signedUrl || undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:no-underline text-inherit"
                    >
                        <FlexRow
                            alignItems="center"
                            gap="3"
                            alignSelf="stretch"
                        >
                            <FileIcon mimeType={file.mimeType || ""} />
                            <Text variant="body-bold" color="brand">
                                {(!!file.documentVersions?.length &&
                                    file.documentVersions[0]?.document?.name) ||
                                    file.name}
                            </Text>
                        </FlexRow>
                    </a>
                    <Button
                        size="sm"
                        variant="tonal"
                        onClick={handleDeleteDocumentClick(file.id || "")}
                    >
                        <Icon name="trash" size="sm" />
                    </Button>
                </FlexRow>
            ))}
        </FlexCol>
    );
};
