import { Modal } from "@design-system/Modal";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import "@app/screens/Frameworks/framework/Csrd/pillar/i18n";
import "./i18n";

import { RESOURCE_NAME, keys } from "./i18n";

import { useSetToast } from "@app/components/Toast";
import {
    NewS3File,
    newS3FileToCreateFileInput,
} from "@app/usecases/FileUseCases";
import { FlexCol } from "@design-system/Layout/Flex";
import { Tabs } from "@design-system/Tabs";
import { useEffect, useState } from "react";
import { documentModalActiveDatapoint } from "../../../../screens/Frameworks/framework/Csrd/pillar/ctx";
import { useUpdateDatapoint } from "../../../../screens/Frameworks/framework/Csrd/pillar/data";
import { LibraryTab } from "./LibraryTab";
import { UploadTab } from "./UploadTab";
import { type FC } from "react";
import { type CreateFileInput } from "@generated/client/graphql";

export const DocumentsModal: FC<{
    onConfirm?: (val: {
        fileIds?: string[];
        files?: CreateFileInput[];
    }) => Promise<unknown> | void;
    open?: boolean;
    onClose?: () => void;
    loading?: boolean;
}> = ({ onConfirm, open, onClose, loading }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [activeTab, setActiveTab] = useState<"library" | "upload">("library");
    const [datapoint, setDatapoint] = useAtom(documentModalActiveDatapoint);
    const { setToastError, setToastSuccess } = useSetToast();
    const { isPending, mutate } = useUpdateDatapoint(datapoint?.id || "");

    const onOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            setDatapoint(undefined);
            onClose?.();
        }
    };

    const handleConfirm = async () => {
        if (onConfirm) {
            await onConfirm(
                activeTab === "library"
                    ? { fileIds }
                    : { files: newS3Files.map(newS3FileToCreateFileInput) },
            );
            onOpenChange(false);

            return;
        }

        if (!datapoint?.id) return;

        if (activeTab === "library") {
            mutate(
                {
                    id: datapoint?.id,
                    patch: { connectEvidenceFileIds: fileIds },
                },
                {
                    onSuccess: () => {
                        onOpenChange(false);
                        setToastSuccess(t(keys.success));
                    },
                    onError: () => {
                        setToastError(t(keys.error));
                    },
                },
            );
        }

        if (activeTab === "upload") {
            mutate(
                {
                    id: datapoint?.id,
                    patch: {
                        createAndConnectEvidenceFiles: newS3Files.map(
                            newS3FileToCreateFileInput,
                        ),
                    },
                },
                {
                    onSuccess: () => {
                        onOpenChange(false);
                        setToastSuccess(t(keys.success));
                    },
                    onError: () => {
                        setToastError(t(keys.error));
                    },
                },
            );
        }
    };
    const [fileIds, setFileIds] = useState<string[]>([]);
    const [newS3Files, setNewS3Files] = useState<NewS3File[]>([]);

    useEffect(() => {
        setFileIds([]);
        setNewS3Files([]);
    }, [datapoint?.id]);

    return (
        <Modal
            open={datapoint?.id !== undefined || open}
            onOpenChange={onOpenChange}
        >
            <Modal.Overlay className="py-5">
                <Modal.Content className="w-[860px]">
                    <Modal.Header className="pb-0">
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="max-h-[590px] pt-0">
                        <FlexCol gap="4" h="full">
                            <Tabs
                                activeTab={activeTab}
                                onActiveTabChange={setActiveTab}
                                className="flex-grow"
                                size="md"
                            >
                                <Tabs.Trigger
                                    value={"library"}
                                    label={t(keys.tab_library)}
                                />
                                <Tabs.Trigger
                                    value={"upload"}
                                    label={t(keys.tab_upload)}
                                />
                            </Tabs>
                            {activeTab === "library" ? (
                                <LibraryTab setFileIds={setFileIds} />
                            ) : (
                                <UploadTab
                                    newS3Files={newS3Files}
                                    setNewS3Files={setNewS3Files}
                                />
                            )}
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={isPending || loading}
                            onClick={handleConfirm}
                            preventClose
                            disabled={
                                activeTab === "library" && fileIds.length === 0
                            }
                        >
                            {t(keys.save)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
