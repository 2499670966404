import i18next from "i18next";

const enResources = {
    copy_link: "Copy link",
    success_copy: "Link copied to clipboard",
    success_delete: "Objective deleted",
    error_delete: "Error deleting objective",
    delete_objective: "Delete objective",
    entity: "Entity",
    close: "Close",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    copy_link: "Copier le lien",
    success_copy: "Lien copié dans le presse-papier",
    success_delete: "Objectif supprimé",
    error_delete: "Erreur lors de la suppression de l'objectif",
    delete_objective: "Supprimer l'objectif",
    entity: "Entité",
    close: "Fermer",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;

export const RESOURCE_NAME = "ObjectiveSideBar";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
