import i18next from "../../i18n";

i18next.addResourceBundle("en", "Users", {
    title: "Users",
    subtitle:
        "Invite your coworkers to collaborate on your Beavr account. Admins have the ability to create, edit, and delete other users",
    add_user: "Invite",
    update: {
        success: "User updated successfully",
        error: "Error updating user",
    },
    create: {
        success: "Users created successfully",
        error: "Error creating users",
    },
    delete: {
        success: "User deleted successfully",
        error: "Error deleting user",
    },
});

i18next.addResourceBundle("fr", "Users", {
    title: "Utilisateurs",
    subtitle:
        "Invitez vos collègues à collaborer sur votre compte Beavr. Les administrateurs ont la possibilité de créer, modifier et supprimer d’autres utilisateurs.",
    add_user: "Inviter",
    update: {
        success: "Utilisateur mis à jour avec succès",
        error: "Erreur lors de la mise à jour de l'utilisateur",
    },
    create: {
        success: "Utilisateurs créés avec succès",
        error: "Erreur lors de la création des utilisateurs",
    },
    delete: {
        success: "Utilisateur supprimé avec succès",
        error: "Erreur lors de la suppression de l'utilisateur",
    },
});
