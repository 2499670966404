import i18next from "@app/i18n";

const enResources = {
    trigger: "Import Questions",
    title: "Import Questions",
    placeholder: "Paste your questions here",
    description: "<li> 1. Copy to the clipboard the questions you want to import. </li><li> 2. Paste the questions in the text field below. </li><li> 3. Click on the import button.</li>",
    cancel: "Cancel",
    confirm: "Import",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources = {
    trigger: "Importer des questions",
    title: "Importer des questions",
    placeholder: "Coller vos questions ici",
    description: "<li> 1. Copiez les questions que vous souhaitez importer dans le presse-papiers. </li><li> 2. Collez les questions dans le champ de texte ci-dessous. </li><li> 3. Cliquez sur le bouton d'importation.</li>",
    cancel: "Annuler",
    confirm: "Importer",
} as const;

export const RESOURCE_NAME = "ExternalQuestionnaire";
export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
