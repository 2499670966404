import { cn, type ClassValue } from "@design-system/Utilities";
import { useMemo } from "react";

/* -------------------------------------------------------------------------------------------------
 * Dropdown Content
 * -----------------------------------------------------------------------------------------------*/
const makeDropdownContentStyles = (className?: ClassValue) => {
    return cn(
        "rounded-xl",
        "bg-primary",
        "w-[280px] max-h-[300px]",
        "p-1",
        "flex flex-col gap-0.5",
        "shadow-b-300",
        "overflow:scroll",
        "overflow-auto",
        className,
    );
};
export function useDropdownContentStyles(className?: ClassValue) {
    return useMemo(() => makeDropdownContentStyles(className), [className]);
}

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item
 * -----------------------------------------------------------------------------------------------*/
type DropdownItemVariants = {
    negative?: boolean;
    active?: boolean;
};
const makeDropdownItemStyles = (
    { negative = false, active = false }: DropdownItemVariants,
    className: ClassValue,
) => {
    return cn(
        "rounded-lg",
        "w-full min-h-[36px]",
        "box-border px-2 py-1.5",
        "flex flex-row items-center gap-2.5",
        "hover:bg-tertiary",
        "group/dropdown-item",
        "text-primary",
        "text-base",
        "cursor-pointer",
        "[&_.dropdown-item-icon]:text-brand",
        "hover:text-brand",
        active && "group/dropdown-item-active",
        negative && [
            "group/dropdown-item-negative",
            "hover:bg-tertiary hover:bg-red-100",
            "text-danger hover:text-danger",
            "[&_.dropdown-item-icon]:text-danger",
        ],
        className,
    );
};

export function useDropdownItemStyles(
    variants: DropdownItemVariants,
    className?: ClassValue,
) {
    return useMemo(
        () => makeDropdownItemStyles(variants, className),
        [variants, className],
    );
}

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Icon
 * -----------------------------------------------------------------------------------------------*/
const makeDropdownItemIconStyles = (className: ClassValue) => {
    return cn(
        "w-4 h-4",
        "m-0.5",
        "flex-shrink-0",
        "dropdown-item-icon",
        className,
    );
};
export function useDropdownItemIconStyles(className: ClassValue) {
    return useMemo(() => makeDropdownItemIconStyles(className), [className]);
}

/* -------------------------------------------------------------------------------------------------
 * Dropdown Item Text
 * -----------------------------------------------------------------------------------------------*/
export function useDropdownItemTextStyles(className?: ClassValue) {
    return useMemo(() => cn("w-full text-current", className), [className]);
}
