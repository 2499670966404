import { GraphQLCsrdRepository } from "@app/repositories/GraphQLRepositories/CsrdRepository";
import {
    type CsrdDatapointPatch,
    type CsrdDatapointsFilter,
    type MaterialityPatch,
} from "@generated/client/graphql";
import { skipToken, useMutation, useQuery } from "@tanstack/react-query";
import { csrdKeys, useCsrdDatapoint } from "../data";
import {
    computePillarStats,
    filterPillarData,
    makeCompletionStatOption,
    makeDrCompletionStats,
} from "../services";

import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLDocumentRepository } from "@app/repositories/GraphQLRepositories/DocumentRepository";
import { GraphQLIndicatorRepository } from "@app/repositories/GraphQLRepositories/indicator/IndicatorRepository";
import { GraphQLS3FileRepository } from "@app/repositories/GraphQLRepositories/S3FileRepository";
import { tanstackUnitaryOptimisticUpdateOptionsMaker } from "@app/shared/utils/optimisticUpdates";
import { usersAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { filterAtom } from "./ctx";
import {
    getPeriodsOfAssociatedReportingDatapoints,
    makeAssociatedReportingDatapointGroups,
    makeAssociatedReportingEntities,
    makeDisclosureRequirementUpdateMaterialityCacheCb,
    makeUpdateDatapointCache,
    makeUpdateDisclosureRequirementOwnerIdsCacheCb,
    makeUpdateEsrsOwnerIdsCacheCb,
} from "./services";
import { PillarDatapoint } from "./types";

export const csrdRepository = new GraphQLCsrdRepository();
const S3FileRepository = new GraphQLS3FileRepository();
const DocumentRepository = new GraphQLDocumentRepository();
const IndicatorRepository = new GraphQLIndicatorRepository();

export const csrdPillarKeys = {
    all: [...csrdKeys.all, "pillar"] as const,
    details: () => [...csrdPillarKeys.all, "details"] as const,
    detail: (pillarId?: string, filter?: CsrdDatapointsFilter) =>
        [...csrdPillarKeys.details(), pillarId, filter] as const,
    updateDatapoint: (datapointId: string | undefined) =>
        [...csrdPillarKeys.all, "updateDatapoint", datapointId] as const,
    updateDisclosureRequirement: (drId: string | undefined) =>
        [...csrdPillarKeys.all, "updateDisclosureRequirement", drId] as const,
    updateEsrs: (esrsId?: string) =>
        [...csrdPillarKeys.all, "updateEsrs", esrsId] as const,
    getSuggestedFiles: (datapointId: string) =>
        [...csrdPillarKeys.all, "getSuggestedFiles", datapointId] as const,
};

/**
 * This hook is used to fetch the associated reporting datapoints of a given csrd datapoint.
 *
 * @param {string} id - The id of the csrd datapoint.
 * @returns {Object} An object containing the associated reporting datapoints and the rest of the query.
 * @property {Array} associatedReportingDatapoints - The associated reporting datapoints of the csrd datapoint.
 * @property {Object} query - The rest of the query.
 */
export function useAssociatedReportingDatapoints(id?: string) {
    const { datapoint, ...query } = useCsrdDatapoint(id);
    const associatedReportingDatapoints =
        datapoint?.associatedReportingDatapoints ?? [];
    return { associatedReportingDatapoints, ...query };
}

/**
 * This hook is used to fetch the associated reporting datapoint groups of a given csrd datapoint. They are formated as an array of datapointGroups and as a record of datapointGroups by entity id. The datapointGroups have a datapointId field with the id of the reporting datapoint they belong to.
 *
 * @param {string} id - The id of the csrd datapoint.
 * @returns {Object} An object containing the associated reporting datapoint groups and the rest of the query.
 * @property {Array} associatedReportingDatapointGroups - The associated reporting datapoint groups of the csrd datapoint.
 * @property {Object} query - The rest of the query.
 */
export function useAssociatedReportingDatapointGroups(id?: string) {
    const { associatedReportingDatapoints, ...query } =
        useAssociatedReportingDatapoints(id);
    const associatedReportingDatapointGroups = useMemo(
        () =>
            makeAssociatedReportingDatapointGroups(
                associatedReportingDatapoints,
            ),
        [associatedReportingDatapoints],
    );

    return {
        associatedReportingDatapointGroups,
        ...query,
    };
}

/**
 * This hook is used to fetch the associated reporting datapoint entities of a given csrd datapoint.
 *
 * @param {string} id - The id of the csrd datapoint.
 * @returns {Object} An object containing the associated reporting datapoint entities and the rest of the query.
 * @property {Array} associatedReportingDatapointEntities - The associated reporting datapoint entities of the csrd datapoint.
 * @property {Object} query - The rest of the query.
 */
export function useAssociatedReportingDatapointEntities(id?: string) {
    const { associatedReportingDatapointGroups, ...query } =
        useAssociatedReportingDatapointGroups(id);

    const associatedReportingDatapointEntities = useMemo(() => {
        return makeAssociatedReportingEntities(
            associatedReportingDatapointGroups.byEntity,
        );
    }, [associatedReportingDatapointGroups]);

    return { associatedReportingDatapointEntities, ...query };
}

/**
 * This hook is used to fetch the associated reporting datapoint periods of a given csrd datapoint and a specific entity, ordered by years, as well as the years the periods are grouped in.
 *
 * @param {string} id - The id of the csrd datapoint.
 * @param {string} entityId - The id of the entity.
 * @returns {Object} An object containing the associated reporting datapoint periods and the rest of the query.
 * @property {Array} associatedReportingDatapointPeriods - The associated reporting datapoint periods of the csrd datapoint.
 * @property {Object} query - The rest of the query.
 * @see getPeriodsOfAssociatedReportingDatapoints
 * @see useAssociatedReportingDatapointGroups
 * @see useAssociatedReportingDatapoints
 * @see useAssociatedReportingDatapointEntities
 * @see useCsrdDatapoint
 */
export function useAssociatedReportingDatapointPeriodsByEntity(
    id: string,
    entityId?: string,
) {
    const { associatedReportingDatapointGroups, ...query } =
        useAssociatedReportingDatapointGroups(id);

    const periods = useMemo(
        () =>
            getPeriodsOfAssociatedReportingDatapoints(
                entityId,
                associatedReportingDatapointGroups,
            ),
        [entityId, associatedReportingDatapointGroups],
    );

    return { associatedReportingDatapointPeriods: periods, ...query };
}

export function useCsrdPillar(csmId?: string, filter?: CsrdDatapointsFilter) {
    const { data, ...query } = useQuery({
        queryKey: csrdPillarKeys.detail(csmId, filter),
        queryFn:
            csmId && filter !== null
                ? () => csrdRepository.getPillar(csmId, filter)
                : skipToken,
    });

    const filteredPillar = data?.pillar
        ? filterPillarData(data?.pillar)
        : undefined;
    return {
        ...query,
        pillar: data?.pillar,
        filteredPillar,
    };
}

export function useCsrdPillarStats(
    csmId?: string,
    filter?: CsrdDatapointsFilter,
) {
    const { pillar, ...data } = useCsrdPillar(csmId, filter);

    return {
        stats: pillar
            ? makeCompletionStatOption(computePillarStats(pillar))
            : undefined,
        ...data,
        disclosureRequirementStats: pillar?.esrss
            ? makeDrCompletionStats(pillar.esrss)
            : undefined,
    };
}

export function useEsrs(
    csmId?: string,
    pillarId?: string,
    filter?: CsrdDatapointsFilter,
) {
    const { pillar, isLoading } = useCsrdPillar(pillarId, filter);

    const esrs = useMemo(
        () => pillar?.esrss.find((esrs) => esrs.cmsId === csmId),
        [pillar, csmId],
    );

    return { esrs, isLoading };
}

export function useUpdateDatapoint(datapointId: string) {
    const { pillar_id } = useParams<{ pillar_id: string }>();
    const filter = useAtomValue(filterAtom);
    const { data: usersData } = useAtomValue(usersAtom);

    return useMutation({
        mutationKey: csrdPillarKeys.updateDatapoint(datapointId),
        mutationFn: async ({
            id,
            patch,
        }: {
            id: string;
            patch: CsrdDatapointPatch;
        }) => {
            await csrdRepository.updateDatapoint({
                id,
                patch,
            });
        },
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => csrdPillarKeys.detail(pillar_id, filter),
            makeUpdateDatapointCache(
                () => datapointId,
                () => usersData,
            ),
        ),

        onSettled() {
            queryClient.invalidateQueries({
                queryKey: csrdPillarKeys.detail(pillar_id, filter),
            });
        },
    });
}

export function useGenerateAiSuggestion() {
    const { pillar_id } = useParams<{ pillar_id: string }>();
    const filter = useAtomValue(filterAtom);
    return useMutation({
        mutationFn: csrdRepository.generateAiSuggestion,
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: csrdPillarKeys.detail(pillar_id, filter),
            });
        },
    });
}

export function useUpdateEsrs(id: string) {
    const { pillar_id } = useParams<{ pillar_id: string }>();
    const filter = useAtomValue(filterAtom);
    const { data: usersData } = useAtomValue(usersAtom);

    return useMutation({
        mutationKey: csrdPillarKeys.updateEsrs(id),
        mutationFn: async (selectedUsers: string[]) => {
            const ownerId = selectedUsers[0];
            await csrdRepository.updateEsrs(id, {
                ownerId: ownerId ?? null,
            });
        },
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => csrdPillarKeys.detail(pillar_id, filter),
            makeUpdateEsrsOwnerIdsCacheCb(
                () => id,
                () => usersData,
            ),
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: csrdPillarKeys.all,
            });
        },
    });
}

export function useUpdateDisclosureRequirementOwner(id: string) {
    const { pillar_id } = useParams<{ pillar_id: string }>();
    const filter = useAtomValue(filterAtom);
    const { data: usersData } = useAtomValue(usersAtom);

    return useMutation({
        mutationKey: csrdPillarKeys.updateDisclosureRequirement(id),
        mutationFn: async (selectedUsers: string[]) => {
            const ownerId = selectedUsers[0] ?? null;
            await csrdRepository.updateDisclosureRequirement({
                id,
                patch: { ownerId },
            });
        },
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => csrdPillarKeys.detail(pillar_id, filter),
            makeUpdateDisclosureRequirementOwnerIdsCacheCb(
                () => id,
                () => usersData,
            ),
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: csrdPillarKeys.all,
            });
        },
    });
}

export function useUpdateDisclosureRequirementMateriality(id?: string) {
    const { pillar_id } = useParams<{ pillar_id: string }>();
    const filter = useAtomValue(filterAtom);

    return useMutation({
        mutationKey: csrdPillarKeys.updateDisclosureRequirement(id),
        mutationFn: async (materialityPatch: MaterialityPatch) => {
            if (!id) {
                throw new Error("No id provided");
            }
            await csrdRepository.updateDisclosureRequirement({
                id,
                patch: { materialityPatch },
            });
        },
        ...tanstackUnitaryOptimisticUpdateOptionsMaker(
            () => csrdPillarKeys.detail(pillar_id, filter),
            makeDisclosureRequirementUpdateMaterialityCacheCb(() => id),
        ),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: csrdPillarKeys.all,
            });
        },
    });
}

export function useGetSuggestedFiles(datapoint?: PillarDatapoint) {
    const { data, ...query } = useQuery({
        queryKey: csrdPillarKeys.getSuggestedFiles(datapoint?.id || ""),
        queryFn: !!datapoint?.id
            ? () =>
                  S3FileRepository.getSuggestedFiles({
                      csrdDatapointId: datapoint?.id,
                  })
            : skipToken,
    });
    return {
        ...query,
        suggestedFiles:
            data?.suggestedFiles
                .map((elt) => elt.file)
                .filter(
                    (elt) =>
                        !datapoint?.evidenceFiles
                            ?.map((file) => file.id)
                            .includes(elt.id),
                ) ?? [],
    };
}

export function useGetEvidenceFiles() {
    const { data, ...query } = useQuery({
        queryKey: ["getEvidenceFiles"],
        queryFn: () => S3FileRepository.getEvidenceFiles(),
    });
    return {
        ...query,
        evidenceFiles: data?.evidenceFiles ?? [],
    };
}

export function useGetDocuments(documentIds: string[]) {
    const { data, ...query } = useQuery({
        queryKey: ["getDocuments", documentIds],
        queryFn: () => DocumentRepository.getDocumentsByIds(documentIds),
    });

    return {
        ...query,
        documents: data?.documents ?? [],
    };
}

export function useGetIndicators(indicatorIds: string[]) {
    const { data, ...query } = useQuery({
        queryKey: ["getIndicators", indicatorIds],
        queryFn: () => IndicatorRepository.getIndicatorsByIds(indicatorIds),
    });

    return {
        ...query,
        indicators: data?.indicators2 ?? [],
    };
}
