import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { Calendar } from "@design-system/Inputs/Calendar";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { cn } from "@design-system/Utilities";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCreateExternalQuestionnaire } from "../../data";
import { keys, RESOURCE_NAME } from "../../i18n";

const StartFromScratch = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [companyName, setCompanyName] = useState<string>();
    const [deadline, setDeadline] = useState<Date>();

    const makeEventHandler =
        (setter: Dispatch<SetStateAction<string | undefined>>) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setter(e.target.value);
        };
    const { create, isPending } = useCreateExternalQuestionnaire();

    const canConfirm = !!name?.length;

    const handleConfirm = () => {
        if (!name) return;
        create({
            name,
            requesterEmail: email,
            companyName,
            deadline,
        });
        setName(undefined);
        setEmail(undefined);
        setCompanyName(undefined);
        setDeadline(undefined);
    };

    return (
        <FlexCol className="gap-2">
            <p>Start from scratch and add your questions later</p>
            <Textfield
                placeholder={t(
                    keys.create_questionnaire_modal_name_placeholder,
                )}
                value={name}
                onChange={makeEventHandler(setName)}
            />
            <Textfield
                placeholder={t(
                    keys.create_questionnaire_modal_requester_email_placeholder,
                )}
                value={email}
                onChange={makeEventHandler(setEmail)}
            />
            <Textfield
                placeholder={t(
                    keys.create_questionnaire_modal_company_name_placeholder,
                )}
                value={companyName}
                onChange={makeEventHandler(setCompanyName)}
            />
            <Popover>
                <PopoverTrigger
                    className="w-full flex items-stretch justify-stretch"
                    asChild
                >
                    <Button
                        variant={"outlined"}
                        className={cn(
                            "w-full",
                            "px-4",
                            "font-normal text-primary",
                            // !deadline &&
                            //     "text-muted-foreground",
                        )}
                    >
                        {deadline ? (
                            format(deadline, "PPP")
                        ) : (
                            <span className="text-secondary">
                                Set a deadline
                            </span>
                        )}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    align="start"
                    alignOffset={-2}
                    sideOffset={9}
                    className="absolute transform -translate-x-3 shadow-lg bg-white"
                >
                    <Calendar
                        mode="single"
                        defaultMonth={
                            deadline ? new Date(deadline) : new Date()
                        }
                        selected={deadline}
                        onSelect={setDeadline}
                        className="rounded-md border"
                    />
                </PopoverContent>
            </Popover>

            <Tooltip>
                <Tooltip.Trigger asChild>
                    {/* This Box below is necessary because the tooltip doesn't open when the button is
                     * disabled but we want to display the tooltip just when the button is disabled
                     */}

                    <Box className="w-fit self-end">
                        <Modal.ConfirmCTA
                            onClick={handleConfirm}
                            disabled={!canConfirm}
                            loading={isPending}
                        >
                            {t(keys.create_questionnaire_modal_confirm_label)}
                        </Modal.ConfirmCTA>
                    </Box>
                </Tooltip.Trigger>
                {!canConfirm && (
                    <Tooltip.Content>
                        {t(keys.create_questionnaire_modal_tooltip_no_name)}
                    </Tooltip.Content>
                )}
            </Tooltip>
        </FlexCol>
    );
};

export default StartFromScratch;
