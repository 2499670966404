import i18next from "@app/i18n";

const enResources = {
    toast_success: "Objective created successfully",
    toast_error_entity: "Please select an entity",
    toast_error_theme: "Please select a theme",
    toast_error_unknown: "Failed to create objective",
    title: "Create an objective",
    title_theme:
        "Attach an indicator to measure and track progress towards this objective, if relevant.",
    unit: "Unit",
    indicator: "Indicator",
    select_theme: "Select theme",
    subtheme: "Subtheme",
    cancel_button: "Cancel",
    confirm_button: "Confirm indicator",
    confirm_button_skip: "Continue without indicator",
    title_indicator_no_results: "No indicators found",
    no_indicator_found_subtitle: "Try changing theme or search input",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    toast_success: "Objectif créé avec succès",
    toast_error_entity: "Veuillez sélectionner une entité",
    toast_error_theme: "Veuillez sélectionner un thème",
    toast_error_unknown: "Impossible de créer l'objectif",
    title: "Créer un objectif",
    title_theme:
        "Attachez un indicateur pour mesurer et suivre la progression vers cet objectif, si pertinent.",
    unit: "Unité",
    indicator: "Indicateur",
    select_theme: "Sélectionner un thème",
    subtheme: "Sous-thème",
    cancel_button: "Annuler",
    confirm_button: "Créer avec un indicateur",
    confirm_button_skip: "Continuer sans indicateur",
    title_indicator_no_results: "Aucun indicateur trouvé",
    no_indicator_found_subtitle:
        "Essayez de changer le thème ou le champ de recherche",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "CreateObjectiveModal";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
