import { useFeatureFlagEnabled } from "posthog-js/react";
import { useParams } from "react-router-dom";

export const useFeatureSwitch = (
    featureFlag: string,
    { devOrgUname }: { devOrgUname?: string } = {},
) => {
    const { org_uname } = useParams();
    if (devOrgUname && org_uname === devOrgUname) {
        return true;
    }

    return useFeatureFlagEnabled(featureFlag);
};
