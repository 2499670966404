import { SubthemeTag } from "@app/shared/components/Theme/SubthemeTag";
import { normalizeString } from "@app/utils/string";
import { Radio } from "@design-system/Inputs/Radio";
import { FlexRow } from "@design-system/Layout/Flex";
import { compareBasic } from "@design-system/Table";
import { Text } from "@design-system/Typography/Text";
import { ColumnDef } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { selectedIndicatorIdAtom } from "./ctx";
import { RESOURCE_NAME, keys } from "./i18n";
import { Indicator } from "./types";

const actionCol: ColumnDef<Indicator> = {
    id: "select",
    meta: {
        colWidth: "50px",
        headerClassName: "min-w-[36px] max-w-[36px] border-r-0 gap-3 px-2",
        cellClassName:
            "min-w-[36px] max-w-[36px] group-hover/tablerow:bg-secondary border-r-0 gap-3 pl-4 pr-0",
    },
    header: () => {
        return "";
    },
    cell: ({ row }) => {
        const [selectedIndicatorId, setSelectedIndicatorId] = useAtom(
            selectedIndicatorIdAtom,
        );
        const handleRadioClick = () => {
            if (selectedIndicatorId === row.original.id) {
                setSelectedIndicatorId(undefined);
                return;
            }
            setSelectedIndicatorId(row.original.id);
        };
        return (
            <FlexRow
                alignItems="center"
                justifyContent="center"
                h="full"
                className="cursor-pointer hover:underline"
                onClick={handleRadioClick}
            >
                <Radio checked={selectedIndicatorId === row.original.id} />
            </FlexRow>
        );
    },
};

const nameCol: ColumnDef<Indicator> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "400px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.indicator);
    },
    cell: ({ row }) => {
        return (
            <FlexRow alignItems="center" justifyContent="start" h="full">
                <Text variant="body-sm">{row.original.name}</Text>
            </FlexRow>
        );
    },
};

const unitCol: ColumnDef<Indicator> = {
    id: "unit",
    meta: {
        colWidth: "120px",
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.unit);
    },
    cell: ({ row }) => {
        return (
            <Text variant="body-sm">{row.original.unit?.shortName || ""}</Text>
        );
    },
};

const subthemeCol: ColumnDef<Indicator> = {
    id: "subtheme",
    accessorKey: "subthemeId",
    filterFn: "arrIncludesSome",
    sortingFn: (rowA, rowB) => {
        const subthemeA = rowA.original.subtheme?.name ?? "";
        const subthemeB = rowB.original.subtheme?.name ?? "";

        return compareBasic(
            normalizeString(subthemeA),
            normalizeString(subthemeB),
        );
    },
    header: () => {
        const { t } = useTranslation(RESOURCE_NAME);
        return t(keys.subtheme);
    },
    cell: ({ row }) => {
        const subThemeId = row.original.subtheme?.id;

        return (
            <FlexRow className="max-w-fit">
                <SubthemeTag
                    themeId={row.original.subtheme?.theme?.id ?? ""}
                    subthemeId={subThemeId ?? undefined}
                    className="bg-secondary"
                    isTrigger
                    disabled
                />
            </FlexRow>
        );
    },
};

export const useColumns = (): ColumnDef<Indicator>[] => {
    const columns = [actionCol, nameCol, unitCol, subthemeCol];
    return columns;
};
