import {
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useState } from "react";
import { columns } from "./columns";

import { UserCrown } from "iconoir-react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { User } from "../../../../../generated/client/graphql";
import SearchFilter, {
    searchFilterStringAtom,
} from "../../../../components/Filters/Search";
import DataTable from "../../../../components/Table/DataTable";
import EmptyTable from "../../../../components/Table/EmptyTable";
import LoadingTable from "../../../../components/Table/LoadingTable";
import { useUsers } from "../../data";
import { newUsersModalOpenAtom } from "../NewUserModal";
import UpdateUserModal, { toUpdateUserIdAtom } from "../UpdateUserModal";
import "./i18n";

export function UsersTableInner({
    users,
    supplierMode,
}: {
    users: User[];
    supplierMode?: boolean;
}) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [searchString, setSearchString] = useAtom(searchFilterStringAtom);

    const table = useReactTable<User>({
        data: users,
        columns: columns(supplierMode),
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: setSearchString,
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            globalFilter: searchString,
        },
    });

    const toUpdateUserId = useAtomValue(toUpdateUserIdAtom);
    const toUpdateUser = users.find((u) => u.id === toUpdateUserId);
    return (
        <>
            <div className="space-y-4">
                <SearchFilter
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
                <DataTable table={table} />
            </div>
            <UpdateUserModal user={toUpdateUser as User} />
        </>
    );
}

const UsersTable = () => {
    const { data, isLoading } = useUsers();

    const setCreateModalOpen = useSetAtom(newUsersModalOpenAtom);
    const { t } = useTranslation("UsersTable");

    return !data && isLoading ? (
        <LoadingTable />
    ) : data?.users.length === 0 ? (
        <EmptyTable
            Icon={UserCrown}
            smallText={t("empty_table_small_text")}
            buttonText={t("empty_table_button_text")}
            onClick={() => setCreateModalOpen(true)}
        />
    ) : data?.users ? (
        <UsersTableInner users={data.users as User[]} />
    ) : (
        <div>Error, refresh ...</div>
    );
};

export default UsersTable;
