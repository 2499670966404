import { useSetToast } from "@app/components/Toast";
import { ResponseStatus } from "@generated/client/graphql";

export const useHandleMutationToasts = () => {
    const { setToastSuccess, setToastError } = useSetToast();

    return (
        data:
            | { status?: ResponseStatus; error?: { message?: string } | null }
            | null
            | undefined,
        {
            successMessage,
            defaultErrorMessage,
        }: {
            successMessage: string;
            defaultErrorMessage: string;
        },
    ) => {
        if (data?.status === ResponseStatus.Error) {
            setToastError(data.error?.message ?? defaultErrorMessage);
        } else if (data?.status === ResponseStatus.Success) {
            setToastSuccess(successMessage);
        }
    };
};
