import { graphql } from "@generated/client";
import { CreateUserInput, UserPatch } from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

const GetUsersQuery = graphql(`
    query GetUsers {
        users {
            id
            firstName
            lastName
            initials
            department
            avatar {
                signedUrl
            }
            email
        }
    }
`);

const GetUsersDetailsQuery = graphql(`
    query GetUsersDetails {
        users {
            id
            firstName
            lastName
            email
            clerkUser {
                lastLogin
                emailVerified
            }
            permission {
                role
            }
            department
            createdAt
            initials
            avatar {
                signedUrl
            }
        }
    }
`);

const GetCurrentUserQuery = graphql(`
    query GetCurrentUser {
        currentUser {
            id
            firstName
            lastName
            email
            initials
            locale
            avatar {
                signedUrl
            }
            clerkUser {
                id
            }
            createdAt
            permission {
                role
                tenant {
                    id
                    name
                    uname
                }
            }
        }
    }
`);

const GetCurrentUserNoTenantQuery = graphql(`
    query GetCurrentUserNoTenant {
        currentUserNoTenant {
            id
            type
        }
    }
`);

const GetUsersWithReportingStatsQuery = graphql(`
    query GetUsersWithReportingStats {
        users {
            id
            firstName
            lastName
            initials
            avatar {
                signedUrl
            }
            email
            reportingStats {
                datapointGroupId
                assignedDatapoints
                answeredDatapoints
            }
        }
    }
`);

const GetCurrentUserOrgsWithCustomizationQuery = graphql(`
    query GetCurrentUserOrgsWithCustomization {
        orgs {
            data {
                type
                tenantId
                tenant {
                    id
                    uname
                    name
                }
                userPermissions {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                }
                customization {
                    logo {
                        id
                        signedUrl
                        name
                        fileSize
                    }
                    hexColor
                }
            }
        }
    }
`);

const GetUserQuery = graphql(`
    query GetUser($id: String!) {
        user(id: $id) {
            firstName
            lastName
            avatar {
                signedUrl
            }
        }
    }
`);

const UpdateUserMutation = graphql(`
    mutation UpdateUser($id: String!, $set: UserPatch!) {
        updateUser(id: $id, set: $set) {
            id
        }
    }
`);

const CreateUsersMutation = graphql(`
    mutation CreateUsers($inputs: [CreateUserInput!]!) {
        createUsers(inputs: $inputs) {
            id
        }
    }
`);

const DeleteUserMutation = graphql(`
    mutation DeleteUser($id: String!) {
        deleteUser(id: $id) {
            id
        }
    }
`);

export class GraphQLUserRepository {
    get = async () => {
        return graphqlClientKoyeb.request(
            GetUsersQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getDetails = async () => {
        return graphqlClientKoyeb.request(
            GetUsersDetailsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    getUser = async (id: string) => {
        return graphqlClientKoyeb.request(
            GetUserQuery,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
    getCurrentUser = async () =>
        graphqlClientKoyeb.request(
            GetCurrentUserQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    getCurrentUserNoTenant = async () => {
        const headers = await AccessHeadersHolder.getHeaders();
        return graphqlClientKoyeb.request(
            GetCurrentUserNoTenantQuery,
            undefined,
            headers,
        );
    };

    getWithReportingStats = async () => {
        return graphqlClientKoyeb.request(
            GetUsersWithReportingStatsQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };
    getCurrentUserOrgs = async () => {
        return graphqlClientKoyeb.request(
            GetCurrentUserOrgsWithCustomizationQuery,
            undefined,
            await AccessHeadersHolder.getHeaders(),
        );
    };

    updateUser = async (id: string, set: UserPatch) => {
        return graphqlClientKoyeb.request(
            UpdateUserMutation,
            { id, set },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    createUsers = async (inputs: CreateUserInput[]) => {
        return graphqlClientKoyeb.request(
            CreateUsersMutation,
            { inputs },
            await AccessHeadersHolder.getHeaders(),
        );
    };

    deleteUser = async (id: string) => {
        return graphqlClientKoyeb.request(
            DeleteUserMutation,
            { id },
            await AccessHeadersHolder.getHeaders(),
        );
    };
}
