import i18next from "@app/i18n";

const enResources = {
    page_title: "Questionnaires",
    page_subtitle: "Manage your company’s external questionnaires",
    table_header_name: "Name",
    table_header_requester: "Requester's email",
    table_header_company: "Company",
    table_header_progress: "Progress",
    table_header_deadline: "Deadline",
    create_questionnaire_modal_title: "Create questionnaire",
    create_questionnaire_modal_trigger_label: "New questionnaire",
    create_questionnaire_modal_cancel_label: "Cancel",
    create_questionnaire_modal_confirm_label: "Create",
    create_questionnaire_modal_tooltip_no_name: "You must add a name",
    create_questionnaire_modal_name_placeholder: "Questionnaire name",
    create_questionnaire_modal_requester_email_placeholder: "Requester email",
    create_questionnaire_modal_company_name_placeholder: "Company name",
    create_questionnaire_modal_deadline_placeholder: "Deadline",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    page_title: "Questionnaires externes",
    page_subtitle: "Gérez les questionnaires externes de votre entreprise",
    table_header_name: "Nom",
    table_header_requester: "Email du demandeur",
    table_header_company: "Entreprise",
    table_header_progress: "Progression",
    table_header_deadline: "Date limite",
    create_questionnaire_modal_title: "Créer un questionnaire",
    create_questionnaire_modal_trigger_label: "Nouveau questionnaire",
    create_questionnaire_modal_cancel_label: "Annuler",
    create_questionnaire_modal_confirm_label: "Créer",
    create_questionnaire_modal_tooltip_no_name: "Vous devez ajouter un nom",
    create_questionnaire_modal_name_placeholder: "Nom du questionnaire",
    create_questionnaire_modal_requester_email_placeholder:
        "Email du demandeur",
    create_questionnaire_modal_company_name_placeholder: "Nom de l'entreprise",
    create_questionnaire_modal_deadline_placeholder: "Date limite",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourcesKeys, ResourcesKeys>;
export const RESOURCE_NAME = "ExternalQuestionnaires";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
