import { adminPaths } from "@app/routing/paths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { useEffect } from "react";
import { useExternalQuestionnaires } from "../../data";
import { useExternalQuestionnaire } from "../data";

export function useExternalQuestionnaireBreadcrumb(id?: string) {
    const { externalQuestionnaire } = useExternalQuestionnaire(id);
    const { externalQuestionnaires } = useExternalQuestionnaires();
    const setBreadcrumb = useBreadcrumb();

    useEffect(() => {
        const options = externalQuestionnaires.map((q) => ({
                name: q.name,
                param: q.id,
            })) ?? [];

        setBreadcrumb(
            adminPaths.external_questionnaire_id_param,
            externalQuestionnaire?.name ?? "...",
            options,
        );
    }, [externalQuestionnaire, externalQuestionnaires, setBreadcrumb]);
}
