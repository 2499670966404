import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLVersionRepository } from "@app/repositories/GraphQLRepositories/VersionRepository";
import { documentURLAtom } from "@app/store/documentStore";
import { versionKeys } from "@app/store/queryKeys";
import { DocumentVersionStatus } from "@generated/client/graphql";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai/react";

const versionRepository = new GraphQLVersionRepository();

export function useUnsendReviewRequest() {
    const documentUrl = useAtomValue(documentURLAtom);

    return useMutation({
        mutationFn: (id: string) =>
            versionRepository.update({
                id,
                patch: {
                    status: DocumentVersionStatus.Draft,
                },
            }),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [versionKeys.versionByDocumentURL, { documentUrl }],
            });
        },
    });
}

export function useUpdateLastCommentedAt(versionId?: string) {
    return useMutation({
        mutationFn: () => {
            if (!versionId) {
                throw new Error("Version ID is required");
            }
            return versionRepository.update({
                id: versionId,
                patch: { lastCommentedAt: new Date() },
            });
        },
    });
}

export function useCreateVersionFromPrevious() {
    const client = useQueryClient();
    const { mutate, ...mutation } = useMutation({
        mutationFn: (documentId: string) => {
            return versionRepository.create({
                documentId,
                input: { createFromPreviousVersion: true },
            });
        },
        onSuccess() {
            client.invalidateQueries({
                queryKey: [versionKeys.versionByDocumentURL],
            });
        },
    });

    return { create: mutate, ...mutation };
}
