import { useCreateVersionFromPrevious } from "@app/screens/Document/Edit/data";
import { allowNestedClickWithCallback } from "@app/shared/utils/allowNestedClickWithCallback";
import { ColorTag } from "@design-system/DataDisplay/Tag/ColorTag";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { Dropdown } from "@design-system/Inputs/UniversalDropdown";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { cn } from "@design-system/Utilities";
import { DefinedQueryObserverResult } from "@tanstack/react-query";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    DeleteDraftDocument,
    DocumentVersion,
    DocumentVersionStatus,
    GetDocumentByUrlQuery,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import InnerLayout from "../../../../components/Layouts/FullFrame/Block/InnerLayout";
import { VersionBlockIllustration } from "./VersionBlockIllustration";
import { VersionInfoTooltip } from "./VersionInfoTooltip";
import "./i18n";
import { Text } from "@design-system/Typography/Text";
import { Box } from "@design-system/Layout/Box";

const VersionBlock = ({
    documentId,
    version,
    getDocumentQuery,
    isLatest,
    isMostRecentlyApprovedVersion,
}: {
    documentId: string;
    version: DocumentVersion;
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
    isLatest: boolean;
    isMostRecentlyApprovedVersion: boolean;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation("DocumentVersionBlock");
    dayjs.extend(LocalizedFormat);
    const isUploadedFile = !version.withEditor;
    const isDraft = version.status !== DocumentVersionStatus.Approved;

    const signedUrl = version.finalFile?.signedUrl;

    const openFile = allowNestedClickWithCallback(() => {
        if (!signedUrl) return;
        window.open(signedUrl, "_blank")?.focus();
    });

    const deleteMutation = useGraphQLMutation(DeleteDraftDocument);
    const deleteVersion = allowNestedClickWithCallback(() => {
        deleteMutation.mutate(
            {
                versionId: version.id,
            },
            { onSuccess: () => getDocumentQuery.refetch() },
        );
    });
    const createFromPreviousMutation = useCreateVersionFromPrevious();
    const createFromPrevious = allowNestedClickWithCallback(() => {
        if (!documentId) return;
        createFromPreviousMutation.create(documentId, {
            onSuccess: () => {
                getDocumentQuery.refetch();
            },
        });
    });

    const handleBlockClick = () => {
        if (!isLatest) return;
        navigate("edit");
    };
    return (
        <div className="group">
            <FullFrameBlock
                className={cn(
                    isLatest || isMostRecentlyApprovedVersion
                        ? "hover:bg-secondary hover:outline hover:outline-green hover:outline-1 hover:border-green cursor-pointer"
                        : "",
                )}
                onClick={handleBlockClick}
            >
                <InnerLayout className="p-4 flex space-x-5">
                    <Box className="grid place-items-center">
                        <VersionBlockIllustration
                            extension={
                                isUploadedFile
                                    ? version.finalFile?.extension
                                    : null
                            }
                            status={version.status}
                        />
                    </Box>
                    <div
                        className={cn(
                            "flex-grow space-y-1 text-primary",
                            !isMostRecentlyApprovedVersion &&
                                !isDraft &&
                                "text-secondary",
                        )}
                    >
                        <FlexRow justifyContent="between">
                            <div className="pt-2 text-lg font-bold flex items-center gap-1.5">
                                <span>
                                    {dayjs(
                                        version.approvedAt ||
                                            version.lastEditedAt,
                                    ).format("LL")}{" "}
                                    {version.status ===
                                        DocumentVersionStatus.Draft &&
                                        t("draft")}
                                </span>
                                {isUploadedFile && version.finalFile?.name && (
                                    <VersionInfoTooltip
                                        tooltip={version.finalFile.name}
                                    />
                                )}
                                {isMostRecentlyApprovedVersion && (
                                    <Tooltip delayDuration={0}>
                                        <Tooltip.Trigger>
                                            <Icon name="lock" size="sm" />
                                        </Tooltip.Trigger>
                                        <Tooltip.Content>
                                            {version.withEditor
                                                ? t(
                                                      "cannot_edit_approved_version_with_editor",
                                                  )
                                                : t(
                                                      "cannot_edit_approved_version_pdf_only",
                                                  )}
                                        </Tooltip.Content>
                                    </Tooltip>
                                )}
                                {!isDraft && !isMostRecentlyApprovedVersion && (
                                    <ColorTag
                                        color="grey"
                                        text={t("archived")}
                                    />
                                )}
                            </div>
                            {!isDraft && (
                                <FlexCol>
                                    <FlexRow gap="0.5">
                                        {isMostRecentlyApprovedVersion &&
                                            version.withEditor && (
                                                <Tooltip>
                                                    <Tooltip.Trigger>
                                                        <Button
                                                            loading={
                                                                createFromPreviousMutation.isPending
                                                            }
                                                            onClick={
                                                                createFromPrevious
                                                            }
                                                            size="sm"
                                                            variant="text"
                                                        >
                                                            <Button.Icon name="duplicate" />
                                                        </Button>
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Content>
                                                        {t("duplicate")}
                                                    </Tooltip.Content>
                                                </Tooltip>
                                            )}
                                        <Tooltip>
                                            <Tooltip.Trigger asChild>
                                                <Box>
                                                    <Button
                                                        disabled={!signedUrl}
                                                        onClick={openFile}
                                                        size="sm"
                                                        variant="text"
                                                    >
                                                        <Button.Icon name="download" />
                                                    </Button>
                                                </Box>
                                            </Tooltip.Trigger>
                                            <Tooltip.Content>
                                                {signedUrl
                                                    ? t("download")
                                                    : t("cannot_download_file")}
                                            </Tooltip.Content>
                                        </Tooltip>
                                    </FlexRow>
                                </FlexCol>
                            )}
                        </FlexRow>
                        <FlexCol>
                            <div className="flex items-center text-sm font-regular space-x-1.5">
                                <span>{t("last_edited_by")}</span>
                                <span>
                                    {version.lastEditedBy?.firstName}{" "}
                                    {version.lastEditedBy?.lastName[0]}.
                                </span>
                                <svg width={4} height={4}>
                                    <ellipse
                                        cx="2"
                                        cy="2"
                                        rx="2"
                                        ry="2"
                                        className="fill-neutral-500"
                                    />
                                </svg>
                                <span>
                                    {t("last_edit_time", {
                                        date: dayjs(
                                            version.lastEditedAt,
                                        ).format("LL"),
                                        time: dayjs(
                                            version.lastEditedAt,
                                        ).format("LT"),
                                    })}
                                </span>
                            </div>
                            {version.approvedBy && (
                                <Text
                                    className="flex items-center gap-1"
                                    variant="body-sm"
                                    mt="1"
                                >
                                    <Icon
                                        className="text-green"
                                        name="check"
                                        size="xs"
                                    />
                                    {t("approved_by", {
                                        approver: `${version.approvedBy?.firstName} ${version.approvedBy?.lastName[0]}.`,
                                    })}
                                </Text>
                            )}
                        </FlexCol>
                    </div>
                    {version.status !== DocumentVersionStatus.Approved && (
                        <div
                            className="flex space-x-2"
                            onClick={allowNestedClickWithCallback(() => {})}
                        >
                            <Dropdown>
                                <Dropdown.Trigger asChild>
                                    <Button size="sm" variant="tonal">
                                        <Button.Icon name="more" />
                                    </Button>
                                </Dropdown.Trigger>
                                <Dropdown.Content hasPortal>
                                    {version.finalFile && (
                                        <Dropdown.Item>
                                            <Dropdown.ItemIcon name="download" />
                                            <Dropdown.ItemText>
                                                {t("download")}
                                            </Dropdown.ItemText>
                                        </Dropdown.Item>
                                    )}
                                    <Modal>
                                        <Modal.Trigger>
                                            <Dropdown.Item variant="negative">
                                                <Dropdown.ItemIcon name="trash" />
                                                <Dropdown.ItemText>
                                                    {t("delete")}
                                                </Dropdown.ItemText>
                                            </Dropdown.Item>
                                        </Modal.Trigger>
                                        <Modal.Overlay>
                                            <Modal.Content>
                                                <Modal.Header>
                                                    <Modal.Title>
                                                        {t(
                                                            "delete_modal_title",
                                                        )}
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    {t(
                                                        "delete_modal_description",
                                                    )}
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Modal.CancelCTA>
                                                        {t(
                                                            "delete_modal_cancel",
                                                        )}
                                                    </Modal.CancelCTA>
                                                    <Modal.ConfirmCTA
                                                        loading={
                                                            deleteMutation.isPending
                                                        }
                                                        onClick={deleteVersion}
                                                        variant="flatDanger"
                                                    >
                                                        {t("delete")}
                                                    </Modal.ConfirmCTA>
                                                </Modal.Footer>
                                            </Modal.Content>
                                        </Modal.Overlay>
                                    </Modal>
                                </Dropdown.Content>
                            </Dropdown>
                        </div>
                    )}
                </InnerLayout>
            </FullFrameBlock>
            <div className="relative group-last:hidden">
                <div className="absolute w-[34px]  h-5 border-r border-seconday" />
            </div>
        </div>
    );
};

export default VersionBlock;
