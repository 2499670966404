import { atomFamily, atomWithStorage } from "jotai/utils";

export const selectedSubthemesAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `objectivesSelectedSubthemes_${orgUname}`,
            [],
        ),
);

export const selectedUsersAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `objectivesSelectedUsers_${orgUname}`,
            [],
        ),
);

export const selectedThemesAtomFamily = atomFamily(
    (orgUname: string | undefined) =>
        atomWithStorage<(string | null)[]>(
            `objectivesSelectedThemes_${orgUname}`,
            [],
        ),
);
