import { useExternalQuestionnaires } from "@app/screens/ExternalQuestionnaires/data";
import BodyHeader from "@app/shared/components/BodyHeader";
import { Box } from "@design-system/Layout/Box";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { getPaginationRowModel, Table } from "@design-system/Table";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { columns } from "./columns";
import { CreateQuestionnaireModal } from "./components/CreateQuestionnaireModal";
import "./i18n";
import { keys, RESOURCE_NAME } from "./i18n";

export const ExternalQuestionnaires: FC = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const { externalQuestionnaires, isPending } = useExternalQuestionnaires();

    return (
        <PageContainer>
            <BodyHeader
                action={<CreateQuestionnaireModal />}
                title={t(keys.page_title)}
                subTitle={t(keys.page_subtitle)}
            />
            <Box p="4">
                {!isPending && externalQuestionnaires.length === 0 ? (
                    "no data"
                ) : (
                    <Table
                        columns={columns}
                        data={externalQuestionnaires}
                        loading={isPending}
                        options={{
                            getPaginationRowModel: getPaginationRowModel(),
                        }}
                    />
                )}
            </Box>
        </PageContainer>
    );
};
