import { Text } from "@design-system/Typography/Text";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { OpenNewWindow } from "iconoir-react";
import { Download } from "lucide-react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Document } from "@generated/client/graphql";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import CheckboxCell from "@app/components/Table/CheckboxCell";
import DataTable from "@app/components/Table/DataTable";
import CheckboxHeader from "@app/components/Table/Header/Checkbox";
import { Tooltip } from "@app/components/Tooltip";

export const columns: ColumnDef<Document>[] = [
    {
        id: "select",
        header: ({ table }) => {
            return <CheckboxHeader table={table} />;
        },
        cell: ({ row }) => <CheckboxCell row={row} />,
        enableSorting: false,
        enableHiding: false,
        meta: {
            colWidth: "7%",
        },
    },
    {
        id: "name",
        accessorKey: "name",
        header: () => {
            const { t } = useTranslation("Framework");
            return t("table_header_name");
        },
        cell: ({ row }) => (
            <div>
                <div className=" font-bold text-ellipsis">
                    {row.original.name}
                </div>
                <div className="flex -mx-1 flex-wrap">
                    {row.original.frameworkSections?.map((fs) => (
                        <Tooltip tooltipContent={fs.name}>
                            <Text
                                whitespace="nowrap"
                                variant="body-sm"
                                className="mx-1"
                            >
                                {fs.tagName}
                            </Text>
                        </Tooltip>
                    ))}
                </div>
            </div>
        ),
    },
    {
        id: "actions",
        meta: {
            colWidth: "54px",
        },
        cell: ({ row }) => {
            const onClick = () => {
                const url = row.original.lastVersion?.finalFile?.signedUrl;
                if (url) {
                    window.open(url, "_blank");
                }
            };
            const ext = row.original.lastVersion?.finalFile?.extension;
            const { t } = useTranslation("Framework");

            return (
                <ButtonSmall
                    onClick={onClick}
                    IconLeft={ext === "pdf" ? OpenNewWindow : Download}
                    tooltipContent={
                        ext === "pdf" ? t("open_in_new_tab") : t("download")
                    }
                />
            );
        },
    },
];

const Table = ({
    documents,
    onSelectedDocumentIdsChange,
    searchString,
}: {
    documents: Document[];
    onSelectedDocumentIdsChange: (ids: string[]) => void;
    searchString?: string;
}) => {
    const table = useReactTable({
        columns: columns,
        data: documents,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            globalFilter: searchString,
        },
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 5,
            },
        },
    });

    const selectedDocumentIds = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original.id);
    const selectedDocumentsIdsRef = useRef<string[]>(selectedDocumentIds);
    selectedDocumentsIdsRef.current = selectedDocumentIds;

    useEffect(() => {
        onSelectedDocumentIdsChange(selectedDocumentsIdsRef.current);
    }, [selectedDocumentIds.length, selectedDocumentsIdsRef]);
    return <DataTable table={table} rowClassName={() => "h-[72px]"} />;
};

export default Table;
