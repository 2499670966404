import {
    createContext,
    forwardRef,
    useContext,
    type ForwardRefExoticComponent,
    type RefAttributes,
} from "react";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { cn } from "../../Utilities";
import { Button } from "@design-system/Inputs/Button";

const SegmentButtonCtx = createContext<{ size: "sm" | "md" } | null>(null);

const Item = forwardRef<
    HTMLButtonElement,
    ToggleGroupPrimitive.ToggleGroupItemProps
>(({ children, className, ...props }, ref) => {
    const ctx = useContext(SegmentButtonCtx);
    const size = ctx?.size;

    if (!size) {
        throw new Error(
            "SegmentButton.Item should be used within a SegmentButton. If you need a button, use a <Button /> componenet instead.",
        );
    }
    return (
        <ToggleGroupPrimitive.Item asChild ref={ref} {...props}>
            <Button __segment variant="plain" size={size}>
                {children}
            </Button>
        </ToggleGroupPrimitive.Item>
    );
});

export type SegmentButtonProps = {
    size?: "sm" | "md";
} & Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, "type"> &
    RefAttributes<HTMLDivElement>;

type SegmentButtonType = ForwardRefExoticComponent<SegmentButtonProps> & {
    Item: typeof Item;
};

export const SegmentButton = forwardRef<HTMLDivElement, SegmentButtonProps>(
    ({ className, size, ...props }, ref) => {
        return (
            <SegmentButtonCtx.Provider value={{ size: size ?? "md" }}>
                <ToggleGroupPrimitive.Root
                    className={cn(
                        "inline-flex border border-secondary rounded-lg overflow-hidden w-full",
                        className,
                    )}
                    ref={ref}
                    {...props}
                    type="single"
                />
            </SegmentButtonCtx.Provider>
        );
    },
    // casting the type here is ugly, but I have no choice
) as SegmentButtonType;

SegmentButton.Item = Item;
