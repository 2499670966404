import {
    GetObjectivesWithDetailsQuery,
    GetThemesAndSubthemesQuery,
} from "@generated/client/graphql";
import { Content, JSONContent } from "@tiptap/core";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { TFunction } from "i18next";

const formatTargets = (
    targets: GetObjectivesWithDetailsQuery["objectives"][number]["targets"],
    unitName: string,
) => {
    dayjs.extend(LocalizedFormat);
    return targets
        .map(
            (target) =>
                `${target.value?.number} ${unitName} ${dayjs(target.date).format("LL")}`,
        )
        .join("; \n");
};

export const generateObjectiveContent = (
    objectives: GetObjectivesWithDetailsQuery["objectives"] | undefined,
    subtheme:
        | GetThemesAndSubthemesQuery["themes"][number]["subthemes"][number]
        | undefined,
    t: TFunction,
): Content => {
    if (!objectives || !subtheme) return "";
    const subthemeObjectives = objectives.filter(
        (objective) => objective.subthemeId === subtheme.id,
    );
    const content: JSONContent[] = [
        {
            type: "heading",
            attrs: { textAlign: "left", level: 2 },
            content: [
                {
                    type: "text",
                    text:
                        subtheme.objectivesTitle ??
                        t("objectives_insert_title", {
                            subthemeName: subtheme.name,
                        }),
                },
            ],
        },
        {
            type: "paragraph",
            attrs: { textAlign: "left" },
        },
        {
            type: "paragraph",
            attrs: { textAlign: "left" },
        },
        {
            type: "table",
            content: [
                {
                    type: "tableRow",
                    content: [
                        {
                            type: "tableHeader",
                            attrs: {
                                colspan: 1,
                                rowspan: 1,
                            },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: t(
                                                "objectives_insert_objective_header_title",
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableHeader",
                            attrs: { colspan: 1, rowspan: 1 },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: t(
                                                "objectives_insert_description_header_title",
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableHeader",
                            attrs: { colspan: 1, rowspan: 1 },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: t(
                                                "objectives_insert_indicator_header_title",
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableHeader",
                            attrs: { colspan: 1, rowspan: 1 },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: t(
                                                "objectives_insert_targets_header_title",
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                ...subthemeObjectives.map((objective) => ({
                    type: "tableRow",
                    content: [
                        {
                            type: "tableCell",
                            attrs: {
                                colspan: 1,
                                rowspan: 1,
                            },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: `${objective.name || "-"}`,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableCell",
                            attrs: { colspan: 1, rowspan: 1 },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: `${objective.description || "-"}`,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableCell",
                            attrs: { colspan: 1, rowspan: 1 },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: `${
                                                objective.indicator?.name || "-"
                                            }`,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableCell",
                            attrs: { colspan: 1, rowspan: 1 },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: `${
                                                formatTargets(
                                                    objective?.targets,
                                                    objective?.indicator?.unit
                                                        ?.shortName || "",
                                                ) || "-"
                                            }`,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                })),
            ],
        },
    ];

    //tiptap doesn't support inserting empty paragraph
    if (subtheme.objectivesIntro) {
        content.splice(2, 0, {
            type: "paragraph",
            attrs: { textAlign: "left" },
            content: [{ type: "text", text: subtheme.objectivesIntro }],
        });
    }

    return {
        type: "doc",
        content,
    };
};
