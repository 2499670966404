import * as chameleon from "@chamaeleonidae/chmln";

import { currentUserAtom } from "@app/store/userStore";
import { useAtomValue } from "jotai";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserRole } from "../../../generated/client/graphql";

export const useTrackingIdentify = () => {
    const posthog = usePostHog();
    const { data } = useAtomValue(currentUserAtom);
    const activeUser = data?.currentUser;
    const activeTenant = data?.currentUser.permission?.tenant;
    useEffect(() => {
        if (!activeUser) return;
        posthog.init(
            import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY as string,
            {
                api_host: import.meta.env
                    .VITE_REACT_APP_PUBLIC_POSTHOG_HOST as string,
                ui_host: "https://eu.posthog.com",
                persistence: "memory",
                capture_pageview: false,
                loaded: function (ph) {
                    if (import.meta.env.DEV) {
                        ph.opt_out_capturing();
                    }
                },
                bootstrap: {
                    featureFlags: {
                        legacyObjectives: true,
                        questionnaires: false,
                        "ask-ai": false,
                        "ai-suggestions": false,
                    },
                },
            },
        );
        posthog?.identify(activeUser.id, {
            email: activeUser.email,
            firstname: activeUser.firstName,
            lastname: activeUser.lastName,
        });
    }, [activeUser?.id, posthog]);

    useEffect(() => {
        if (!activeTenant) return;
        posthog?.group("tenant", activeTenant.id, {
            name: activeTenant.name,
            uname: activeTenant.uname,
        });
        posthog.resetGroupPropertiesForFlags();
    }, [activeTenant, posthog]);

    useEffect(() => {
        if (!activeUser?.permission?.role) return;
        posthog?.group("role", activeUser.permission?.role, {
            name: activeUser.permission?.role,
        });
    }, [activeUser?.permission?.role, posthog]);

    useEffect(() => {
        if (!activeUser) return;
        // Initialise Chameleon

        chameleon.init(import.meta.env.VITE_REACT_APP_CHAMELEON_ID!, {
            fastUrl: "https://fast.chameleon.io/",
        });

        chameleon.identify(activeUser.id, {
            email: activeUser.email,
            name: activeUser.firstName + " " + activeUser.lastName,
            created: activeUser.createdAt,
            locale: activeUser.locale,
            role: activeUser.permission?.role ?? UserRole.Contributor,
            company: {
                uid: activeTenant?.uname,
                name: activeTenant?.name,
            },
        });
    }, [activeUser]);
};

export const useAutoCapturePageViews = () => {
    const posthog = usePostHog();
    let location = useLocation();
    const { pathname } = location;

    const getProps = (pathname: string) => {
        const regex = /^\/o\/.*\/admin(?:\/(.*))?$/;
        const [, breadcrumb = undefined] = pathname.match(regex) || []; // pathname: '/o/tenant/admin/after/the/regex' breadcrumb: "after/the/regex"
        const [
            module = undefined,
            section = undefined,
            subSection = undefined,
        ] = breadcrumb?.split("/") || [];
        return { breadcrumb, module, section, subSection };
    };
    // Track pageviews
    useEffect(() => {
        const props = getProps(pathname);

        posthog.capture("$pageview", {
            $current_url: window.location.href.split("?")[0],
            ...props,
        });
    }, [pathname]);
};
