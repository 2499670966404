import { atomWithStorage } from "jotai/utils";

export const isObjectivesListOpenAtom = atomWithStorage<boolean>(
    "__beavr_isObjectivesListOpenAtom",
    true,
);

export const isRelatedDocumentsListOpenAtom = atomWithStorage<boolean>(
    "__beavr_isRelatedDocumentsListOpenAtom",
    true,
);
