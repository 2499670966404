import { Icon } from "@design-system/Icon";
import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { getPaginationRowModel, Table } from "@design-system/Table";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { columns } from "./columns";
import { CreateQuestionsModal } from "./components/CreateQuestionsModal";
import { QuestionnaireHeader } from "./components/QuestionnaireHeader";
import { useImportQuestionsFromText, useQuestions } from "./data";
import { useExternalQuestionnaireBreadcrumb } from "./hooks/useExternalQuestionnairesBreadcrumb";
import { keys, RESOURCE_NAME } from "./i18n";
import { ImportQuestionsModal } from "./components/ImportQuestionsModal";
import Loading from "@app/components/Loading";

export const ExternalQuestionnaire: FC = () => {
    const { external_questionnaire_id: id } = useParams();
    const { t } = useTranslation(RESOURCE_NAME);
    const { questions } = useQuestions(id);
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("../");
    };

    useExternalQuestionnaireBreadcrumb(id);
    const { importQuestionsAsync, isPending } = useImportQuestionsFromText();

    const handleImportQuestionsConfirm = async (text: string) => {
        if (!id) return;
        importQuestionsAsync({
            questionnaireId: id,
            text,
        });
    };

    return (
        <PageContainer>
            <FlexCol gap="4" p="4">
                <QuestionnaireHeader />
                <FlexRow justifyContent="between">
                    <Button variant="tonal" onClick={handleBack}>
                        <Button.Icon name="angleLeft" />
                        {t(keys.back_button_label)}
                    </Button>
                    <FlexRow gap="2">
                        <Button variant="text">
                            <Icon name="download" />
                            Export
                        </Button>
                        <CreateQuestionsModal />
                        <ImportQuestionsModal
                            onConfirm={handleImportQuestionsConfirm}
                            loading={isPending}
                        />
                    </FlexRow>
                </FlexRow>
                {isPending ? (
                    <Loading size="large" />
                ) : (
                    <Table
                        columns={columns}
                        data={questions ?? []}
                        options={{
                            getPaginationRowModel: getPaginationRowModel(),
                        }}
                    />
                )}
            </FlexCol>
        </PageContainer>
    );
};
