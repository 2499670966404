import { type FC } from "react";
import { Text } from "@design-system/Typography/Text";
import { Box } from "@design-system/Layout/Box";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
const classes = {
    info: "bg-accent-100 border border-accent-700",
} as const;

export const InfoBox: FC<{ variant?: "info"; text: string }> = ({
    variant = "info",
    text,
}) => {
    return (
        <Box br="lg" p="2.5" className={classes[variant]}>
            <ReactMarkdown
                children={text}
                components={{
                    p: ({ children }) => (
                        <Text variant="body-sm-bold">{children}</Text>
                    ),
                    ol: ({ children }) => (
                        <ol className="pl-5 list-decimal list-outside">
                            {children}
                        </ol>
                    ),
                    ul: ({ children }) => (
                        <ul className="pl-5 list-disc list-outside">
                            {children}
                        </ul>
                    ),
                    li: ({ children }) => (
                        <li className="text-sm font-bold list-item mb-2 last:mb-0">
                            {children}
                        </li>
                    ),
                }}
                remarkPlugins={[remarkGfm, remarkBreaks]}
            />
        </Box>
    );
};
