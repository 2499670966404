import {
    skipToken,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { externalQuestionnairesKeys } from "../data";
import { GraphQLExternalQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/externalQuestionnaire";
import { Question } from "./types";
import { makeCreateQuestionsInput } from "./services";
import { QuestionUpdateInput } from "@generated/client/graphql";

const externalQuestionnairesRepository =
    new GraphQLExternalQuestionnaireRepository();

export function useExternalQuestionnaire(id?: string) {
    const { data, ...query } = useQuery({
        queryKey: externalQuestionnairesKeys.details(id),
        queryFn: id
            ? () =>
                  externalQuestionnairesRepository.getExternalQuestionnaire(id)
            : skipToken,
    });
    return { externalQuestionnaire: data?.questionnaire, ...query };
}

export function useCreateQuestions({
    questionnaireId,
}: {
    questionnaireId?: string;
}) {
    const client = useQueryClient();
    const {
        mutate: createMany,
        mutateAsync: createManyAsync,
        ...mutation
    } = useMutation({
        mutationFn: async (questions: Partial<Question>[]) => {
            if (!questionnaireId) {
                throw new Error("External questionnaire ID is required");
            }
            return externalQuestionnairesRepository.createQuestions(
                makeCreateQuestionsInput(questionnaireId, questions),
            );
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: externalQuestionnairesKeys.details(questionnaireId),
            });
        },
    });
    return { createMany, createManyAsync, ...mutation };
}

export function useQuestions(questionnaireId?: string) {
    const { data: { questions } = {}, ...query } = useQuery({
        queryKey: externalQuestionnairesKeys.questions(questionnaireId),
        queryFn: questionnaireId
            ? () =>
                  externalQuestionnairesRepository.getQuestions({
                      questionnaireId,
                  })
            : skipToken,
    });
    return { questions, ...query };
}

export function useDeleteQuestion(questionnaireId?: string) {
    const client = useQueryClient();
    const { mutate: deleteQuestion, ...mutation } = useMutation({
        mutationFn: externalQuestionnairesRepository.deleteQuestion,
        onSettled: () => {
            client.invalidateQueries({
                queryKey: externalQuestionnairesKeys.questions(questionnaireId),
            });
        },
    });
    return { deleteQuestion, ...mutation };
}

export function useQuestionUpdate() {
    const queryClient = useQueryClient();
    const { mutate: updateQuestion, ...mutation } = useMutation({
        mutationFn: ({
            id,
            input,
        }: {
            id: string;
            input: QuestionUpdateInput;
        }) => externalQuestionnairesRepository.updateQuestion(id, input),
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: externalQuestionnairesKeys.all,
            });
        },
    });
    return { updateQuestion, ...mutation };
}

export const useImportQuestionsFromText = () => {
    const queryClient = useQueryClient();
    const { mutateAsync: importQuestionsAsync, ...mutation } = useMutation({
        mutationFn: ({
            questionnaireId,
            text,
        }: {
            questionnaireId: string;
            text: string;
        }) =>
            externalQuestionnairesRepository.importQuestionsFromText(
                questionnaireId,
                { text },
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: externalQuestionnairesKeys.all,
            });
        },
    });

    return { importQuestionsAsync, ...mutation };
};
