import i18next from "i18next";

const enResources = {
    objective_title:
        "Increase the share of renewable sources in total energy consumption to reach 50% by 2030",
    indicator_title:
        "Percentage of renewable sources in total energy consumption",
    objective_description: `In 2023, 70% of our energy consumption was derived from non-renewable sources. We have 
        identified that increasing our reliance on renewable energy sources will significantly reduce our Scope 2 emissions. 
        We are confident that this shift will contribute meaningfully toward achieving our science-based target of a 30% 
        reduction in Scope 2 emissions by 2030, using 2019 as the baseline year.`,
    description_title: "How did you set this objective?",
    action_plan_title: "What is your plan to reach this objective?",
    action_plan_description: `Our action plan\n
Short-term:
-  Analyze energy consumption to identify areas for easy renewable energy adoption.
- List and contact renewable energy suppliers to initiate contracts. \n
Long-term:
- Redesign procurement to prioritize renewable energy.
- Invest in infrastructure and partnerships to enable sustainable energy sourcing.
- Monitor progress and adapt strategies as needed.`,
    targets_title: "What are your targets?",
    example_title: "Example of objective",
} as const;

type ResourceKeys = keyof typeof enResources;

const frResources = {
    objective_title:
        "Atteindre 50% d’énergies renouvelables dans la consommation totale en 2030",
    indicator_title:
        "Pourcentage de sources renouvelables dans la consommation totale d'énergie",
    objective_description: `70% de l’énergie que nous consommons provient de sources non renouvelables. 
        Nous avons identifié que le passage à des sources d’énergie renouvelables nous aidera à atteindre notre objectif 
        de réduction de 30% de nos émissions sur le scope 2 d’ici 2030 (2019 étant l’année de référence).`,
    description_title: "Comment avez-vous défini cet objectif ?",
    action_plan_title: "Quel est votre plan pour atteindre cet objectif ?",
    action_plan_description: `Notre plan d'action\n
Court terme :
- Analyser notre consommation énergétique pour identifier les domaines où le passage à des énergies renouvelables est accessible.
- Lister et contacter les fournisseurs d'énergies renouvelables pour initier des contrats.\n
Long terme :
- Repenser les processus d'approvisionnement pour faire la place aux énergies renouvelables.
- Investir dans les infrastructures et les partenariats pour permettre un approvisionnement durable en énergie.
- Suivre nos progrès et adapter les stratégies si nécessaire.`,
    targets_title: "Quels sont vos objectifs chiffrés ?",
    example_title: "Exemple d'objectif",
} as const;

export const keys = Object.fromEntries(
    Object.keys(enResources).map((key) => [key, key]),
) as Record<ResourceKeys, ResourceKeys>;

export const RESOURCE_NAME = "ObjectiveExampleDrawer";

i18next.addResourceBundle("en", RESOURCE_NAME, enResources);
i18next.addResourceBundle("fr", RESOURCE_NAME, frResources);
