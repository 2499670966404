import { useThemes } from "@app/store/themeStore";
import { ObjectiveTargetStatus } from "@generated/client/graphql";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";

export const useExampleData = () => {
    const { themes } = useThemes();
    const theme = themes?.find((theme) => theme.cmsThemeId === "environment");
    const subtheme = theme?.subthemes?.find(
        (subtheme) => subtheme.cmsSubThemeId === "energy-mgmt",
    );

    const { t } = useTranslation(RESOURCE_NAME);

    const targets = [
        {
            id: "FakeTargetId",
            value: 20,
            status: ObjectiveTargetStatus.Reached,
            date: new Date("2025-01-01"),
        },
        {
            id: "FakeTargetId2",
            value: 50,
            status: ObjectiveTargetStatus.Ongoing,
            date: new Date("2030-01-01"),
        },
    ];

    const indicator = {
        id: "FakeIndicatorId",
        name: t(keys.indicator_title),
        unit: {
            id: "FakeUnitId",
            shortName: "%",
        },
        datapoints: [
            {
                id: "FakeDatapointId",
                value: {
                    float: 12,
                },
            },
        ],
    };

    const objective = {
        id: "FakeObjectiveId",
        name: t(keys.objective_title),
        description: t(keys.objective_description),
        actionPlan: t(keys.action_plan_description),
        themeId: theme?.id || "",
        subthemeId: subtheme?.id,
        indicator: indicator,
        targets: targets,
    };

    return { objective, indicator, targets };
};
