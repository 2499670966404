import { FlexCol } from "@design-system/Layout/Flex";
import { type FC } from "react";
import { Text } from "@design-system/Typography/Text";
import {
    useDropdownItemTextStyles,
    useDropdownItemStyles,
} from "@design-system/Inputs/UniversalDropdown";
import { Box } from "@design-system/Layout/Box";
import { useQuestions } from "../../data";
import { Link, useParams } from "react-router-dom";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { cn } from "@design-system/Utilities";

const SidebarItem: FC<{ active: boolean; id: string; name: string }> = ({
    active,
    id,
    name,
}) => {
    const itemClassName = useDropdownItemStyles({ active });
    const textClassName = useDropdownItemTextStyles({
        active,
    });
    return (
        <Box
            className={cn(
                itemClassName,
                active && "bg-tertiary",
                "min-w-[232px]",
            )}
        >
            <Link className="[all:unset] hover:no-underline" to={`../${id}`}>
                <Ellipsis asChild className="max-w-[226px]" hasTooltip>
                    <Text
                        className={cn(
                            textClassName,
                            active && "text-brand_hover",
                        )}
                    >
                        {name}
                    </Text>
                </Ellipsis>
            </Link>
        </Box>
    );
};
export const Sidebar: FC = () => {
    const {
        external_questionnaire_id,
        external_questionnaire_question_id: currentQuestionId,
    } = useParams();

    const { questions } = useQuestions(external_questionnaire_id);
    return (
        <nav className="h-full w-fit">
            <FlexCol className="h-full" gap="0.5">
                {questions?.map((question) => (
                    <SidebarItem
                        key={question.id}
                        id={question.id}
                        name={question.name}
                        active={question.id === currentQuestionId}
                    />
                ))}
            </FlexCol>
        </nav>
    );
};
