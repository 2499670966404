import { Button } from "@design-system/Inputs/Button";
import { Textarea } from "@design-system/Inputs/Textarea";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { useState, type ChangeEvent, type FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";

export const ImportQuestionsModal: FC<{
    onConfirm: (value: string) => void;
    loading?: boolean;
}> = ({ onConfirm, loading }) => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [value, setValue] = useState<string>();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    const handleConfirm = () => {
        if (value) {
            onConfirm(value);
            setValue(undefined);
        }
    };

    return (
        <Modal>
            <Modal.Trigger>
                <Button>
                    <Button.Icon name="upload2" />
                    {t(keys.title)}
                </Button>
            </Modal.Trigger>
            <Modal.Overlay>
                <Modal.Content>
                    <Modal.Header>
                        <Modal.Title>{t(keys.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FlexCol gap="4">
                            <ol>
                                <Trans
                                    t={t}
                                    i18nKey={keys.description}
                                    components={{
                                        li: <li className="text-base" />,
                                    }}
                                />
                            </ol>
                            <Textarea
                                value={value}
                                onChange={handleChange}
                                rows={10}
                                placeholder={t(keys.placeholder)}
                            />
                        </FlexCol>
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelCTA>{t(keys.cancel)}</Modal.CancelCTA>
                        <Modal.ConfirmCTA
                            loading={loading}
                            onClick={handleConfirm}
                        >
                            {t(keys.confirm)}
                        </Modal.ConfirmCTA>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Overlay>
        </Modal>
    );
};
