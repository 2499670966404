import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import SearchFilter, {
    searchFilterStringAtom,
} from "../../../components/Filters/Search";
import BodyHeader from "../../../shared/components/BodyHeader";

import { useAtom } from "jotai";
import NewSupplierModal from "./components/NewSupplierModal";
import SupplierTable from "./components/SupplierTable";
import { useSuppliers } from "./data";
import "./i18n";

export default function Suppliers() {
    const { t } = useTranslation("Suppliers");
    const { suppliers, isLoading, refetch } = useSuppliers();
    const [searchString, setSearchString] = useAtom(searchFilterStringAtom);
    return (
        <>
            <Container>
                <BodyHeader
                    title={t("title")}
                    subTitle={t("subtitle")}
                    action={<NewSupplierModal refetch={refetch} />}
                />
                <div className="p-4 space-y-4">
                    <SearchFilter
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                    <SupplierTable
                        suppliers={suppliers}
                        isLoading={isLoading}
                    />
                </div>
            </Container>
        </>
    );
}
