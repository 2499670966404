import {
    QuestionType,
    type QuestionCreateInput,
} from "@generated/client/graphql";
import { type Question } from "./types";

export function makeCreateQuestionsInput(
    questionnaireId: string,
    questions: Partial<Question>[],
) {
    const input: QuestionCreateInput[] = questions.map((partialQuestion) => {
        const question = {
            ...partialQuestion,
            questionnaireId,
        };
        if (!isQuestionCreateInput(question)) {
            throw new Error("Question name and type are required");
        }
        return question;
    });
    return input;
}

export function isQuestionCreateInput(
    question: Partial<QuestionCreateInput>,
): question is QuestionCreateInput {
    return (
        typeof question.name === "string" &&
        typeof question.type !== "undefined"
    );
}

export function isQuestionType(value: string): value is QuestionType {
    return Object.values(QuestionType).includes(value as QuestionType);
}

export function canSubmitQuestion<
    T extends {
        name?: string | null;
        type?: QuestionType;
    },
>(question: T) {
    return (
        typeof question.name === "string" &&
        question.name.length > 0 &&
        !!question.type
    );
}
