import { GraphQLExternalQuestionnaireRepository } from "@app/repositories/GraphQLRepositories/externalQuestionnaire";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

export const externalQuestionnairesRepository =
    new GraphQLExternalQuestionnaireRepository();

export const externalQuestionnairesKeys = {
    all: ["externalQuestionnaires"] as const,
    details: (id?: string) => [...externalQuestionnairesKeys.all, id] as const,
    questions: (questionnaireId?: string) =>
        [
            ...externalQuestionnairesKeys.all,
            questionnaireId,
            "questions",
        ] as const,
    question: (id?: string) =>
        [...externalQuestionnairesKeys.all, "questions", id] as const,
};

export const useExternalQuestionnaires = () => {
    const { data, ...query } = useQuery({
        queryKey: externalQuestionnairesKeys.all,
        queryFn: externalQuestionnairesRepository.getExternalQuestionnaires,
    });

    const externalQuestionnaires = useMemo(
        () => data?.questionnaires ?? [],
        [data],
    );

    return { externalQuestionnaires, ...query };
};

export const useCreateExternalQuestionnaire = () => {
    const client = useQueryClient();

    const { mutate: create, ...query } = useMutation({
        mutationFn:
            externalQuestionnairesRepository.createExternalQuestionnaire,
        onSettled: () => {
            client.invalidateQueries({
                queryKey: externalQuestionnairesKeys.all,
            });
        },
    });
    return { create, ...query };
};
